import React from 'react';
import plus_icon from '../../../../assets/icons/plus.svg';
import {
  RectangularContainer,
  Label,
  CircularContainer,
  RehabButtonContainer,
} from './styles';

interface Props {
  tag?: string;
  onClick: () => void;
}

export const RectangularAddWorkItemButton: React.FC<Props> = ({ tag = 'default', onClick }) => (
  <RectangularContainer
    id={`rectangular-add-work-item-button-${tag}`}
    data-testid="rectangular-addLineItem"
    onClick={() => onClick()}
  >
    <img src={plus_icon} alt="plus_icon" style={{ marginRight: 8 }} />
    <Label>Add work item</Label>
  </RectangularContainer>
);

export const CircularAddWorkItemButton: React.FC<Props> = ({ onClick }) => (
  <CircularContainer
    data-testid="circular-addLineItem"
    onClick={() => onClick()}
  >
    <img src={plus_icon} alt="plus_icon" />
  </CircularContainer>
);

export const RehabAddWorkItemButton: React.FC<Props> = ({ onClick }) => (
  <RehabButtonContainer
    id="rehab-add-work-item-button"
    onClick={() => onClick()}
  >
    Add work item
  </RehabButtonContainer>
);
