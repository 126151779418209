import React, { useContext, useEffect } from 'react';
import Modal from 'react-modal';
import { colorPaletteGrayscale50 } from 'haven-design-system/build/typescript/es6';
import { RehabToolContext } from '../../context';
import RightPanel from './RightPanel';
import LeftPanel from './LeftPanel';
import styles from './styles.module.scss';
import Spinner from '../../../../components/Spinner';

const ModalStyle = {
  overlay: {
    backgroundColor: colorPaletteGrayscale50,
    zIndex: 5,
  },
  content: {
    border: 'none',
    padding: 0,
  },
};

interface Props {
  systemNames: string[]
  close: () => void
}

const WorkItemModal: React.FC<Props> = ({ systemNames, close }) => {
  const { state } = useContext(RehabToolContext);

  const escFunction = (event: KeyboardEvent) => {
    if (event.key === 'Escape') close();
  };

  useEffect(() => {
    if (state.isWorkItemModalOpen) {
      document.addEventListener('keyup', escFunction, false);
    }
    return () => {
      document.removeEventListener('keyup', escFunction, false);
    };
  }, [state.isWorkItemModalOpen]);

  return (
    <Modal
      id="work-item-modal"
      isOpen={state.isWorkItemModalOpen}
      onAfterOpen={() => { document.body.style.overflow = 'hidden'; }}
      onAfterClose={() => { document.body.style.overflow = 'scroll'; }}
      style={ModalStyle}
      ariaHideApp={false}
    >
      <div className={styles.workItemModal} data-testid="modalContainer">
        {state.isWorkItemModalLoading
          ? <Spinner />
          : (
            <>
              <LeftPanel />
              <RightPanel
                systemNames={systemNames}
                close={close}
              />
            </>
          )}
      </div>
    </Modal>
  );
};

export default WorkItemModal;
