import { gql } from '@apollo/client';
import { FULL_REPORT_FIELDS } from 'marketplace-common';

const REHAB_PROPERTY_FRAGMENT = gql`
  fragment RehabPropertyFields on PropertyType {
    id
    address
    street
    street2
    city
    state
    zipCode
    country
    homeType
    totalSquareFeet
    bedrooms
    bathrooms
    yearBuilt
    photo {
      cdnUrl
    }
    timezone
  }
`;

export const REHAB_WORK_ITEM_FRAGMENT = gql`
  fragment RehabWorkItemFields on WorkItemType {
    id
    projectId
    systemName
    subsystemName
    title
    details
    quantity
    unit
    pricePerUnit
    totalPrice
    tags
    templateWorkItemPricingId
    pricingExternalId
    displayIndex
    workItemAssociations {
      id
      workItemId
      itemId
      itemType
      data
    }
    workItemAttachments {
      id
      workItemId
      workItemAssociationId
      s3ObjectKey
      attachmentUrl
      thumbnailUrl
      resizedUrls {
        url
        width
      }
    }
    workItemContributors {
      amount
      createdAt
      id
      name
      updatedAt
      workItemId
    }
  }
`;

export const REHAB_TEAM_CUSTOM_SYSTEM_FRAGMENT = gql`
  fragment RehabTeamCustomSystemFields on TeamCustomSystemType {
    id
    teamId
    name
    subsystemNames
  }
`;

const REHAB_TEAM_FRAGMENT = gql`
  ${REHAB_TEAM_CUSTOM_SYSTEM_FRAGMENT}

  fragment RehabTeamFields on TeamType {
    id
    token
    name
    rehabTags
    canPublishRehabProjects
    rehabTeamMarkets {
      id
      name
      stateAbbreviation
    }
    rehabTeamCustomSystems {
      ...RehabTeamCustomSystemFields
    }
  }
`;

export const FETCH_REHAB_PROJECT_READ_ONLY = gql`
  ${REHAB_PROPERTY_FRAGMENT}
  ${REHAB_WORK_ITEM_FRAGMENT}
  ${REHAB_TEAM_FRAGMENT}
  
  query getRehabProject($id: ID!, $resizedAttachmentWidths: [Int!]) {
    rehabProject(id: $id, resizedAttachmentWidths: $resizedAttachmentWidths) {
      id
      name
      propertyId
      teamId
      reportId
      publishedAt
      teamMarketId
      property {
        ...RehabPropertyFields
      }
      workItems {
        ...RehabWorkItemFields
      }
      team {
        ...RehabTeamFields
      }
    }
  }
`;

export const FETCH_REHAB_PROJECT = gql`
  ${REHAB_PROPERTY_FRAGMENT}
  ${FULL_REPORT_FIELDS}
  ${REHAB_WORK_ITEM_FRAGMENT}
  ${REHAB_TEAM_FRAGMENT}
  
  query getRehabProject($id: ID!, $resizedAttachmentWidths: [Int!], $rotatedAttachmentDegrees: [Int!]) {
    rehabProject(id: $id, resizedAttachmentWidths: $resizedAttachmentWidths, rotatedAttachmentDegrees: $rotatedAttachmentDegrees) {
      id
      name
      propertyId
      teamId
      reportId
      publishedAt
      teamMarketId
      property {
        ...RehabPropertyFields
      }
      report {
        ...FullReportFields
      }
      workItems {
        ...RehabWorkItemFields
      }
      team {
        ...RehabTeamFields
      }
    }
  }
`;

export const FETCH_REHAB_PROJECT_IDS_FOR_REPORT = gql`
  query rehabProjects($reportId: ID) {
    rehabProjects(reportId: $reportId) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          reportId
          propertyId
          teamId
        }
      }
    }
  }
`;


export const FETCH_TEMPLATE_WORK_ITEMS = gql`
  query templateWorkItems($marketId: ID, $after: String, $first: Int) {
    templateWorkItems(marketId: $marketId, after: $after, first: $first) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        systemName
        teamId
        title
        templateWorkItemPricings {
          id
          pricingExternalId
          details
          teamMarketId
          lumpSumPrice
          pricePerUnit
          templateWorkItemId
          unit
          tags
        }
      }
    }
  }
`;

export const FETCH_WORK_HISTORY_FOR_PROJECT = gql`
  query projectHistory($first: Int, $projectId: ID!, $after: String) {
    projectHistory(first: $first, projectId: $projectId, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          itemType
          itemId
          event
          createdAt
          objectChanges {
            fieldName
            oldValue
            newValue
          }
          user {
            id
            name
            email
          }
        }
      }
    }
  }
`;
