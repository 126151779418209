import cn from 'classnames';
import isNil from 'lodash/isNil';
import { InformationInputType } from 'marketplace-common';
import React from 'react';
import InfoIcon from 'haven-design-system/build/assets/svgs/display/info_outlined.svg?component';
import { InlineInspectionScopeField } from './InlineInspectionScopeField';
import styles from './styles.module.scss';

interface InformationItemProps {
  id: string;
  label: string;
  value: string | null | undefined;
  isLink?: boolean;
  isSignature?: boolean;
  questionType?: string;
  cannotAnswer?: boolean;
  inspectionScopeItemId?: string;
  reportSubsystemId?: string;
}

const InformationItem: React.FC<InformationItemProps> = ({
  label, value, isLink, isSignature, questionType, cannotAnswer,
}) => {
  const isCannotAnswer = Boolean(cannotAnswer);
  const isApplianceQuestionType = questionType === (InformationInputType.appliance || InformationInputType.applianceTag);
  const renderedValue = isNil(value) ? '--' : value;
  const isIncomplete = renderedValue === 'Incomplete';

  if (isApplianceQuestionType && !isCannotAnswer) return null;

  return (
    <div>
      {(!isSignature) && <p className={styles.labelHeader}>{label}</p>}
      <p className={cn(styles.labelValue, isSignature && styles.signature, isIncomplete && styles.incomplete)}>
        {isLink ? <a href={renderedValue}>{renderedValue}</a> : renderedValue}
        {isIncomplete && (
          <span className={styles.iconContainer}>
            <InfoIcon />
          </span>
        )}
      </p>
    </div>
  );
};

interface InformationProps {
  header?: string
  isSignature?: boolean
  data: InformationItemProps[]
}

const Informations: React.FC<InformationProps> = ({ header, data, isSignature }) => (
  <div className={styles.sectionBody} style={{ marginBottom: header === 'Client info' ? 32 : 0 }}>
    {header && !isSignature && (
      <h3 className={styles.sectionHeader} style={{ paddingLeft: header === 'Client info' ? 18 : 0 }}>
        {header}
      </h3>
    )}

    <div className={styles.informationsContainer}>
      {data.map((info, index) => (
        <div key={`${info.label}-${info.value}-${index + 1}`} className={styles.informationContainer}>
          <InformationItem
            isSignature={isSignature}
            {...info}
          />
          <InlineInspectionScopeField
            inspectionScopeItemId={info.inspectionScopeItemId}
            reportSubsystemId={info.reportSubsystemId}
          />
        </div>
      ))}
    </div>
  </div>
);

export default Informations;
