import { ContractorTypeEnum, OnboardingType } from '../../../../types';

export interface OnboardingTrackerProps {
  contractorType: ContractorTypeEnum;
  onboardingData: OnboardingType;
  handleContactModal: () => void;
}

export interface TrackerItemProps {
  header: string;
  instructions: string;
  isChecked: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export enum TrackerItemHeader {
  MISA = 'Sign Master Inspection & Services Agreement',
  BusinessInformation = 'Complete business information',
  BackgroundCheck = 'Submit background check',
  Payment = 'Set up payments',
  AddUsers = 'Add users',
  App = 'Download the Inspectify App',
}

export enum TrackerItemInstructions {
  MISA = 'This is necessary before we can start sending you jobs.',
  BusinessInformation = 'Upload certificate of insurance and business info to receive job offers.',
  BackgroundCheck = 'Click here to submit your background check through Checkr. There may be a delay before the submission is reflected here.',
  BackgroundCheckDisabled = 'We are generating your background check invitation. Please check back soon!',
  Payment = 'This is necessary for us to pay you for your work.  There may be a delay before the submission is reflected here.',
  AddUsers = 'Include multiple inspectors on a single company account.',
  App = 'Our app can be found in the Apple App store or Google play store. Make sure you sign in once you have downloaded the app to complete this step.',
}

export enum MarketSaturationStatus {
  Header = 'Thanks for your interest!',
  Body = 'Looks like we have enough surveyors in your area at the moment. We\'ll hold on to your info and reach out when more work becomes available.',
}
