export interface PopupInstructionsProps {
  header: string,
  body: string,
  xPos: number,
  yPos: number,
  showPopupAboveElement?: boolean,
  onClose: () => void,
}

export enum PopupInstructionsContent {
  AddUserHeader = 'Add users to your account',
  AddUserBody = 'Each inspector providing services must have their own individual login credentials.  You can customize their permissions to meet your needs.',
  SendInviteHeader = 'Send an invite',
  SendInviteBody = 'Your new user will receive an email where they can set their password and log into the Inspectify app. ',
}
