import React from 'react';
import { Job } from 'marketplace-common';
import { isNull } from 'lodash';
import { Tabs } from '../../../components/Tabs';
import JobRow from './JobRow';
import Spinner from '../../../components/Spinner';
import { JobsTabs } from '../types';
import { JobsHeadingWrapper } from './styles';
import { Container as JobsContainer } from '../shared/styles';
import OnboardingTracker from './OnboardingTracker';
import { ContractorTypeEnum, OnboardingType } from '../../../types';
import { ContactSupport } from '../../../components/ContactModal/types';
import ContactModal from '../../../components/ContactModal';

interface Props {
  tab: JobsTabs,
  setTab: (tab: JobsTabs) => void,
  jobs: Job[],
  loading: boolean,
  onboardingData: OnboardingType,
  contractorType: ContractorTypeEnum,
  isContactModalOpen: boolean,
  handleContactModal: () => void,
  children?: React.ReactNode,
}

const Jobs: React.FC<Props> = ({
  tab,
  setTab,
  jobs,
  loading,
  contractorType,
  onboardingData,
  isContactModalOpen,
  handleContactModal,
  children,
}) => (
  <JobsContainer>
    {isNull(onboardingData?.onboardingTrackerDismissedAt) && (
      <>
        <OnboardingTracker
          contractorType={contractorType}
          onboardingData={onboardingData}
          handleContactModal={handleContactModal}
        />
        {isContactModalOpen && (
          <ContactModal
            onClose={handleContactModal}
            header={ContactSupport.Header}
            subheader={ContactSupport.Body}
            url="customersupport@inspectify.com"
          />
        )}
      </>
    )}
    <JobsHeadingWrapper />
    <Tabs tab={tab} setTab={setTab} tabs={Object.values(JobsTabs)} />
    {jobs.map((job) => (
      <JobRow job={job} key={job.id} />
    ))}
    {children}
    {loading && <Spinner />}
  </JobsContainer>
);

export default Jobs;
