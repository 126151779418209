import { UserData } from '../types';

export enum ModalType {
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
  ARCHIVE = 'archive',
}

export interface UserFormProps {
  handleReturn: () => void,
  usersAdded: boolean,
  singleUser?: UserData | null,
  setSingleUser?: (user: UserData) => void;
}

export enum SubmitButtonText {
  SEND = 'Send invite',
  SAVE = 'Save changes',
  SAVED = 'Saved',
}
