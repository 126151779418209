import React from 'react';
import './styles.scss';
import '../styles.scss';
import AlertOutlinedIcon from 'haven-design-system/build/assets/svgs/display/alert_outlined.svg?component';
import Button from '../../../components/Button';
import { ContractorTypeEnum } from '../../../types';

interface Props {
  onboardingContractorType: string;
  handleStartOnboarding: () => void;
  errorLoadingVideo: boolean;
  setErrorLoadingVideo: (value: boolean) => void;
}

const OnboardingLandingPage: React.FC<Props> = ({
  onboardingContractorType,
  handleStartOnboarding,
  errorLoadingVideo,
  setErrorLoadingVideo,
}) => (
  <div className="backdrop">
    <div className="container">
      <p className="text__header">
        Thanks for your interest in Inspectify!
      </p>
      <p className="text__instructions">
        This video will quickly introduce you to Inspectify,
        what we do, and what you can expect while working with us. Please review our&nbsp;
        <a
          className="text__link"
          href={onboardingContractorType === ContractorTypeEnum.Surveyor
            ? 'https://knowledge.inspectify.com/inspectify-community-guidelines-surveyors'
            : 'https://knowledge.inspectify.com/inspectify-community-guidelines-inspectors'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Community Guidelines
        </a>
        &nbsp;and visit our&nbsp;
        <a
          className="text__link"
          href={onboardingContractorType === ContractorTypeEnum.Surveyor
            ? 'https://knowledge.inspectify.com/surveyors'
            : 'https://knowledge.inspectify.com/inspectors'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Center
        </a>
        &nbsp;if you have additional questions.
      </p>
      <div className="contentContainer">
        <div className="videoInstructionsContainer">
          <div className="instructionsContainer">
            <p className="text__subheader">
              What to expect
            </p>
            <ol type="1">
              <li className="text__instructions">Complete and submit your onboarding form</li>
              <li className="text__instructions">Set up your account</li>
              <li className="text__instructions">Download the Inspectify app</li>
              <li className="text__instructions">Get started with Inspectify</li>
            </ol>
          </div>
          <div className="videoContainer">
            {errorLoadingVideo ? (
              <div className="errorContainer">
                <div>
                  <AlertOutlinedIcon />
                </div>
                <div className="errorContainerText">
                  There was an error loading the video.  Please try reloading the page or continue to the next step.
                </div>
              </div>
            ) : (
              <video
                className="video"
                width="100%"
                border-radius="8px"
                controls
                onError={() => setErrorLoadingVideo(true)}
                src={onboardingContractorType === ContractorTypeEnum.Inspector
                  ? 'https://public-inspectify.s3.us-east-1.amazonaws.com/videos/inspector-onboarding/InspectorIntroVideo.mp4'
                  : 'https://public-inspectify.s3.us-east-1.amazonaws.com/videos/surveyor-onboarding/SurveyorIntroVideo.mp4'}
              />
            )}
          </div>
        </div>
        <div className="divider" />
        <div className="buttonContainer">
          <Button onClick={handleStartOnboarding}>Start onboarding form</Button>
        </div>
      </div>
    </div>
  </div>
);

export default OnboardingLandingPage;
