import { useMutation } from '@apollo/client';
import { UPDATE_SURVEYOR_ONBOARDING } from '../../graphql/mutations';
import { captureException } from '../../utils/error';
import { UpdateOnboardingVariables } from './types';
import { OnboardingUpdateResponseType } from '../../types';

export const useUpdateSurveyorOnboarding = (variables?: UpdateOnboardingVariables) => {
  const [updateSurveyorOnboarding, { loading: updateSurveyorOnboardingLoading, data: updateSurveyorOnboardingData }] = useMutation<OnboardingUpdateResponseType, UpdateOnboardingVariables>(
    UPDATE_SURVEYOR_ONBOARDING,
    {
      onError: () => { captureException(new Error(variables?.errorMessage)); },
      onCompleted: (data: OnboardingUpdateResponseType) => { variables?.onCompleted?.(data); },
    },
  );
  return { updateSurveyorOnboarding, updateSurveyorOnboardingLoading, updateSurveyorOnboardingData };
};
