import { useLazyQuery, useQuery } from '@apollo/client';
import isNil from 'lodash/isNil';
import { FETCH_REPORT_WITH_INSPECTION_SCOPE_ITEMS, IdRef } from 'marketplace-common';
import React, { useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import InspectifyLayout from '../../components/InspectifyLayout';
import { ORDER_BY_TOKEN, SINGLE_CONTRACTOR, USER_CONTRACTOR } from '../../graphql/queries';
import {
  saveReport,
  saveWorksForOrders,
  setActiveOrder,
  setActiveReport,
  setActiveSystemGroupFilters,
  updateSplitioAttributes,
} from '../../redux/actions';
import { selectReports, selectWorks } from '../../redux/selectors';
import { useTypedSelector } from '../../redux/store';
import { PageErrorType, UserContractor } from '../../types';
import { toCapitalizedSentence } from '../../utils/string';
import { ReportContext } from './context';
import ReportsPageLoader from './page/loader';
import ReportsPdfPageLoader from './pdf';

const WEB_IMAGE_WIDTH = 180;
const PDF_IMAGE_WIDTH = 360;
const INSURANCE_PDF_IMAGE_WIDTH = 840;

interface Props {
  reportId: string;
  orderToken: string;
  redirectToRehab: boolean;
  printMode: boolean;
  rotatedAttachmentDegrees: number[];
  noDeficienciesRequired?: boolean;
  systemGroupNames?: string[];
  isAppliancesOnly?: boolean;
}

const ReportsLoader: React.FC<Props> = ({
  reportId,
  orderToken,
  redirectToRehab,
  printMode,
  rotatedAttachmentDegrees,
  noDeficienciesRequired,
  systemGroupNames,
  isAppliancesOnly,
}) => {
  const dispatch = useDispatch();
  const works = useTypedSelector(selectWorks);
  const reports = useTypedSelector(selectReports);

  const {
    state,
    set,
    setOrderPropertyInspectionsAndReportRecords,
    setInspectionWarranty,
    setOrderStakeholder,
    setContractorId,
    setSelectedReport,
    setShowDrawInspectionBudgetSummary,
    setWarrantyCoveredAppliances,
    setDrawInspectionBudget,
  } = useContext(ReportContext);

  useEffect(() => {
    if (orderToken) set('orderToken', orderToken);
    if (printMode) set('printMode', printMode);
  }, [orderToken, printMode]);

  useEffect(() => {
    if (state.orderId) setOrderPropertyInspectionsAndReportRecords(state.orderId, works);
  }, [state.orderId, works.orders, works.properties, works.inspections, works.reports]);

  useEffect(() => {
    if (state.inspections.length) setInspectionWarranty(state.inspections, works);
  }, [state.inspections, works.inspectionWarranties]);

  useEffect(() => {
    if (state.order?.orderStakeholderIds?.length && works.orderStakeholders) {
      setOrderStakeholder(state.order.orderStakeholderIds as string[], works);
    }
  }, [state.order, works.orderStakeholders]);

  useEffect(() => {
    if (state.selectedReportId && state.inspections) {
      setContractorId(state.selectedReportId, state.inspections, works);
    }
  }, [state.selectedReportId, state.inspections]);

  useEffect(() => {
    if (state.selectedReportId && reports?.[state.selectedReportId]) {
      setSelectedReport(
        reports[state.selectedReportId],
        works,
        systemGroupNames,
        noDeficienciesRequired,
        isAppliancesOnly,
      );
    }
  }, [state.selectedReportId, reports, works.inspections, works.inspectionProposals, isAppliancesOnly]);

  useEffect(() => {
    if (state.selectedReport?.informations && state.inspectionTypes) {
      setShowDrawInspectionBudgetSummary(
        state.inspectionTypes,
        state.selectedReport.informations,
      );
    }
  }, [state.selectedReport, state.inspectionTypes]);

  useEffect(() => {
    if (state.selectedReport?.informations && state.selectedReport?.applianceInformations) {
      setWarrantyCoveredAppliances(
        state.selectedReport.applianceInformations,
        state.selectedReport.informations,
      );
    }
  }, [state.selectedReport?.informations, state.selectedReport?.applianceInformations]);

  useEffect(() => {
    if (state.selectedReport && state.contractor && printMode) {
      setTimeout(() => set('displayPrintReady', true), 6000);
    }
  }, [state.selectedReport, state.contractor]);

  const reportTitle = useMemo(() => {
    const inspectionIds = state?.selectedReport?.report?.inspectionIds || [];
    const inspectionTypes = inspectionIds.reduce((acc: string[], id: IdRef) => {
      const inspectionType = works?.inspections?.[id]?.inspectionType;
      if (inspectionType) acc.push(inspectionType);
      return acc;
    }, []);
    return toCapitalizedSentence(inspectionTypes);
  }, [state?.selectedReport?.report, works.inspections]);

  useEffect(() => {
    if (state.selectedReport) {
      setDrawInspectionBudget(state.selectedReport, state.groupedReportSystemsByName);
    }
  }, [state.selectedReport]);

  const { loading: loadingUser, data: userData } = useQuery<UserContractor>(USER_CONTRACTOR);

  const imageWidth = useMemo(() => {
    if (!printMode) return WEB_IMAGE_WIDTH;

    if (works && state.selectedReportId) {
      const isInsuranceInspection = works.reports?.[state.selectedReportId]?.inspectionIds.some(
        (inspectionId) => works.inspections[inspectionId].inspectionType === 'insurance_inspection',
      );

      if (isInsuranceInspection) return INSURANCE_PDF_IMAGE_WIDTH;
    }

    return PDF_IMAGE_WIDTH;
  }, [printMode, works, state.selectedReportId]);

  const { loading: loadingWorks } = useQuery(ORDER_BY_TOKEN, {
    variables: { token: orderToken },
    context: { headers: { order_token: orderToken } },
    onCompleted: (data) => {
      if (data?.order) {
        set('orderId', data.order.id);
        dispatch(saveWorksForOrders(data, works));
      }
    },
    onError: () => {
      set('reportQueryError', PageErrorType.GeneralError);
    },
  });

  const [fetchContractor, { loading: loadingContractor }] = useLazyQuery(SINGLE_CONTRACTOR, {
    variables: { id: state.contractorId?.toString() },
    context: { headers: { order_token: orderToken } },
    onCompleted: (data) => {
      set('contractor', data.contractor);
    },
  });

  const [
    fetchReport,
    { loading: loadingReport },
  ] = useLazyQuery(FETCH_REPORT_WITH_INSPECTION_SCOPE_ITEMS, {
    context: { headers: { order_token: orderToken } },
    onCompleted: (data) => {
      if (data.report?.reportSystems) dispatch(saveReport(data));
    },
    onError: () => {
      set('reportQueryError', PageErrorType.GeneralError);
    },
  });

  useEffect(() => {
    if (state.selectedReportId) dispatch(setActiveReport(state.selectedReportId, {}));
    if (state.orderId) dispatch(setActiveOrder(state.orderId, {}));
  }, [state.selectedReportId]);

  useEffect(() => {
    if (state.selectedReportSystemGroupIds) {
      dispatch(setActiveSystemGroupFilters(state.selectedReportSystemGroupIds, {}));
    }
  }, [state.selectedReportSystemGroupIds]);

  useEffect(() => {
    if (state.selectedReportId) {
      fetchReport({
        variables: {
          id: state.selectedReportId,
          orderToken,
          imageWidths: printMode ? [PDF_IMAGE_WIDTH, INSURANCE_PDF_IMAGE_WIDTH] : [WEB_IMAGE_WIDTH],
          rotatedAttachmentDegrees,
        },
      });
    }
  }, [state.selectedReportId]);

  useEffect(() => {
    if (state.reportRecordIds && works.reports && isNil(state.selectedReportId)) {
      if (reportId) {
        set('selectedReportId', reportId);
      } else {
        const mostRecentlyPublishedReportId = (
          state.reportRecordIds as IdRef[]
        ).reduce((prev, cur) => (
          (works?.reports?.[prev]?.publishedAt > works?.reports?.[cur]?.publishedAt) ? prev : cur
        ), null);
        set('selectedReportId', mostRecentlyPublishedReportId);
      }
    }
  }, [state.reportRecordIds, works.reports, state.selectedReportId]);

  useEffect(() => {
    if (state.contractorId) fetchContractor();
  }, [state.contractorId]);

  useEffect(() => {
    if (state?.orderTeamId) {
      dispatch(updateSplitioAttributes({ teamId: state.orderTeamId }));
    }
  }, [state?.orderTeamId]);

  useEffect(() => {
    if (!state.order || !userData || !state.contractorId) return;

    set(
      'isPaywalled',
      state.order.isPaywall && state.contractorId !== userData.currentUser?.contractor?.id,
    );
  }, [state.order, state.contractorId, userData]);

  const loading = (
    loadingUser || loadingContractor || loadingWorks || loadingReport
  );

  const children = printMode ? (
    <ReportsPdfPageLoader
      imageWidth={imageWidth}
      loading={loading}
      user={userData}
    />
  ) : (
    <ReportsPageLoader
      imageWidth={imageWidth}
      redirectToRehab={redirectToRehab}
      user={userData}
      loading={loading}
    />
  );

  return (
    <>
      <Helmet>
        <title>{reportTitle}</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showFooter={false} />

      {children}
    </>
  );
};

export default ReportsLoader;
