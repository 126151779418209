import React, { useContext, useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import { slide as SlideMenu } from 'react-burger-menu';
import Filter from 'haven-design-system/build/assets/svgs/action/filter-view.svg?component';
import InspectifyLogo from '../../../../assets/inspectify/Inspectify_Logo_Color.svg';
import { downloadProjectCsv, downloadProjectPdf } from '../../../../utils/rehab';
import { ReportPropertyImage } from '../../../../components/PropertyImage/PropertyImage';
import { ReportDeliveryFilter } from '../../../../types/models';
import ContractorDetails from '../ContractorDetails';
import MenuItems from './MenuItems';
import { burgerMenuStyles } from './styles';
import styles from './styles.module.scss';
import { ReportContext } from '../../context';
import Button from '../../../../components/Button';
import { useReportPdf } from '../../../../hooks/report/useReportPdf';

interface Props {
  filter: ReportDeliveryFilter;
  groupNames: string[] | null;
  toggleReportFilterModal: (value: boolean) => void;
}

const MobileMenu: React.FC<Props> = ({
  filter,
  groupNames,
  toggleReportFilterModal,
}) => {
  const { state } = useContext(ReportContext);
  const { downloadReportPdf } = useReportPdf();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const formattedSystemGroupNames = useMemo(() => {
    if (groupNames?.length > 1) {
      return `, ${groupNames.length} units`;
    }

    if (groupNames?.length > 0) {
      return `, ${groupNames[0]}`;
    }

    return null;
  }, [groupNames]);

  return (
    <Col className={styles.mobileHeaderContainer}>
      <div className={styles.mobileHeaderRow}>
        <img src={InspectifyLogo} alt="Inspectify" className={styles.logo} />
        <SlideMenu
          right
          isOpen={isMobileMenuOpen}
          onStateChange={({ isOpen }) => setIsMobileMenuOpen(isOpen)}
          styles={burgerMenuStyles}
        >
          <Button
            id="filter-report"
            onClick={() => toggleReportFilterModal(true)}
            style={{ width: '100%', marginBottom: '8px' }}
            secondary
          >
            <Filter /> Filter: {filter}{formattedSystemGroupNames}
          </Button>

          {state.selectedReport?.report?.publishedAt && (
          <Button
            onClick={() => downloadReportPdf()}
            style={{ width: '100%', marginBottom: '8px' }}
            secondary
          >
            Download inspection report (PDF)
          </Button>
          )}

          {state.existingRehabProjectId && (
            <Button
              onClick={() => downloadProjectPdf(state.existingRehabProjectId)}
              style={{ width: '100%', marginBottom: '8px' }}
              secondary
            >
              Download rehab project PDF
            </Button>
          )}

          {state.existingRehabProjectId && (
            <Button
              onClick={() => downloadProjectCsv(state.existingRehabProjectId)}
              style={{ width: '100%', marginBottom: '8px' }}
              secondary
            >
              Download rehab project CSV
            </Button>
          )}

          <MenuItems
            isMobileView
            onMobileItemPress={() => { setIsMobileMenuOpen(false); }}
            filter={filter}
          />
          {state.contractor && (
            <ContractorDetails
              contractor={state.contractor}
              state={state.property.state}
            />
          )}
        </SlideMenu>
      </div>

      <div className={styles.propertyImageAndButtonSection}>
        <ReportPropertyImage property={state.property} />
      </div>
    </Col>
  );
};

export default MobileMenu;
