import React from 'react';
import cn from 'classnames';
import { ReportSeverityType } from 'marketplace-common';
import LimitationIcon from 'haven-design-system/build/assets/svgs/issues/limitation_outlined.svg?component';
import NotPresentIcon from 'haven-design-system/build/assets/svgs/issues/not-present.svg?component';
import IssueIcon from 'haven-design-system/build/assets/svgs/issues/deficiency_outlined.svg?component';
import SafetyIcon from 'haven-design-system/build/assets/svgs/issues/safety.svg?component';
import MonitorIcon from 'haven-design-system/build/assets/svgs/issues/monitor.svg?component';
import RepairIcon from 'haven-design-system/build/assets/svgs/issues/repair.svg?component';
import FixedIcon from 'haven-design-system/build/assets/svgs/action/confirm.svg?component';
import FlagIcon from 'haven-design-system/build/assets/svgs/issues/flag_filled.svg?component';
import styles from './styles.module.scss';

interface Props {
  type: 'fixed' | 'not_present' | 'cannot_inspect' | 'limitation' | 'issue' | ReportSeverityType
  count?: number
  noDeficienciesAllowed?: boolean
  hideDeficiencies?: boolean
}

const IssueBadge: React.FC<Props> = ({
  type,
  count,
  noDeficienciesAllowed,
  hideDeficiencies,
}) => {
  let total = count;
  let text = '';
  let icon = null;

  switch (type) {
    case 'fixed':
      text = 'Fixed';
      icon = <FixedIcon />;
      break;
    case 'not_present':
      text = 'Not present';
      icon = <NotPresentIcon />;
      break;
    case 'cannot_inspect':
      text = 'Cannot inspect';
      icon = <NotPresentIcon />;
      break;
    case 'limitation':
      text = 'Limitation';
      icon = <LimitationIcon />;
      break;
    case 'issue':
      if (hideDeficiencies || (noDeficienciesAllowed && count === 0)) {
        total = undefined;
        text = 'Inspected';
        icon = <FixedIcon className={styles.icon} />;
        break;
      } else if (count === 0) {
        total = undefined;
        text = 'No issues';
        icon = <FixedIcon className={styles.icon} />;
        break;
      }
      text = count > 1 ? 'Issues' : 'Issue';
      icon = <IssueIcon className={styles.icon} />;
      break;
    case ReportSeverityType.safety:
      text = 'Safety deficiency';
      icon = <SafetyIcon className={styles.icon} />;
      break;
    case ReportSeverityType.repair:
      text = 'Repair deficiency';
      icon = <RepairIcon className={styles.icon} />;
      break;
    case ReportSeverityType.monitor:
      text = 'Monitor deficiency';
      icon = <MonitorIcon className={styles.icon} />;
      break;
    case ReportSeverityType.inspectify_flag:
      text = 'Inspectify flag';
      icon = <FlagIcon className={styles.icon} />;
      break;
    default:
      return null;
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.fixed]: type === 'fixed',
        [styles.cannotInspect]: ['cannot_inspect', 'not_present'].includes(type),
        [styles.limitation]: type === 'limitation',
        [styles.noIssues]: type === 'issue' && count === 0,
        [styles.issue]: type === 'issue' && count,
        [styles.inspected]: type === 'issue' && hideDeficiencies,
        [styles.repair]: type === ReportSeverityType.repair,
        [styles.safety]: type === ReportSeverityType.safety,
        [styles.monitor]: type === ReportSeverityType.monitor,
        [styles.flagged]: type === ReportSeverityType.inspectify_flag,
      })}
    >
      {icon}
      <small className={cn(styles.text)}>{total || ''} {text}</small>
    </div>
  );
};

export default IssueBadge;
