import React, { useMemo } from 'react';
import { IdRef } from 'marketplace-common';
import {
  RehabContainer,
  RowContainer,
  TotalText,
  MenuItem,
  MenuText,
  MenuPrice,
  MenuLastPublishedAt,
  publishProjectButtonStyles,
  MissingPricesWarningText,
  InfoIconMarginLeft,
  InfoIconMarginRight,
} from '../../../Reports/components/Menu/styles';
import InfoUnfilledIcon from '../../../../assets/icons/info_unfilled.svg';
import { formatMoney } from '../../../../utils/money';
import { getPricesBySystem, getTotalToDisplay } from '../../../../utils/rehab';
import { WorkItemModalOptions } from '../../types';
import { MenuItemsContainer } from './styles';
import { useTypedSelector } from '../../../../redux/store';
import { selectRehab, selectTeams } from '../../../../redux/selectors';
import { getFormattedDateTime } from '../../../../utils/date';
import Button from '../../../../components/Button';
import { RehabAddWorkItemButton } from '../AddWorkItemButton/AddWorkItemButton';
import Spinner from '../../../../components/Spinner';

interface Props {
  isRehabReadOnly: boolean;
  teamId: IdRef;
  projectId: IdRef;
  isPublishing: boolean;
  handleWorkItemModal?: (options: WorkItemModalOptions) => void;
  handlePublishRehabProject: () => void;
  fullHeight?: boolean;
}

const RehabMenuContainer: React.FC<Props> = ({
  isRehabReadOnly,
  teamId,
  projectId,
  isPublishing,
  handleWorkItemModal,
  handlePublishRehabProject,
  fullHeight = true,
}) => {
  const rehab = useTypedSelector(selectRehab);
  const teams = useTypedSelector(selectTeams);
  const pricesBySystem = useMemo(() => getPricesBySystem(rehab.workItems), [rehab]);
  const totalToDisplay = useMemo(() => getTotalToDisplay(pricesBySystem), [pricesBySystem]);
  const project = useMemo(() => rehab.projects[projectId], [rehab]);

  const canPublishRehabProjects = useMemo(
    // Unfortunately, teams is indexed using team token...
    () => Object.values(teams).find((t) => t.id === teamId)?.canPublishRehabProjects || false,
    [teams, teamId],
  );

  const isAnyPriceMissing = useMemo(
    () => Object.values(pricesBySystem).some((system) => system.missingPrice),
    [pricesBySystem],
  );

  return (
    <RehabContainer data-testid="rehabMenuContainer" fullHeight={fullHeight}>
      <MenuItemsContainer>
        {Object.values(pricesBySystem).map((system) => (
          <div key={system.id}>
            <MenuItem isActive={false} isDisabled isRehab>
              <MenuText isRehab>{system.id}
                {system.missingPrice && (<InfoIconMarginLeft src={InfoUnfilledIcon} />)}
              </MenuText>

              <MenuPrice>{formatMoney(system.price)}</MenuPrice>
            </MenuItem>
          </div>
        ))}
      </MenuItemsContainer>
      <RowContainer>
        <TotalText>Total</TotalText>
        <TotalText isBold>{totalToDisplay}</TotalText>
      </RowContainer>
      {isAnyPriceMissing && (
        <MissingPricesWarningText>
          <InfoIconMarginRight src={InfoUnfilledIcon} />Some work items have no pricing assigned
        </MissingPricesWarningText>
      )}
      {!isRehabReadOnly && (
        <>
          <RehabAddWorkItemButton
            onClick={() => handleWorkItemModal({
              isOpen: true,
              attachments: [],
              isAddingFromReport: false,
            })}
          />
          {canPublishRehabProjects && (
            <>
              <Button
                secondary
                style={publishProjectButtonStyles}
                disabled={isPublishing}
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  if (window.confirm('Are you sure you want to publish this project?')) handlePublishRehabProject();
                }}
              >
                {isPublishing ? <Spinner style={{ padding: 0 }} /> : 'Publish Project'}
              </Button>
              {project.publishedAt && (
                <MenuLastPublishedAt>
                  Last Published: {getFormattedDateTime(project.publishedAt, false)}
                </MenuLastPublishedAt>
              )}
            </>
          )}
        </>
      )}
    </RehabContainer>
  );
};

export default RehabMenuContainer;
