import { useEffect, useMemo, useReducer } from 'react';
import uniq from 'lodash/uniq';
import { IdRef } from 'marketplace-common';
import { WorkItem } from '../../../../../types';
import { useTypedSelector } from '../../../../../redux/store';
import { selectRehab, selectTeams } from '../../../../../redux/selectors';
import {
  ExternalTagTeamIds,
  WorkItemFormState,
  WorkItemFormComputedState,
  TabOptions,
} from './types';

export const defaultExternalTagTeamIds: (ExternalTagTeamIds | IdRef)[] = [
  ExternalTagTeamIds.SECOND_AVE,
  ExternalTagTeamIds.SOVEREIGN,
];

export const getDefaultTagsForTeam = (teamId: IdRef) => (
  defaultExternalTagTeamIds.includes(String(teamId)) ? ['External'] : []
);

export const createInitialState = (
  systemNameOrigin = '',
  workItem = {} as WorkItem,
  teamId = '' as IdRef,
  modalTemplateWorkItemPricingId?: string | null,
): WorkItemFormState => ({
  selectedTab: workItem?.title && workItem?.details ? TabOptions.WorkItemFormTab : TabOptions.WorkItemLibraryTab,
  systemName: systemNameOrigin || '',
  title: workItem?.title || '',
  description: workItem?.details || '',
  unit: workItem?.unit || '',
  unitCost: workItem?.pricePerUnit || 0,
  totalCost: workItem?.totalPrice || 0,
  tags: uniq([...getDefaultTagsForTeam(teamId), ...(workItem?.tags || [])]),
  quantity: workItem?.quantity || 1,
  saveToLibrary: false,
  useLumpSumCost: !!(workItem?.pricePerUnit == null || !workItem?.unit),
  isRemoveModalOpen: false,
  isContributionDistribution: false,
  teamMarketId: '',
  templateWorkItemPricingId: modalTemplateWorkItemPricingId || '',
  pricingExternalId: '',
});

export const reducer = (state: WorkItemFormState, action: any) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.key]: action.value };
    case 'SET':
      return { ...state, ...action.value };
    default:
      return state;
  }
};

type WorkItemFormStateParams = {
  teamId: IdRef
  systemNameOrigin: string
  workItem: WorkItem
  modalTemplateWorkItemPricingId?: string | null
};

export const useWorkItemFormState = ({
  teamId,
  systemNameOrigin,
  workItem,
  modalTemplateWorkItemPricingId,
}: WorkItemFormStateParams) => {
  const initialState = createInitialState(
    systemNameOrigin,
    workItem,
    teamId,
    modalTemplateWorkItemPricingId,
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const rehab = useTypedSelector(selectRehab);
  const teams = useTypedSelector(selectTeams);
  const team = useMemo(
    // Unfortunately, teams is indexed using team token...
    () => Object.values(teams).find((t) => t.id === teamId),
    [teams, teamId],
  );

  const workItemPricing = useMemo(() => {
    if (!state.templateWorkItemPricingId) return null;
    return rehab.templateWorkItemPricings?.[state.templateWorkItemPricingId];
  }, [state.templateWorkItemPricingId, rehab.templateWorkItemPricings]);

  const templateWorkItem = useMemo(() => {
    if (!workItemPricing?.templateWorkItemId) return null;
    return rehab.templateWorkItems?.[workItemPricing.templateWorkItemId];
  }, [workItemPricing, rehab.templateWorkItems]);

  const workItemPricings = useMemo(() => {
    if (!templateWorkItem) return [];
    return Object.values(rehab.templateWorkItemPricings).filter((pricing) => (
      pricing.templateWorkItemId === templateWorkItem.id
    ));
  }, [templateWorkItem, rehab.templateWorkItemPricings]);

  const rehabTags = useMemo(() => {
    const items = [
      ...Object.values(rehab.templateWorkItemPricings || []),
      ...(workItemPricings || []),
    ];
    const pricingTags = items.reduce(
      (acc, item) => (item?.tags?.length ? [...acc, ...item.tags] : acc),
      [],
    );
    const workItemTags = Object.values(rehab.workItems || []).reduce(
      (acc, { tags }) => (tags.length ? [...acc, ...tags] : acc),
      [],
    );

    return uniq([...pricingTags, ...workItemTags, ...(team?.rehabTags || [])]);
  }, [team, rehab.workItems, workItemPricings]);

  const calculatedTotal = useMemo(() => (
    state.quantity * state.unitCost
  ), [state.quantity, state.unitCost]);

  useEffect(() => {
    if (workItemPricing) {
      dispatch({
        type: 'SET',
        value: {
          teamMarketId: workItemPricing.teamMarketId,
          templateWorkItemPricingId: workItemPricing.id,
          pricingExternalId: workItemPricing.pricingExternalId,
        },
      });
    }
  }, [workItemPricing]);

  const computedState: WorkItemFormComputedState = {
    team,
    rehabTags,
    calculatedTotal,
    workItemPricing,
    workItemPricings,
  };

  return {
    state: state as WorkItemFormState,
    computedState,
    dispatch,
    set: (value: any) => dispatch({ type: 'SET', value }),
    update: (key: string, value: any) => dispatch({ type: 'UPDATE', key, value }),
  };
};
