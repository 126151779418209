import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { isEmpty } from 'lodash';
import { IdRef } from 'marketplace-common';
import {
  Project,
  TeamCustomSystem,
  TeamMarket,
  TemplateWorkItemPricingsInterface,
  TemplateWorkItemsInterface,
  WorkItemAssociationsInterface,
  WorkItemAttachmentsInterface,
  WorkItemContributorType,
  WorkItemsInterface,
} from '../../types';
import {
  normalizeProjectHistoryGqlResponse,
  normalizeGraphqlCreateWorkItemAssociationResponse,
  normalizeGraphqlCreateWorkItemAttachmentResponse,
  normalizeGraphqlCreateWorkItemResponse,
  normalizeGraphqlRehabProjectResponse,
  normalizeGraphqlUpdateWorkItemResponse,
  normalizeGraphqlTemplateWorkItemsResponse,
} from '../../utils/rehab';
import { addProperty } from './property';
import { saveReport } from './report';
import { addTeam } from './team';
import {
  SET_PROJECT,
  ADD_REHAB_TEAM_MARKETS,
  ADD_WORK_ITEMS,
  ADD_WORK_ITEM_ASSOCIATIONS,
  ADD_WORK_ITEM_ATTACHMENTS,
  DELETE_WORK_ITEM,
  DELETE_WORK_ITEM_ASSOCIATIONS,
  ADD_TEMPLATE_WORK_ITEMS,
  ADD_TEMPLATE_WORK_ITEM_PRICINGS,
  DELETE_WORK_ITEM_ATTACHMENTS,
  ADD_PROJECT_HISTORY,
  CLEAR_TEMPLATE_WORK_ITEMS,
  ADD_WORK_ITEM_CONTRIBUTOR,
  ADD_REHAB_TEAM_CUSTOM_SYSTEMS,
} from './types';

export const setRehabProject = (
  project: Project,
) => (dispatch: any) => {
  dispatch({
    type: SET_PROJECT,
    payload: { project },
  });
};

export const addRehabTeamMarkets = (
  rehabTeamMarkets: TeamMarket[],
) => (dispatch: any) => {
  dispatch({
    type: ADD_REHAB_TEAM_MARKETS,
    payload: { rehabTeamMarkets },
  });
};

export const addRehabTeamCustomSystems = (
  rehabTeamCustomSystems: TeamCustomSystem[],
) => (dispatch: any) => {
  dispatch({
    type: ADD_REHAB_TEAM_CUSTOM_SYSTEMS,
    payload: { rehabTeamCustomSystems },
  });
};

export const addRehabWorkItems = (workItems: WorkItemsInterface) => (dispatch: any) => {
  dispatch({
    type: ADD_WORK_ITEMS,
    payload: { workItems },
  });
};

export const deleteRehabWorkItem = (workItemId: string) => (dispatch: any) => {
  dispatch({
    type: DELETE_WORK_ITEM,
    payload: { workItemId },
  });
};

export const addRehabWorkItemAssociations = (
  workItemAssociations: WorkItemAssociationsInterface,
) => (dispatch: any) => {
  dispatch({
    type: ADD_WORK_ITEM_ASSOCIATIONS,
    payload: { workItemAssociations },
  });
};

export const deleteRehabWorkItemAssociations = (
  workItemAssociationIds: string[],
) => (dispatch: any) => {
  dispatch({
    type: DELETE_WORK_ITEM_ASSOCIATIONS,
    payload: { workItemAssociationIds },
  });
};

export const deleteRehabWorkItemAttachments = (
  workItemAttachmentIds: string[],
) => (dispatch: any) => {
  dispatch({
    type: DELETE_WORK_ITEM_ATTACHMENTS,
    payload: { workItemAttachmentIds },
  });
};

export const addRehabWorkItemContributor = (
  contributor: WorkItemContributorType,
) => (dispatch: any) => {
  dispatch({
    type: ADD_WORK_ITEM_CONTRIBUTOR,
    payload: { contributor },
  });
};

export const addRehabWorkItemAttachments = (
  workItemAttachments: WorkItemAttachmentsInterface,
) => (dispatch: any) => {
  dispatch({
    type: ADD_WORK_ITEM_ATTACHMENTS,
    payload: { workItemAttachments },
  });
};

export const addTemplateWorkItems = (
  templateWorkItems: TemplateWorkItemsInterface,
) => (dispatch: any) => {
  dispatch({
    type: ADD_TEMPLATE_WORK_ITEMS,
    payload: { templateWorkItems },
  });
};

export const addTemplateWorkItemPricings = (
  templateWorkItemPricings: TemplateWorkItemPricingsInterface,
) => (dispatch: any) => {
  dispatch({
    type: ADD_TEMPLATE_WORK_ITEM_PRICINGS,
    payload: { templateWorkItemPricings },
  });
};

export const clearTemplateWorkItems = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_TEMPLATE_WORK_ITEMS,
    payload: { },
  });
};

export const saveRehabProject = (
  data: any,
  isReadOnly = false,
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
) : Promise<any> => {
  const normalizedRehabProject = normalizeGraphqlRehabProjectResponse(data);

  if (normalizedRehabProject) {
    const {
      projectData,
      workItemsData,
      workItemAssociationsData,
      workItemAttachmentsData,
      reportData,
      propertyData,
      rehabTeamMarkets,
      rehabTeamCustomSystems,
      teamData,
    } = normalizedRehabProject;

    if (!isReadOnly && !isEmpty(reportData?.report)) {
      dispatch(saveReport(reportData));
    }
    dispatch(addProperty(propertyData));
    dispatch(addRehabTeamMarkets(rehabTeamMarkets));
    dispatch(addRehabTeamCustomSystems(rehabTeamCustomSystems));
    dispatch(addTeam(teamData));
    dispatch(addRehabWorkItemAttachments(workItemAttachmentsData));
    dispatch(addRehabWorkItemAssociations(workItemAssociationsData));
    dispatch(addRehabWorkItems(workItemsData));
    dispatch(setRehabProject(projectData));
  }
  return Promise.resolve();
};

export const saveTemplateWorkItems = (
  templateWorkItemNodes: any,
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
): Promise<any> => {
  const normalizedTemplateWorkItems = normalizeGraphqlTemplateWorkItemsResponse(
    templateWorkItemNodes,
  );

  if (normalizedTemplateWorkItems) {
    const {
      templateWorkItems,
      templateWorkItemPricings,
    } = normalizedTemplateWorkItems;

    dispatch(addTemplateWorkItemPricings(templateWorkItemPricings));
    dispatch(addTemplateWorkItems(templateWorkItems));
  }
  return Promise.resolve();
};

export const saveWorkItem = (
  data: any,
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
): Promise<any> => {
  const normalizedResponse = normalizeGraphqlCreateWorkItemResponse(data);

  if (normalizedResponse) {
    const {
      workItemData,
      templateWorkItemData,
      templateWorkItemPricingsData,
    } = normalizedResponse;

    dispatch(addRehabWorkItems(workItemData));

    if (templateWorkItemData && templateWorkItemPricingsData) {
      dispatch(addTemplateWorkItemPricings(templateWorkItemPricingsData));
      dispatch(addTemplateWorkItems(templateWorkItemData));
    }
    return Promise.resolve(normalizedResponse);
  }
  return Promise.resolve(null);
};

export const updateWorkItem = (data: any) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
): Promise<any> => {
  const normalizedResponse = normalizeGraphqlUpdateWorkItemResponse(data);

  if (normalizedResponse) {
    dispatch(addRehabWorkItems(normalizedResponse));
    return Promise.resolve(normalizedResponse);
  }
  return Promise.resolve(null);
};

export const deleteWorkItem = (
  workItemId: string,
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
) : Promise<any> => {
  dispatch(deleteRehabWorkItem(workItemId));
  return Promise.resolve();
};

export const saveWorkItemAssociation = (
  data: any,
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
): Promise<any> => {
  const normalizedWorkItemAssociation = normalizeGraphqlCreateWorkItemAssociationResponse(data);

  if (normalizedWorkItemAssociation) {
    dispatch(addRehabWorkItemAssociations(normalizedWorkItemAssociation));
  }
  return Promise.resolve();
};

export const saveWorkItemAttachment = (
  data: any,
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
) : Promise<any> => {
  const normalizedWorkItemAttachment = normalizeGraphqlCreateWorkItemAttachmentResponse(data);

  if (normalizedWorkItemAttachment) {
    dispatch(addRehabWorkItemAttachments(normalizedWorkItemAttachment));
  }
  return Promise.resolve();
};

export const deleteWorkItemAssociations = (
  workItemAssociationIds: string[],
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
) : Promise<any> => {
  dispatch(deleteRehabWorkItemAssociations(workItemAssociationIds));
  return Promise.resolve();
};

export const saveProjectHistory = (
  projectId: IdRef,
  data: any,
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
): Promise<any> => {
  const normalizedProjectHistory = normalizeProjectHistoryGqlResponse(data);

  if (normalizedProjectHistory) {
    dispatch({
      type: ADD_PROJECT_HISTORY,
      payload: {
        projectId,
        projectHistory: normalizedProjectHistory,
      },
    });
  }

  return Promise.resolve();
};
