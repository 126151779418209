import React, { useMemo, useState } from 'react';
import { Report } from 'marketplace-common';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import { WorkItem, WorkItemsInterface } from '../../../../types';
import ReactSelect from '../../../../components/ReactSelect';
import {
  RehabWorkItemsContainer,
  SystemName,
} from './styles';
import RehabWorkItem from './RehabWorkItem';
import { WorkItemModalOptions } from '../../types';
import { useTypedSelector } from '../../../../redux/store';
import { selectRehab } from '../../../../redux/selectors';
import styles from '../../../Reports/components/styles.module.scss';

interface Props {
  projectId: string;
  report: Report;
  isRehabReadOnly: boolean | null;
  handleWorkItemModal?: (options: WorkItemModalOptions) => void;
}

const RehabWorkItems: React.FC<Props> = ({
  projectId,
  report,
  isRehabReadOnly,
  handleWorkItemModal,
}) => {
  const rehab = useTypedSelector(selectRehab);
  const [selectedTags, setSelectedTags] = useState([] as string[]);

  const tags = useMemo(() => {
    const workItemTags = Object.values(rehab?.workItems || {}).reduce((acc: string[], item) => {
      const contributorNames = item.workItemContributors
        ? item.workItemContributors.flatMap((contributor) => (contributor.name ? [contributor.name] : []))
        : [];
      return [...acc, ...(item.tags || []), ...contributorNames];
    }, []);
    return uniq(workItemTags);
  }, [rehab.workItems]);

  const filters = useMemo(() => ([
    { label: 'Tags', options: tags.map((value) => ({ label: value, value })) },
  ]), [tags]);

  const filteredWorkItems = useMemo(() => (
    projectId && rehab.workItems ? Object.values(rehab.workItems).reduce((acc, workItem) => {
      const allTags = [
        ...(workItem.tags || []),
        ...(workItem.workItemContributors?.flatMap((contributor) => (contributor.name ? [contributor.name] : [])) || []),
      ];
      const tagsMatch = selectedTags.length === 0 || intersection(allTags, selectedTags).length;
      return (workItem.projectId === projectId && tagsMatch)
        ? { ...acc, [workItem.id]: workItem }
        : acc;
    }, {} as WorkItemsInterface) : {}
  ), [
    rehab.workItems,
    rehab.workItemAttachments,
    rehab.workItemAssociations,
    projectId,
    selectedTags,
  ]);

  const workItemsBySystem = useMemo(() => {
    if (filteredWorkItems) {
      return Object.values(filteredWorkItems).reduce((acc, workItem) => {
        if (acc[workItem.systemName]) {
          acc[workItem.systemName].push(workItem);
        } else {
          acc[workItem.systemName] = [workItem];
        }
        return acc;
      }, {} as { [systemName: string]: [WorkItem] });
    }
    return {};
  }, [filteredWorkItems]);

  const workItemsCount = Object.keys(filteredWorkItems).length;

  return (
    <RehabWorkItemsContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h2 className={styles.pageHeader}>{workItemsCount} Work Items</h2>
        <ReactSelect
          placeholder="Filters"
          options={filters}
          onChange={(options) => {
            setSelectedTags(options ? options.map(({ value }: any) => value) : []);
          }}
          isMulti
          width="unset"
        />
      </div>
      {Object.keys(workItemsBySystem).map((system, i) => (
        <div key={`${system}-${i + 1}`}>
          <SystemName>{system}</SystemName>
          {workItemsBySystem[system].map((workItem) => (
            <RehabWorkItem
              key={workItem.id}
              workItemId={workItem.id}
              report={report}
              isRehabReadOnly={isRehabReadOnly}
              handleWorkItemModal={handleWorkItemModal}
            />
          ))}
        </div>
      ))}
    </RehabWorkItemsContainer>
  );
};

export default RehabWorkItems;
