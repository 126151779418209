/*
 * action types
 */
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const SET_ACTIVE_ITEMS = 'SET_ACTIVE_ITEMS';

export const ADD_REPORT = 'ADD_REPORT';
export const ADD_REPORT_ATTACHMENT = 'ADD_REPORT_ATTACHMENT';

export const SET_WARRANTY = 'SET_WARRANTY';

export const APPEND_WORKS = 'APPEND_WORKS';
export const REPLACE_WORKS = 'REPLACE_WORKS';
export const UPDATE_JOB = 'UPDATE_JOB';
export const CLEAR_COMPLETED_JOBS = 'CLEAR_COMPLETED_JOBS';
export const CLEAR_UPCOMING_JOBS = 'CLEAR_UPCOMING_JOBS';

export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const PUT_ORDER = 'PUT_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';

export const ADD_TEAM = 'ADD_TEAM';
export const GET_TEAM = 'GET_TEAM';

export const CREATE_INSPECTION = 'CREATE_INSPECTION';
export const GET_INSPECTIONS = 'GET_INSPECTIONS';
export const ADD_INSPECTION = 'ADD_INSPECTION';
export const CLEAR_INSPECTIONS = 'CLEAR_INSPECTIONS';

export const CREATE_INSPECTION_PROPOSAL = 'CREATE_INSPECTION_PROPOSAL';
export const GET_INSPECTION_PROPOSALS = 'GET_INSPECTION_PROPOSAL';
export const ADD_INSPECTION_PROPOSAL = 'ADD_INSPECTION_PROPOSAL';
export const SET_INSPECTION_PROPOSALS = 'SET_INSPECTION_PROPOSALS';

export const GET_BIDS = 'GET_BIDS';
export const SET_BIDS = 'SET_BIDS';

export const GET_PROPERTY = 'GET_PROPERTY';
export const PUT_PROPERTY = 'PUT_PROPERTY';
export const ADD_PROPERTY = 'ADD_PROPERTY';

export const GET_CONTRACTOR = 'GET_CONTRACTOR';
export const ADD_CONTRACTOR = 'ADD_CONTRACTOR';

export const GET_STAKEHOLDER = 'GET_STAKEHOLDER';
export const GET_STAKEHOLDERS = 'GET_STAKEHOLDERS';
export const PUT_STAKEHOLDER = 'PUT_STAKEHOLDER';
export const ADD_STAKEHOLDER = 'ADD_STAKEHOLDER';
export const SET_STAKEHOLDERS = 'SET_STAKEHOLDERS';

export const GET_AVAILABILITIES = 'GET_AVAILABILITIES';
export const SET_AVAILABILITIES = 'SET_AVAILABILITIES';
export const PUT_AVAILABILITIES = 'PUT_AVAILABILITIES';

export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const API_ERROR = 'API_ERROR';

export const SET_DISPLAY_HEADER = 'SET_DISPLAY_HEADER';
export const SET_LAYOUT_COBRANDED_LOGO = 'SET_LAYOUT_COBRANDED_LOGO';
export const RESET_LAYOUT = 'RESET_LAYOUT';
export const SET_LAYOUT_IS_LOADING = 'SET_LAYOUT_IS_LOADING';
export const SET_LAYOUT_FADED_FOOTER = 'SET_FADED_FOOTER';
export const SET_LAYOUT_SHOW_FOOTER = 'SET_SHOW_FOOTER';
export const SET_LAYOUT_SHOW_SIGNIN = 'SET_SHOW_SIGNIN';
export const SET_LAYOUT_HIDE_HEADER_ON_MOBILE = 'SET_LAYOUT_HIDE_HEADER_ON_MOBILE';

export const SET_PROJECT = 'SET_PROJECT';
export const ADD_REHAB_TEAM_MARKETS = 'ADD_REHAB_TEAM_MARKETS';
export const ADD_REHAB_TEAM_CUSTOM_SYSTEMS = 'ADD_REHAB_TEAM_CUSTOM_SYSTEMS';
export const DELETE_REHAB_TEAM_CUSTOM_SYSTEM = 'DELETE_REHAB_TEAM_CUSTOM_SYSTEM';
export const ADD_WORK_ITEMS = 'ADD_WORK_ITEMS';
export const DELETE_WORK_ITEM = 'DELETE_WORK_ITEM';
export const ADD_WORK_ITEM_ASSOCIATIONS = 'ADD_WORK_ITEM_ASSOCIATIONS';
export const DELETE_WORK_ITEM_ASSOCIATIONS = 'DELETE_WORK_ITEM_ASSOCIATIONS';
export const DELETE_WORK_ITEM_ATTACHMENTS = 'DELETE_WORK_ITEM_ATTACHMENTS';
export const ADD_WORK_ITEM_ATTACHMENTS = 'ADD_WORK_ITEM_ATTACHMENTS';
export const ADD_TEMPLATE_WORK_ITEMS = 'ADD_TEMPLATE_WORK_ITEMS';
export const ADD_TEMPLATE_WORK_ITEM_PRICINGS = 'ADD_TEMPLATE_WORK_ITEM_PRICINGS';
export const CLEAR_TEMPLATE_WORK_ITEMS = 'CLEAR_TEMPLATE_WORK_ITEMS';
export const ADD_PROJECT_HISTORY = 'ADD_PROJECT_HISTORY';

export const ADD_MANAGEABLE_USERS = 'ADD_MANAGEABLE_USERS';
export const ADD_WORK_ITEM_CONTRIBUTOR = 'ADD_WORK_ITEM_CONTRIBUTOR';
