import React, { useMemo } from 'react';
import { useManageableUsers } from '../../../hooks/user';
import Spinner from '../../../components/Spinner';
import ErrorMessage from '../../../components/ErrorMessage';
import GeneralErrorImage from '../../../assets/images/generalErrorImage.svg';
import { captureException } from '../../../utils/error';
import { User } from '../../../types';
import UserManagement from './UserManagement';
import {
  getFirstName, getLastName, getPermissionNames, getUserStatus,
} from '../../../utils/user';
import { UserData, UserManagementLoaderProps } from './types';

const UserManagementLoader: React.FC<UserManagementLoaderProps> = ({ currentUser }) => {
  const {
    loading, error, usersData,
  } = useManageableUsers();

  const users: UserData[] = useMemo(() => usersData?.map((user: User, index: number) => ({
    ...user,
    firstName: getFirstName(user.name),
    lastName: getLastName(user.name),
    status: getUserStatus(user),
    iconColor: index % 8,
    permissions: getPermissionNames(user.grantedPermissions),
  })), [usersData]);

  const usersAdded: boolean = users.length > 1;
  const isMultiInspectorFirm: boolean = currentUser?.contractor?.currentInspectorOnboarding?.isMultiInspectorFirm;

  if (loading) {
    return <Spinner style={{ marginTop: 100 }} />;
  }

  if (error || (!usersData)) {
    captureException('Failed to load available users', error);
    return (
      <div className="errorMessageContainer">
        <div className="errorMessage">
          <ErrorMessage imageUrl={GeneralErrorImage} header="Something went wrong" message="Please try again, or report the issue to support" updateButton />
        </div>
      </div>
    );
  }

  return (
    <UserManagement
      userData={users}
      usersAdded={usersAdded}
      isMultiInspectorFirm={isMultiInspectorFirm}
    />
  );
};

export default UserManagementLoader;
