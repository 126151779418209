import { gql } from '@apollo/client';
import { FULL_CONTRACTOR_FIELDS } from '../queries';

export const INSPECTOR_ONBOARDING = gql`
  ${FULL_CONTRACTOR_FIELDS}
  query($id: ID!) {
    inspectorOnboarding(id: $id) {
      id
      appLoggedIn
      canSetPassword
      completedStep
      contractor {
        ...FullContractorFields
      }
      contractorServicesAdded
      createdAt
      insuranceCertificateUploaded
      isMultiInspectorFirm
      misaSigned
      onboardingTrackerDismissedAt
      paymentDetailsFilled
      status
      updatedAt
      usersAdded
    }
  }
`;
