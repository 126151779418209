import { User, UserPermissionEnum, UserStatusEnum } from '../../../types';

export interface UserManagementLoaderProps {
  currentUser: User,
}
export interface UserManagementProps {
  userData: UserData[],
  usersAdded: boolean,
  isMultiInspectorFirm: boolean,
}

export type UserData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: UserStatusEnum;
  permissions?: UserPermissionEnum[];
  iconColor?: number;
  id?: string,
  activatedAt?: number,
  archivedAt?: number,
};

export enum ManageUsersView {
  ManageUsers = 'Manage Users',
  UserForm = 'User Form',
}

export enum UserRowContent {
  ArchivedOn = 'Archived on',
  ArchivedOff = 'Archived off',
  Standard = 'Standard',
}
