import { LocalInspection, Job, JobStatus } from 'marketplace-common';
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { InspectorDashboardContext } from '../../../context';
import {
  ButtonContainer,
} from '../styles';
import Button from '../../../../../components/Button';
import { selectUrlOrFileStep, selectApplicableInspectionsStep, uploadingStep } from './ReportUploadSteps';
import { updateJob } from '../../../../../redux/actions';

interface Props {
  job: Job;
  modalIsOpen: boolean;
  closeModal: () => void;
  selectedInspection: LocalInspection;
  inspections: LocalInspection[];
  useSpectora: boolean;
  fileForUpload: File;
  urlForUpload: string;
  otherSelectedInspections: LocalInspection[];
  setOtherSelectedInspections: Dispatch<React.SetStateAction<LocalInspection[]>>;
  setFileForUpload: Dispatch<SetStateAction<File>>;
  setUrlForUpload: Dispatch<SetStateAction<string>>;
  upload: () => void;
  error: string;
  setError: (str: string) => void;
}

const ReportUploadModal: React.FC<Props> = ({
  job,
  modalIsOpen,
  closeModal,
  selectedInspection,
  inspections,
  useSpectora,
  setFileForUpload,
  setUrlForUpload,
  setOtherSelectedInspections,
  fileForUpload,
  urlForUpload,
  otherSelectedInspections,
  upload,
  error,
  setError,
}) => {
  const dispatch = useDispatch();
  const { state, setPage } = useContext(InspectorDashboardContext);

  const [step, setStep] = useState(0);
  const [isUploadDone, setIsUploadDone] = useState(false);
  const [finalModalText, setFinalModalText] = useState('Upload successful!');
  const [finalModalSubtext, setFinalModalSubtext] = useState('You may close this pop-up.');
  const [modalErrorSubtext, setModalErrorSubtext] = useState('');
  const [oldURL, setOldURL] = useState('');
  const [inspectionsNeedingUpload, setInspectionsNeedingUpload] = useState([] as LocalInspection[]);

  const showSpectoraUpload = (useSpectora && selectedInspection?.inspectionType === 'home_inspection');
  const modalSubText = showSpectoraUpload
    ? 'Provide the Spectora URL for the home inspection report.'
    : 'Provide the URL or file for the inspection report.';

  const close = () => {
    setStep(0);
    setUrlForUpload('');
    setFileForUpload(null);
    setOtherSelectedInspections([]);
    setIsUploadDone(false);
    setError('');
    closeModal();
    if (inspections.filter((ins) => ins.status !== 'completed').length === 0) {
      setPage(state.page, { ...state.pageParams, status: 'completed' });
      dispatch(updateJob(job, { status: JobStatus.Completed }));
    }
  };

  const uploadUrlOrFile = async () => {
    await upload();
    setIsUploadDone(true);
  };

  const isValidUrl = (str: string) => {
    // eslint-disable-next-line no-useless-escape
    const valid = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig.test(str);
    return valid && (str.includes('youtu') || str.includes('vimeo') || str.includes('spectora') || str.includes('matterport'));
  };

  const stepFunc = () => {
    if (step === 0) {
      if (urlForUpload.length > 0 && fileForUpload !== null) {
        setModalErrorSubtext('Please select either a file OR a URL to upload a report.');
        return;
      } if (urlForUpload.length > 0 && !isValidUrl(urlForUpload)) {
        setModalErrorSubtext(`Please enter a valid Spectora${!showSpectoraUpload ? ', Vimeo, Youtube or Matterport' : ''} URL, including https://www.`);
        setOldURL(urlForUpload);
        return;
      } if (urlForUpload.length === 0 && fileForUpload === null) {
        setModalErrorSubtext('Please add either a file or URL to upload a report.');
        return;
      }
    }
    setStep(step + 1);
  };

  useEffect(() => {
    if (((inspectionsNeedingUpload.length <= 1 && step === 1) || (step === 2))) {
      uploadUrlOrFile();
    }
  }, [step]);

  useEffect(() => {
    if (isUploadDone) {
      if (error) {
        setFinalModalText('There\'s a problem uploading your report.');
        setFinalModalSubtext('Please contact customer support at hello@inspectify.com.');
      } else {
        setFinalModalText('Upload successful!');
        setFinalModalSubtext('You may close this pop-up.');
      }
    }
  }, [error, isUploadDone]);

  useEffect(() => {
    if (inspections) {
      const filteredInspections = inspections.filter((inspection) => !(inspection.templateReportId
        || ['re_inspection', 'draw_inspection', 'sow_inspection', 'land_development_inspection'].includes(inspection.inspectionType)
        || inspection.status === 'completed'
      ));
      // since inspectors who use spectora for home inspections are not allowed to upload
      // files, filter out the home inspections to prevent applying file uploads to them
      if (fileForUpload !== null) {
        setInspectionsNeedingUpload(filteredInspections.filter(
          (inspection) => !(useSpectora && inspection.inspectionType === 'home_inspection'),
        ));
      } else {
        setInspectionsNeedingUpload(filteredInspections);
      }
    }
  }, [inspections, fileForUpload]);

  useEffect(() => {
    if (urlForUpload === '' || fileForUpload === null) {
      setModalErrorSubtext('');
    } else if (urlForUpload !== '' || fileForUpload !== null) {
      setModalErrorSubtext('');
    } else if (urlForUpload !== oldURL) {
      setModalErrorSubtext('');
      setOldURL('');
    }
  }, [urlForUpload, fileForUpload]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={close}
      ariaHideApp={false}
      className="InspectorDashboardModal"
      overlayClassName="InspectorDashboardOverlay"
    >
      {((inspectionsNeedingUpload.length <= 1 && step === 1) || (step === 2)) ? (
        <>
          {uploadingStep(isUploadDone, finalModalText, finalModalSubtext, error)}
          {isUploadDone && (
            <ButtonContainer>
              <Button onClick={close}>Ok</Button>
            </ButtonContainer>
          )}
        </>
      ) : (
        <>
          {step === 0 && selectUrlOrFileStep(
            modalSubText,
            showSpectoraUpload,
            urlForUpload,
            setUrlForUpload,
            fileForUpload,
            setFileForUpload,
            modalErrorSubtext,
          )}
          {inspectionsNeedingUpload.length > 1 && step === 1 && selectApplicableInspectionsStep(
            inspectionsNeedingUpload,
            selectedInspection,
            otherSelectedInspections,
            setOtherSelectedInspections,
          )}
          <ButtonContainer>
            <Button onClick={close} secondary>Cancel</Button>
            <Button onClick={stepFunc} disabled={modalErrorSubtext !== ''}>{step === 0 ? 'Next' : 'Confirm'}</Button>
          </ButtonContainer>
        </>
      )}
    </Modal>
  );
};

export default ReportUploadModal;
