import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import {
  Job,
  JobStatus,
  PAGINATED_JOBS,
} from 'marketplace-common';
import { InspectorDashboardContext } from '../context';
import {
  clearCompletedJobs,
  clearUpcomingJobs,
  saveJobs,
} from '../../../redux/actions';
import { captureException } from '../../../utils/error';
import { useUrlQuery } from '../../../utils/hooks';
import { JobsTabs } from '../types';
import JobDetailsLoader from './JobDetails';
import Jobs from './Jobs';
import { OnboardingType, UserContractor } from '../../../types';
import ErrorMessage from '../../../components/ErrorMessage';
import GeneralErrorImage from '../../../assets/images/generalErrorImage.svg';
import { useTypedSelector } from '../../../redux/store';
import { selectReports, selectWorks } from '../../../redux/selectors';
import { useInspectorOnboarding, useSurveyorOnboarding } from '../../../hooks/onboarding';
import { BusinessDetailsSection } from '../BusinessDetails/types';

interface JobsParams {
  status?: JobStatus.Completed | JobStatus.Scheduled
  first?: number
  last?: number
  after?: string
  before?: string
  searchText?: string
}

interface Props {
  user: UserContractor,
  setCurrentSection?: (section: BusinessDetailsSection) => void,
}

const JobsLoader: React.FC<Props> = ({ user, setCurrentSection }) => {
  const query = useUrlQuery();
  const qOrderId = parseInt(query.get('orderId'), 10);
  const qJobId = query.get('jobId');
  const qScheduledTime = parseInt(query.get('scheduledTime'), 10);
  const qStatus = query.get('status');
  const qReportUpload = query.get('reportUpload');
  const { state, setPageParams } = useContext(InspectorDashboardContext);
  const {
    jobId,
    orderId,
    scheduledTime,
    status,
    reportUpload,
  } = state.pageParams;

  const works = useTypedSelector(selectWorks);
  const reports = useTypedSelector(selectReports);

  useEffect(() => {
    if (qOrderId && qScheduledTime && qStatus) {
      setPageParams({
        jobId: qJobId,
        orderId: qOrderId,
        scheduledTime: qScheduledTime,
        status: qStatus,
        reportUpload: qReportUpload === 'true',
      });
    }
  }, [qOrderId, qScheduledTime, qStatus, qReportUpload]);

  const dispatch = useDispatch();
  const loader = useRef(null);

  const [tab, setTab] = useState(JobsTabs.Upcoming);
  const [shouldFetchJobs, setShouldFetchJobs] = useState(true);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [error, setError] = useState<string>(null);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const onboardingId: string = user.currentUser.contractor?.currentOnboarding?.id || user.currentUser.contractor?.currentInspectorOnboarding?.id;

  const { data: surveyorOnboardingData } = useSurveyorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching surveyor onboarding',
    contractorType: user.currentUser.contractor.contractorType,
  });

  const { data: inspectorOnboardingData } = useInspectorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching inspector onboarding',
    contractorType: user.currentUser.contractor.contractorType,
  });

  const onboardingData: OnboardingType = surveyorOnboardingData?.surveyorOnboarding || inspectorOnboardingData?.inspectorOnboarding;

  const [upcomingCursor, setUpcomingCursor] = useState<string>('');
  const [completedCursor, setCompletedCursor] = useState<string>('');

  const setCursor = (isCompleted: boolean, cursor: string | null) => {
    if (isCompleted) {
      setCompletedCursor(cursor);
    } else {
      setUpcomingCursor(cursor);
    }
  };

  const [queryJobs, { loading }] = useLazyQuery(PAGINATED_JOBS, {
    variables: {} as JobsParams,
    onCompleted: (data) => {
      const isCompleted = tab === JobsTabs.Completed;
      const isFirstPageForCompleted = isCompleted && data?.jobs?.pageInfo?.hasNextPage === false;
      const isLastPageForCompleted = isCompleted && data?.jobs?.pageInfo?.hasPreviousPage === false;
      const isFirstPageForUpcoming = (
        !isCompleted && data?.jobs?.pageInfo?.hasPreviousPage === false
      );
      const isLastPageForUpcoming = !isCompleted && data?.jobs?.pageInfo?.hasNextPage === false;

      if (!data?.jobs?.nodes?.length) {
        setCursor(isCompleted, null);
        dispatch(clearCompletedJobs());

        if (isFirstPageForCompleted) dispatch(clearCompletedJobs());
        if (isFirstPageForUpcoming) dispatch(clearUpcomingJobs());
        return;
      }

      dispatch(saveJobs(data.jobs.nodes));

      let pageCursor = null;
      if (isCompleted && !isLastPageForCompleted && data?.jobs?.pageInfo?.startCursor) {
        pageCursor = data.jobs.pageInfo.startCursor;
      } else if (!isCompleted && !isLastPageForUpcoming && data?.jobs?.pageInfo?.endCursor) {
        pageCursor = data.jobs.pageInfo.endCursor;
      }

      setCursor(isCompleted, pageCursor);
    },
    onError: (e) => {
      captureException(e);
      setError(e.message);
      setShouldFetchJobs(false);
    },
  });

  const handleLoadJobs = async () => {
    setError(null);
    if (loading) return;

    const isCompleted = tab === JobsTabs.Completed;
    const variables: JobsParams = {};

    if (isCompleted) {
      if (completedCursor === null) return;
      if (completedCursor.length > 0) variables.before = completedCursor;
      variables.status = JobStatus.Completed;
      variables.last = 25;
    } else {
      if (upcomingCursor === null) return;
      if (upcomingCursor.length > 0) variables.after = upcomingCursor;
      variables.status = JobStatus.Scheduled;
      variables.first = 25;
    }

    queryJobs({ variables });
  };

  const findJob = () => {
    if (jobId) return (works.jobs as any)[jobId];
    return Object.values(works.jobs).find(
      (j) => j.orderId === orderId
        && j.scheduledTime === scheduledTime
        && j.status === status,
    );
  };

  const handleContactModal = () => {
    setIsContactModalOpen(!isContactModalOpen);
  };

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) setShouldFetchJobs(true);
  }, [works, tab]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    });
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useEffect(() => {
    const isReduxStateLoaded = reports && works;
    const isNotJobDetails = !jobId && !orderId;
    const canFetchJobs = !loading && tab && shouldFetchJobs;

    if (isReduxStateLoaded && isNotJobDetails && canFetchJobs) handleLoadJobs();
  }, [tab, reports, works, shouldFetchJobs, loading, orderId, jobId]);

  useEffect(() => {
    if (works.jobs && tab) {
      const isCompleted = tab === JobsTabs.Completed;

      const jobsArr = Object.values(works.jobs).filter((job) => (
        isCompleted ? job.status === JobStatus.Completed : job.status !== JobStatus.Completed
      ));

      setJobs(isCompleted
        ? orderBy(jobsArr, ['scheduledTime'], ['desc', 'asc'])
        : orderBy(jobsArr, ['scheduledTime'], ['asc', 'asc']));
    }
  }, [works.jobs, tab]);

  return (
    <>
      {orderId || jobId ? (
        <JobDetailsLoader
          user={user}
          orderId={orderId}
          job={findJob()}
          reportUpload={reportUpload}
        />
      ) : (
        <>
          <Jobs
            tab={tab}
            setTab={setTab}
            jobs={jobs}
            loading={loading}
            contractorType={user.currentUser.contractor.contractorType}
            onboardingData={onboardingData}
            isContactModalOpen={isContactModalOpen}
            handleContactModal={handleContactModal}
            setCurrentSection={setCurrentSection}
          >
            {error && (
              <ErrorMessage
                imageUrl={GeneralErrorImage}
                header="Error loading jobs"
                message="There was an issue loading your jobs. Please try again later."
                updateButton
              />
            )}
          </Jobs>
          <div ref={loader} />
        </>
      )}
    </>
  );
};

export default JobsLoader;
