import React from 'react';
import './styles.scss';

interface InformationSectionProps {
  title: string;
  sectionRef: any,
  description?: string;
  children?: React.ReactNode;
}

const InformationSection: React.FC<InformationSectionProps> = ({
  title,
  sectionRef,
  description,
  children,
}) => (
  <div ref={sectionRef} className="userProfile">
    <div className="userProfile__section">
      <div className="userProfile__section__title">
        {title}
      </div>
      <div className="userProfile___section__description">
        {description}
      </div>
    </div>
    <div className="userProfile__section--large">
      {children}
    </div>
  </div>
);

export default InformationSection;
