import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import InspectifyLayout from '../../components/InspectifyLayout/InspectifyLayout';
import { useUpload } from '../../components/MediaUploader/useUpload';
import {
  RUN_FIX_MISSING_S3_OBJECT_KEYS_ON_REPORT_ATTACHMENTS,
} from '../../graphql/mutations/runFixMissingS3ObjectKeysOnReportAttachments';
import { captureException } from '../../utils/error';
import { useCurrentUser } from '../../utils/user';
import MediaRecovery from './MediaRecovery';

const MediaRecoveryLoader: React.FC = () => {
  const currentUser = useCurrentUser();

  const { upload, uploads, remainingCount } = useUpload('public', false);

  useEffect(() => {
    const notSignedIn = currentUser === false;
    if (notSignedIn) {
      window.location.assign('/sign_in?from=/media_recovery');
    }
  }, [currentUser]);

  const [runFixMissingS3ObjectKeysOnReportAttachments, { error }] = useMutation(
    RUN_FIX_MISSING_S3_OBJECT_KEYS_ON_REPORT_ATTACHMENTS,
  );

  useEffect(() => {
    if (error) captureException(error);
  }, [error]);

  const handleFiles = async (acceptedFiles: File[]) => {
    await upload(acceptedFiles, false);

    runFixMissingS3ObjectKeysOnReportAttachments({ variables: { input: {} } });
  };

  return (
    <>
      <Helmet>
        <title>Media Recovery</title>
      </Helmet>
      <InspectifyLayout showFooter />
      <MediaRecovery handleFiles={handleFiles} uploads={uploads} remainingCount={remainingCount} />
    </>
  );
};

export default MediaRecoveryLoader;
