import React, { useMemo } from 'react';
import { flatten } from 'lodash';
import { WorkItem as WorkItemType } from '../../../../types';
import {
  WorkItemBox,
} from '../../styles';
import WorkItem from './WorkItem';
import { useTypedSelector } from '../../../../redux/store';
import { selectRehabWorkItemAttachments } from '../../../../redux/selectors';

interface Props {
  workItems: { [systemName: string]: WorkItemType[] },
  imageWidth: number,
}

const Content: React.FC<Props> = ({ workItems, imageWidth }) => {
  const workItemAttachments = useTypedSelector(selectRehabWorkItemAttachments);
  const items = useMemo(() => (workItems ? flatten(Object.values(workItems)) : []), [workItems]);

  return (
    <>
      {items.map((item) => (
        <WorkItemBox>
          <WorkItem
            key={item.id}
            item={item}
            imageWidth={imageWidth}
            workItemAttachments={workItemAttachments}
          />
        </WorkItemBox>
      ))}
    </>
  );
};

export default Content;
