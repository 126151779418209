import cn from 'classnames';
import FeedbackOutlinedIcon from 'haven-design-system/build/assets/svgs/action/feedback_outlined.svg?component';
import { INSPECTIFY_PHONE_NUMBER, ReportApplianceInformation } from 'marketplace-common';
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { Container } from 'react-bootstrap';
import Button from '../../../components/Button';
import PageErrorIndicator from '../../../components/PageError';
import { PermitsBanner } from '../../../components/Permits/Permits/PermitsBanner';
import Spinner from '../../../components/Spinner';
import { useOrderByToken } from '../../../hooks/order';
import { SplitFlags } from '../../../redux/actions';
import { selectWorksPropertyPermits } from '../../../redux/selectors';
import { useTypedSelector } from '../../../redux/store';
import { ReportDeliveryFilter, ReportFeedbackStep } from '../../../types';
import { formatReportDate } from '../../../utils/date';
import { useReportsPageContext } from '../../../utils/reportsPageContext';
import { useTreatment } from '../../../utils/splitio';
import { includesDrawInspection, includesSowInspection } from '../../../utils/tables';
import Banner from '../components/Banner';
import DrawInspectionBudgetSummary from '../components/DrawInspectionBudgetSummary';
import FeedbackModal from '../components/FeedbackModal/FeedbackModal';
import ClaimForm from '../components/InspectionWarranty/ClaimForm';
import OwnershipForm from '../components/InspectionWarranty/OwnershipForm';
import IssueSummaryTable from '../components/IssueSummaryTable';
import Paywall from '../components/Paywall';
import ReportsPageLayout from '../components/ReportsPageLayout';
import SelectReport from '../components/SelectReport';
import { SowInspectionSummary } from '../components/SowInspectionSummary';
import StateCompliance from '../components/StateCompliance';
import SystemDetails from '../components/SystemDetails';
import { ReportContext } from '../context';
import styles from './styles.module.scss';

interface Props {
  loading: boolean,
  imageWidth: number,
  selectReport: (id: string) => void,
  createRehabProject: () => void,
  inspectifyAdmin: boolean
  isMobile: boolean,
  reportFeedbackModalIsOpen: boolean,
  setReportFeedbackModalIsOpen: (isOpen: boolean) => void,
  reportFeedbackStep: ReportFeedbackStep,
  setReportFeedbackStep: (step: ReportFeedbackStep) => void,
  handleOpenReportMediaUploader?: (itemId: string, itemType: string, reportSystemGroupId?: string | null) => void;
  handleSubmitFeedback: (step: ReportFeedbackStep, feedback?: string) => void;
}

const ReportsPage: React.FC<Props> = ({
  loading,
  imageWidth,
  selectReport,
  createRehabProject,
  inspectifyAdmin,
  isMobile,
  reportFeedbackModalIsOpen,
  setReportFeedbackModalIsOpen,
  reportFeedbackStep,
  setReportFeedbackStep,
  handleOpenReportMediaUploader,
  handleSubmitFeedback,
}) => {
  const { dispatch } = useReportsPageContext();
  const summaryRef = useRef();
  // const currentUser = useCurrentUser();
  const featureReportFeedback = useTreatment(SplitFlags.FeatureReportFeedback);
  const featureDrawManagement = useTreatment(SplitFlags.FeatureDrawManagement);

  const { state, setSelectedReportSystemGroupIds } = useContext(ReportContext);
  const { isAppliancesOnly, noDeficienciesRequired } = state;

  const canEditDrawManagement = useMemo(() => {
    const selectedTeamId = localStorage.getItem('inspectifySelectedTeamId');
    return featureDrawManagement === 'on' && state.orderTeamId === selectedTeamId;
  }, [featureDrawManagement, state.orderTeamId]);

  const permits = useTypedSelector(
    (reduxState) => selectWorksPropertyPermits(reduxState, state.orderId as number),
  );

  const showPermitData = useMemo(() => (
    state.selectedReport?.report?.includePermitData && Object.values(permits).length > 0
  ), [permits, state.selectedReport?.report?.includePermitData]);

  const {
    loading: orderLoading,
    isDrawSummaryHidden,
    displaySowSummary,
    displayWithTotal,
  } = useOrderByToken({ token: state.orderToken });

  const containsSowInspections = includesSowInspection(state.selectedReport);
  const containsDrawInspections = includesDrawInspection(state.selectedReport);

  const [
    selectedReportSystemGroupNames,
    setSelectedReportSystemGroupNames,
  ] = useState<string[]>([]);
  const [filter, setFilter] = useState(ReportDeliveryFilter.FullReport);
  const [isOwnershipFormOpen, setIsOwnershipFormOpen] = useState(false);
  const [isClaimFormOpen, setIsClaimFormOpen] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState<string[]>([]);

  const clientInformationData = useMemo(() => [
    { label: 'Client name', value: state.clientStakeholder?.name },
    { label: 'Phone number', value: state.clientStakeholder?.phone },
    { label: 'Email', value: state.clientStakeholder?.email },
    { label: 'Reference id', value: state.order?.teamExternalId },
    { label: 'Community', value: state.order?.communityName },
    { label: 'Floor Plan', value: state.order?.floorPlanName },
  ].filter(
    (clientData) => clientData.label === 'Client name' || (
      clientData.value
      && clientData.value !== INSPECTIFY_PHONE_NUMBER
      && !(clientData.value.includes('hello+') && clientData.value.includes('@inspectify.com'))
    ),
  ), [state.clientStakeholder]);

  const showClaimInspectionWarrantyBanner = useMemo(() => (
    state.inspectionWarranty
    && state.inspectionWarranty.status !== 'ownership_claimed'
    && !state.isInspectionWarrantyTimeToProvideProofOfOwnershipExpired
    && !state.isInspectionWarrantyExpired
  ), [state.inspectionWarranty, state.isInspectionWarrantyExpired]);

  const showInspectionWarrantyExpiresSoonBanner = useMemo(() => (
    state.isInspectionWarrantyExpiringSoon && !showClaimInspectionWarrantyBanner
  ), [state.isInspectionWarrantyExpiringSoon, showClaimInspectionWarrantyBanner]);

  const showInspectionWarrantyExpired = useMemo(() => (
    state.isInspectionWarrantyExpired
    && !state.isInspectionWarrantyExpiredAndLastDayToClaimIsExpired
  ), [
    state.isInspectionWarrantyExpired,
    state.isInspectionWarrantyExpiredAndLastDayToClaimIsExpired,
  ]);

  const showFileClaimBanner = useMemo(() => (
    state.inspectionWarranty
    && state.inspectionWarranty.status === 'ownership_claimed'
    && !showInspectionWarrantyExpiresSoonBanner
    && !state.isInspectionWarrantyExpired
  ), [
    state.inspectionWarranty,
    showInspectionWarrantyExpiresSoonBanner,
    state.isInspectionWarrantyExpired,
  ]);

  const allowCopyLongLiveUrl = useMemo(() => (
    state.orderTeamId === '3124' // Only Homeward
  ), [state.orderTeamId]);

  const selectedDate = state.selectedReport?.report
    ? formatReportDate(state.selectedReport?.report)
    : null;

  const openClaimForm = () => {
    setEquipmentTypes((state.warrantyCoveredAppliances as ReportApplianceInformation[]).map(
      ({ productName }) => productName,
    ));
    setIsClaimFormOpen(true);
  };

  useEffect(() => {
    if (summaryRef.current) dispatch({ type: 'add_ref', payload: summaryRef });
    return () => dispatch({ type: 'clear_refs' });
  }, [summaryRef.current]);

  useEffect(() => {
    dispatch(setSelectedReportSystemGroupIds(state.selectedReport, selectedReportSystemGroupNames));
  }, [selectedReportSystemGroupNames]);

  useEffect(() => {
    const hasTemplateReportIds = state.selectedReport?.report?.templateReportIds?.length;
    setFilter(!state.isReInspection || hasTemplateReportIds
      ? ReportDeliveryFilter.FullReport
      : ReportDeliveryFilter.IssuesOnly);
  }, [state.isReInspection, state.selectedReportId]);

  useEffect(() => {
    if (isAppliancesOnly) {
      setFilter(ReportDeliveryFilter.AppliancesOnly);
    }
  }, [isAppliancesOnly]);

  if (loading || orderLoading) return <Spinner style={{ width: '100vw', height: '100vh' }} />;

  return (
    <ReportsPageLayout
      filter={filter}
      groupNames={selectedReportSystemGroupNames}
      changeGroupNames={setSelectedReportSystemGroupNames}
      changeFilter={setFilter}
      teamId={state.orderTeamId as number}
      createRehabProject={createRehabProject}
    >
      {(featureReportFeedback === 'on') && (
        <div>
          <Button
            onClick={() => handleSubmitFeedback(ReportFeedbackStep.INITIATION)}
            className={isMobile ? styles.feedbackButtonMobile : styles.giveUsFeedbackButton}
            style={{
              visibility: reportFeedbackModalIsOpen ? 'hidden' : 'visible',
            }}
            disabled={reportFeedbackModalIsOpen}
          >
            <FeedbackOutlinedIcon className={styles.feedbackOutlinedIcon} />
            {!isMobile
              && (
                <div>
                  Give us feedback
                </div>
              )}
          </Button>
          <FeedbackModal
            modalIsOpen={reportFeedbackModalIsOpen}
            setModalIsOpen={setReportFeedbackModalIsOpen}
            reportFeedbackStep={reportFeedbackStep}
            setReportFeedbackStep={setReportFeedbackStep}
            handleSubmitFeedback={handleSubmitFeedback}
          />
        </div>
      )}

      <Container fluid className={styles.body}>
        {state.isDenverPdf && (
          <Banner
            body={(state.selectedReport?.report?.publishedAt
              ? 'Download the inspection report PDF to access the completed Denver Residential Rental Inspection Checklist'
              : 'The Denver Residential Rental Inspection Checklist PDF will be generated and available for download once the inspection has been completed.'
            )}
          />
        )}

        {showClaimInspectionWarrantyBanner && (
          <Banner
            header="Need to file a claim? Verify your home ownership status"
            body={`You have until ${state.inspectionWarrantyLastDayToVerifyOwnershipDate} to verify your status in order to qualify for future claims.`}
            action="Verify status"
            onAction={() => {
              setIsOwnershipFormOpen(true);
            }}
          />
        )}

        {state.isInspectionWarrantyTimeToProvideProofOfOwnershipExpired && (
          <Banner
            type="error"
            header="The period to provide proof of ownership has ended."
            body={`Your last day to verify your home ownership status was ${state.inspectionWarrantyLastDayToVerifyOwnershipDate}.`}
          />
        )}

        {showFileClaimBanner && (
          <Banner
            header="Your Inspectify Inspection Warranty is now active"
            body={`Policy ends on ${state.inspectionWarrantyExpirationDate}. Any claims reported more than 30 days after the expiration of the warranty period will not be accepted. Your last day to file a claim is ${state.inspectionWarrantyLastDayToFileClaimDate}`}
            action="File a claim"
            onAction={openClaimForm}
            link="View policy"
            linkHref="https://public-inspectify.s3.amazonaws.com/documents/Inspectify+-+Home+Purchase+Inspection+Warranty.pdf"
          />
        )}

        {showInspectionWarrantyExpiresSoonBanner && (
          <Banner
            type="warning"
            header="Your Inspectify Inspection Warranty is about to expire"
            body={`Policy ends on ${state.inspectionWarrantyExpirationDate}. Any claims reported more than 30 days after the expiration of the warranty period will not be accepted. Your last day to file a claim is ${state.inspectionWarrantyLastDayToFileClaimDate}.`}
            action="File a claim"
            onAction={openClaimForm}
            link="View policy"
            linkHref="https://public-inspectify.s3.amazonaws.com/documents/Inspectify+-+Home+Purchase+Inspection+Warranty.pdf"
          />
        )}

        {showInspectionWarrantyExpired && (
          <Banner
            type="error"
            header="Your Inspectify Inspection Warranty has expired"
            body={`Policy ended on ${state.inspectionWarrantyExpirationDate}. Any claims reported more than 30 days after the expiration of the warranty period will not be accepted. Your last day to file a claim is ${state.inspectionWarrantyLastDayToFileClaimDate}.`}
            action="File a claim"
            onAction={openClaimForm}
            link="View policy"
            linkHref="https://public-inspectify.s3.amazonaws.com/documents/Inspectify+-+Home+Purchase+Inspection+Warranty.pdf"
          />
        )}

        {state.isInspectionWarrantyExpiredAndLastDayToClaimIsExpired && (
          <Banner
            type="error"
            header="Your Inspectify Inspection Warranty has expired"
            body={`Policy ended on ${state.inspectionWarrantyExpirationDate}. Your last day to file a claim was ${state.inspectionWarrantyLastDayToFileClaimDate}.`}
            link="View policy"
            linkHref="https://public-inspectify.s3.amazonaws.com/documents/Inspectify+-+Home+Purchase+Inspection+Warranty.pdf"
          />
        )}

        <div id="Summary" ref={summaryRef}>
          <div className={styles.headerRow}>
            <h1 className={styles.summaryText}>Summary</h1>

            {selectedDate && state.reportRecords.length && (
              <SelectReport
                title="Currently viewing"
                selectedDate={selectedDate}
                selectReport={selectReport}
              />
            )}
          </div>
        </div>

        {showPermitData && (
          <div style={{ marginBottom: 24 }}>
            <PermitsBanner permits={Object.values(permits)} />
          </div>
        )}

        {(containsDrawInspections && !isDrawSummaryHidden) ? (
          <DrawInspectionBudgetSummary
            displayWithTotal={displayWithTotal}
            editable={canEditDrawManagement}
            loading={loading}
          />
        ) : null}

        {(containsSowInspections && displaySowSummary) ? (
          <SowInspectionSummary report={state.selectedReport} />
        ) : null}

        {(filter !== ReportDeliveryFilter.MediaOnly
          && filter !== ReportDeliveryFilter.AppliancesOnly) && (
          <IssueSummaryTable
            report={state.selectedReport}
            systems={state.sortedReportSystems}
            filter={filter}
            noDeficienciesRequired={noDeficienciesRequired}
          />
        )}

        <div className={styles.sectionBody} style={{ marginBottom: 32 }}>
          <h3 className={styles.sectionHeader} style={{ paddingLeft: 18 }}>
            Client info
          </h3>
          <div className={styles.informationsContainer}>
            {clientInformationData.map((info) => (
              <div key={info.label} className={styles.informationContainer}>
                <p className={styles.labelHeader}>{info.label}</p>
                <p className={cn(styles.labelValue)}>
                  {info.value}
                </p>
              </div>
            ))}
          </div>
        </div>

        {Object.keys(state?.groupedReportSystemsByName || {}).map((group, index) => (
          <SystemDetails
            report={state.selectedReport}
            groupedReportSystemsByName={state.groupedReportSystemsByName}
            key={`${group}-${index + 1}`}
            systemName={group}
            imageWidth={imageWidth}
            index={index}
            filter={filter}
            isAppliancesOnly={isAppliancesOnly}
            isPrint={false}
            allowCopyLongLiveUrl={allowCopyLongLiveUrl}
            inspectifyAdmin={inspectifyAdmin}
            inspectionWarranty={state.inspectionWarranty}
            isInspectionWarrantyExpired={state.isInspectionWarrantyExpired}
            noDeficienciesRequired={state.noDeficienciesRequired}
            handleOpenReportMediaUploader={handleOpenReportMediaUploader}
          />
        ))}

        {state.selectedReport?.report?.complianceText && (
          <StateCompliance
            stateComplianceText={state.selectedReport.report.complianceText}
          />
        )}
      </Container>

      {state.isPaywalled && (
        <>
          <div className={styles.paywallSpacer} />
          <Paywall order={state.order} contractor={state.contractor} />
        </>
      )}

      <OwnershipForm
        warrantyId={state.inspectionWarranty?.id || ''}
        isOpen={isOwnershipFormOpen}
        close={() => {
          setIsOwnershipFormOpen(false);
        }}
      />

      <ClaimForm
        warranty={state?.inspectionWarranty || null}
        isOpen={isClaimFormOpen}
        equipmentTypes={equipmentTypes}
        close={() => {
          setIsClaimFormOpen(false);
        }}
      />

      {state.reportQueryError && <PageErrorIndicator errorType={state.reportQueryError} />}
    </ReportsPageLayout>
  );
};

export default ReportsPage;
