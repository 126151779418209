export const convertUrlToFilename = (str : string) => {
  if (!str || str.length <= 1) return '';
  return (str.split('/').pop().replace(/%20/g, ' '));
};

export const toCapitalizedSentence = (array: string[]): string => {
  if (!array || array.length === 0) return '';
  // Remove the underscores and capitalize every first letter
  const capitalizeWords = (str: string) => str?.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());

  const capitalizedArray = array.map(capitalizeWords);

  // put "Home Inspection" first
  const homeInspectionIndex = capitalizedArray.indexOf('Home Inspection');
  if (homeInspectionIndex !== -1) {
    capitalizedArray.splice(homeInspectionIndex, 1);
    capitalizedArray.unshift('Home Inspection');
  }
  // Join every word by a comma then replace the last comma with the word "and"
  return capitalizedArray.join(', ').replace(/,([^,]*)$/, ' and$1');
};

/*
  Accepts any string input and removes any non-valid phone digits
  Returns only the first 10 integers as a string
*/
export const unformatPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, '').substring(0, 10);

/*
  Accepts any string input and removes any non-valid phone digits
  Returns the phone number input formatted as (XXX) XXX-XXXX
*/
export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return phoneNumber;
  const digitsOnly = unformatPhoneNumber(phoneNumber);

  if (digitsOnly.length < 4) return digitsOnly;
  if (digitsOnly.length < 7) {
    return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
  }

  return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
};

/*
  Accepts any string input
  Returns true if the email follows the pattern outlined here:
  https://www.scaler.com/topics/email-validation-in-javascript/
*/
export const validateEmail = (email: string) : boolean => {
  const regex = /^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
};

/*
  Accepts any string input
  Returns true if the zip code follows either XXXXX or XXXXX-XXXX format
*/
export const validateZipCode = (zipCode: string) : boolean => {
  const regex = /^\d{5}(-\d{4})?$/;
  return regex.test(zipCode);
};
