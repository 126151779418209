import React, { useContext, useMemo, useState } from 'react';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import ReactSelect from '../../../../components/ReactSelect';
import { RehabWorkItemsContainer } from './styles';
import { useTypedSelector } from '../../../../redux/store';
import { groupAndSortWorkItemsBySystem } from '../../../../utils/rehab';
import { RehabToolContext } from '../../context';
import RehabSystemSections from './RehabSystemSections';

const RehabWorkItems: React.FC = () => {
  const rehab = useTypedSelector((state) => state.rehab);
  const { state: { projectId } } = useContext(RehabToolContext);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const filteredWorkItems = useMemo(() => {
    if (!projectId) return [];

    return Object.values(rehab.workItems)
      .filter((workItem) => {
        const allTags = [
          ...(workItem.tags || []),
          ...(workItem.workItemContributors?.flatMap((contributor) => (contributor.name ? [contributor.name] : [])) || []),
        ];

        const tagsMatch = selectedTags.length === 0
          || intersection(allTags || [], selectedTags).length > 0;

        return workItem.projectId === projectId && tagsMatch;
      });
  }, [rehab.workItems, projectId, selectedTags]);

  const tags = useMemo(() => {
    const workItemTags = Object.values(rehab?.workItems || {}).reduce((acc: string[], item) => {
      const contributorNames = item.workItemContributors
        ? item.workItemContributors.flatMap((contributor) => (contributor.name ? [contributor.name] : []))
        : [];
      return [...acc, ...(item.tags || []), ...contributorNames];
    }, []);
    return uniq(workItemTags);
  }, [rehab.workItems]);

  const workItemsBySystem = useMemo(() => groupAndSortWorkItemsBySystem(filteredWorkItems || []), [filteredWorkItems]);

  const filters = useMemo(
    () => [
      {
        label: 'Tags',
        options: tags.map((value) => ({ label: value, value })),
      },
    ],
    [tags],
  );

  return (
    <RehabWorkItemsContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>{Object.keys(filteredWorkItems).length} Work Items</h2>
        <ReactSelect
          placeholder="Filters"
          options={filters}
          onChange={(options) => {
            setSelectedTags(
              options ? options.map(({ value }: any) => value) : [],
            );
          }}
          isMulti
          width="unset"
        />
      </div>
      <RehabSystemSections workItemsBySystem={workItemsBySystem} />
    </RehabWorkItemsContainer>
  );
};

export default RehabWorkItems;
