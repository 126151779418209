import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { handleSignInError } from 'marketplace-common';
import OnboardingSetPasswordPage from './OnboardingSetPasswordPage';
import InspectifyLayout from '../../../components/InspectifyLayout';
import { captureException } from '../../../utils/error';
import { handleOnboardingPageNavigation } from '../../../utils/onboarding';
import { signIn, signOut } from '../../../utils/amplify_auth';
import { ContractorTypeEnum, OnboardingCompletedStep } from '../../../types';
import {
  useInspectorOnboarding, useSurveyorOnboarding, useUpdateInspectorOnboarding, useUpdateSurveyorOnboarding,
} from '../../../hooks/onboarding';

const OnboardingSetPasswordPageLoader: React.FC = () => {
  const history = useHistory();
  const { onboardingContractorType } = useParams<{ onboardingContractorType: ContractorTypeEnum }>();
  const { onboardingId } = useParams<{ onboardingId: string }>();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const { data: surveyorOnboardingData } = useSurveyorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching surveyor onboarding',
    contractorType: onboardingContractorType,
  });

  const { data: inspectorOnboardingData } = useInspectorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching inspector onboarding',
    contractorType: onboardingContractorType,
  });

  const { updateSurveyorOnboarding, updateSurveyorOnboardingData } = useUpdateSurveyorOnboarding({
    errorMessage: 'Graphql failed to update surveyor onboarding',
  });

  const { updateInspectorOnboarding, updateInspectorOnboardingData } = useUpdateInspectorOnboarding({
    errorMessage: 'Graphql failed to update inspector onboarding',
  });

  const completedStep = updateSurveyorOnboardingData?.updateSurveyorOnboarding?.surveyorOnboarding?.completedStep
    || updateInspectorOnboardingData?.updateInspectorOnboarding?.inspectorOnboarding?.completedStep
    || surveyorOnboardingData?.surveyorOnboarding?.completedStep
    || inspectorOnboardingData?.inspectorOnboarding?.completedStep;

  const canSetPassword = updateSurveyorOnboardingData?.updateSurveyorOnboarding?.surveyorOnboarding?.canSetPassword
    || updateInspectorOnboardingData?.updateInspectorOnboarding?.inspectorOnboarding?.canSetPassword
    || surveyorOnboardingData?.surveyorOnboarding?.canSetPassword
    || inspectorOnboardingData?.inspectorOnboarding?.canSetPassword;

  const email = surveyorOnboardingData?.surveyorOnboarding?.contractor.businessEmail || inspectorOnboardingData?.inspectorOnboarding?.contractor.businessEmail;

  // Prevent the user from returning to the set password page once set
  useEffect(() => {
    if (completedStep) {
      handleOnboardingPageNavigation(completedStep, canSetPassword, onboardingContractorType, onboardingId, history);
    }
  }, [completedStep]);

  const checkPasswordsMatch = () => {
    if (passwordConfirmation.length > 5) {
      if (password !== passwordConfirmation) {
        setShowPasswordError(true);
      } else {
        setShowPasswordError(false);
      }
    }
  };

  const handleSignIn = async () => {
    try {
      if (localStorage.getItem('inspectifySelectedUser')) {
        await signOut();
      }
      await signIn(
        email,
        password,
        () => {
          window.location.assign('/dashboard');
        },
      );
      setIsLoading(false);
    } catch (error) {
      handleSignInError(error, captureException, email);
    }
  };

  const handleSetPassword = async () => {
    setIsLoading(true);
    try {
      if (onboardingContractorType === ContractorTypeEnum.Surveyor) {
        await updateSurveyorOnboarding({
          variables: {
            input: {
              id: onboardingId,
              passwordSet: {
                password,
                passwordConfirmation,
              },
              completedStep: OnboardingCompletedStep.PasswordSet,
            },
          },
          context: { headers: { onboarding_id: onboardingId } },
        });
      }
      if (onboardingContractorType === ContractorTypeEnum.Inspector) {
        await updateInspectorOnboarding({
          variables: {
            input: {
              id: onboardingId,
              passwordSet: {
                password,
                passwordConfirmation,
              },
              completedStep: OnboardingCompletedStep.PasswordSet,
            },
          },
          context: { headers: { onboarding_id: onboardingId } },
        });
      }
    } catch (error) {
      captureException(error, { source: 'Setting password' });
    }
    handleSignIn();
  };

  return (
    <>
      <Helmet>
        <title>Welcome to Inspectify!</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showSignIn={false} hideHeaderOnMobile />
      <OnboardingSetPasswordPage
        password={password}
        setPassword={setPassword}
        showPasswordError={showPasswordError}
        passwordConfirmation={passwordConfirmation}
        setPasswordConfirmation={setPasswordConfirmation}
        checkPasswordsMatch={checkPasswordsMatch}
        setPasswordAndSignIn={handleSetPassword}
        loading={loading}
      />
    </>
  );
};

export default OnboardingSetPasswordPageLoader;
