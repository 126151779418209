import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { GENERATE_TIPALTI_IFRAME_URL } from '../../../graphql/mutations';
import {
  GenerateTipaltiIframeUrlDataType,
} from '../../../types';
import Spinner from '../../../components/Spinner';
import { captureException } from '../../../utils/error';
import { TipaltiIframe } from './styles';
import { InspectorDashboardContext } from '../context';
import Button from '../../../components/Button';
import { DashboardPageTab } from '../types';
import './styles.scss';

interface Props {
  iframeType: 'setup_process' | 'invoice_history' | 'payment_history',
}

const TipaltiIframeLoader: React.FC<Props> = ({ iframeType }) => {
  const { state, setPage } = useContext(InspectorDashboardContext);

  const [
    generateTipaltiIframeUrl, { loading, error, data: tipaltiIframeData },
  ] = useMutation<GenerateTipaltiIframeUrlDataType>(
    GENERATE_TIPALTI_IFRAME_URL,
  );

  useEffect(() => {
    generateTipaltiIframeUrl({
      variables: {
        input: { contractorId: state.contractor.id, iframeType },
      },
    });
  }, [iframeType]);

  useEffect(() => {
    if (error) {
      captureException(error);
    }
  }, [error]);

  return (
    <div className="tipaltiIframeContainer">
      <div className="tipaltiIframeContainer__header">
        <div className="userFormContainer__header__text">
          {iframeType === 'setup_process' ? 'Payment setup' : 'Payment history'}
        </div>
        <div className="buttonContainer">
          <Button onClick={() => setPage(DashboardPageTab.Jobs)}>Back to dashboard</Button>
        </div>
      </div>
      {loading && (<Spinner />)}
      {error && (
        <div>
          There was an issue accessing the payment system.
          Please try again or contact customer service for support.
        </div>
      )}
      {tipaltiIframeData?.generateTipaltiIframeUrl?.iframeUrl && (
        <TipaltiIframe title="Tipalti" src={tipaltiIframeData.generateTipaltiIframeUrl.iframeUrl} />
      )}
    </div>
  );
};

export default TipaltiIframeLoader;
