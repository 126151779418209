import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ReportProvider } from './context';
import Loader from './loader';
import { useUrlQuery } from '../../utils/hooks';

interface Props extends RouteComponentProps<{ orderToken: string }> {}

const ReportsPage: React.FC<Props> = ({ match }) => {
  const { orderToken } = match.params;
  const query = useUrlQuery();

  const reportId = query.get('report_id');
  const redirectToRehab = query.get('redirect') === 'rehab';
  const printMode = query.get('print') === 'true';
  const rotatedAttachmentDegrees = printMode ? [] : [90, 180, 270];
  const hideDeficiencies = query.get('no_deficiencies') === 'true';

  // unit query param is deprecated, use systemGroups instead
  const unit = query.get('unit');
  const systemGroupNames = unit ? [unit] : query.get('systemGroups')?.split(',');

  const isAppliancesOnly = query.get('appliances_only') === 'true';

  return (
    <ReportProvider>
      <Loader
        reportId={reportId}
        orderToken={orderToken}
        printMode={printMode}
        redirectToRehab={redirectToRehab}
        rotatedAttachmentDegrees={rotatedAttachmentDegrees}
        hideDeficiencies={hideDeficiencies}
        systemGroupNames={systemGroupNames}
        isAppliancesOnly={isAppliancesOnly}
      />
    </ReportProvider>
  );
};

export default ReportsPage;
