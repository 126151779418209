import React from 'react';
import ChevronRight from 'haven-design-system/build/assets/svgs/controls/chevron_right.svg?component';
import './styles.scss';
import CheckboxOff from '../../../../assets/icons/checkbox/off.svg';
import CheckboxOn from '../../../../assets/icons/checkbox/on_green.svg';
import Button from '../../../../components/Button';
import { TrackerItemHeader, TrackerItemProps } from './types';

const TrackerItem: React.FC<TrackerItemProps> = ({
  header,
  instructions,
  isChecked,
  isOptional,
  disabled,
  onClick,
}) => (
  <>
    {isChecked ? (
      <div className="trackerItemContainer--checked">
        <img src={CheckboxOn} alt="CheckboxOn" />
        <div className="trackerItemTextHeader--checked">
          {header}
        </div>
      </div>
    ) : (
      <button
        type="button"
        className={disabled ? 'trackerItemContainer--disabled' : 'trackerItemContainer'}
        disabled={disabled}
        onClick={onClick}
      >
        <img src={CheckboxOff} alt="CheckboxOff" />
        <div className="trackerItemTextContainer">
          <div className={disabled ? 'trackerItemTextHeader--disabled' : 'trackerItemTextHeader'}>
            {header}&nbsp;
            {isOptional && (
              <span className="trackerItemTextHeader--optional">
                {TrackerItemHeader.Optional}
              </span>
            )}
          </div>
          <div className={disabled ? 'trackerItemTextInstructions--disabled' : 'trackerItemTextInstructions'}>
            {instructions}
          </div>
        </div>
        <Button
          onClick={onClick}
          className={disabled ? 'chevronButton--disabled' : 'chevronButton'}
        >
          <ChevronRight />
        </Button>
      </button>
    )}
  </>
);

export default TrackerItem;
