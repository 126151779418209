import React from 'react';
import CloseIcon from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import './styles.scss';
import Button from '../Button/Button';
import { PopupInstructionsProps } from './types';

// When using this component, add `position: relative` to the parent element

const PopupInstructions: React.FC<PopupInstructionsProps> = ({
  header, body, xPos, yPos, onClose, showPopupAboveElement,
}) => (
  <div
    className="popupContainer"
    style={showPopupAboveElement ? {
      position: 'absolute',
      right: xPos,
      bottom: yPos,
    } : {
      position: 'absolute',
      right: xPos,
      top: yPos,
    }}
  >
    <div className="popupHeaderContainer">
      <div className="popupHeader">
        {header}
      </div>
      <Button
        className="popupCloseButton"
        onClick={onClose}
      >
        <CloseIcon />
      </Button>
    </div>
    <div className="popupBody">
      {body}
    </div>
  </div>
);

export default PopupInstructions;
