import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string,
  secondary?: boolean,
  type?: 'button' | 'submit' | 'reset',
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({
    className, secondary, type, children, ...props
  }, ref) => (
    <button
      className={className || cn([secondary ? styles.secondary : styles.primary])}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      {...props}
    >
      {children}
    </button>
  ),
);

export default Button;
