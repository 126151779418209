import React, { useContext, useEffect } from 'react';
import { InspectorDashboardContext } from './context';
import { DashboardPageTab, UrlTargets } from './types';
import {
  InspectorDashboardContent,
  InspectorDashboardGlobalStyles,
} from './styles';
import './styles.scss';
import styles from '../../components/Button/styles.module.scss';
import BadNavigationImage from '../../assets/images/badNavigationImage.svg';
import JobsLoader from './Jobs';
import MobileMenu from './MobileMenu';
import Calendar from './Calendar';
import Spinner from '../../components/Spinner';
import TipaltiIframe from './TipaltiIframe';
import MsaReview from './MsaReview';
import { UserContractor } from '../../types';
import BusinessDetailsLoader from './BusinessDetails';
import Menu from './Menu';
import UserManagement from './UserManagement';
import { canAccessMSAPage } from '../../utils/user';
import ContractorBusinessDetailsLoader from './BusinessDetails/ContractorBusinessDetailsLoader';
import { useUrlQuery } from '../../utils/hooks';

interface Props {
  user: UserContractor,
  isLoading: boolean,
  error: string,
  refetchContractor: () => void
  basicInformationRef: React.RefObject<HTMLDivElement>,
  certificationsRef: React.RefObject<HTMLDivElement>,
  serviceableAreaRef: React.RefObject<HTMLDivElement>,
  accessAndSoftwareRef: React.RefObject<HTMLDivElement>,
  documentsRef: React.RefObject<HTMLDivElement>,
}

const InspectorDashboardPage: React.FC<Props> = ({
  user,
  isLoading,
  error,
  refetchContractor,
  basicInformationRef,
  certificationsRef,
  serviceableAreaRef,
  accessAndSoftwareRef,
  documentsRef,
}) => {
  const query = useUrlQuery();
  const target = query.get('target');
  const { state, setPage } = useContext(InspectorDashboardContext);

  useEffect(() => {
    if (target && user?.currentUser?.contractor) {
      switch (target) {
        case UrlTargets.Misa:
          if (canAccessMSAPage(user)) {
            setPage(DashboardPageTab.MasterInspectionAndServiceAgreement);
          } else {
            setPage(DashboardPageTab.Jobs);
          }
          break;
        case UrlTargets.BusinessDetails:
          setPage(DashboardPageTab.BusinessDetails);
          break;
        case UrlTargets.PaymentSetup:
          if (user.currentUser.grantedPermissions.includes('view_payment_setup_page')) {
            setPage(DashboardPageTab.TipaltiSetupProcess);
          } else {
            setPage(DashboardPageTab.Jobs);
          }
          break;
        case UrlTargets.PaymentHistory:
          if (user.currentUser.grantedPermissions.includes('view_payment_setup_page')) {
            setPage(DashboardPageTab.TipaltiPaymentHistory);
          } else {
            setPage(DashboardPageTab.Jobs);
          }
          break;
        case UrlTargets.ManageUsers:
          if (user.currentUser?.canManageUsers) {
            setPage(DashboardPageTab.UserManagement);
          } else {
            setPage(DashboardPageTab.Jobs);
          }
          break;
        default:
          setPage(DashboardPageTab.Jobs);
          break;
      }
    }
  }, [target, user?.currentUser?.contractor]);

  useEffect(() => {
    if (canAccessMSAPage(user)
      && state.page === DashboardPageTab.Jobs
      && !user.currentUser.contractor?.currentOnboarding
      && !user.currentUser.contractor?.currentInspectorOnboarding) {
      setPage(DashboardPageTab.MasterInspectionAndServiceAgreement);
    }
  }, [user?.currentUser?.contractor]);

  const renderPageContent = (content: DashboardPageTab) => {
    switch (content) {
      case DashboardPageTab.Jobs:
        return <JobsLoader user={user} />;
      case DashboardPageTab.Calendar:
        return <Calendar />;
      case DashboardPageTab.BusinessDetails:
        if (user.currentUser.contractor.contractorType === 'inspector') {
          return <ContractorBusinessDetailsLoader />;
        }
        return (
          <BusinessDetailsLoader
            contractor={user.currentUser.contractor}
            basicInformationRef={basicInformationRef}
            certificationsRef={certificationsRef}
            serviceableAreaRef={serviceableAreaRef}
            accessAndSoftwareRef={accessAndSoftwareRef}
            documentsRef={documentsRef}
          />
        );
      case DashboardPageTab.UserManagement:
        return <UserManagement currentUser={user.currentUser} />;
      case DashboardPageTab.TipaltiSetupProcess:
        return <TipaltiIframe iframeType="setup_process" />;
      case DashboardPageTab.TipaltiPaymentHistory:
        return <TipaltiIframe iframeType="payment_history" />;
      case DashboardPageTab.MasterInspectionAndServiceAgreement:
        return <MsaReview refetchContractor={refetchContractor} />;
      default:
        return <JobsLoader user={user} />;
    }
  };

  if (isLoading && error.length === 0) return <Spinner style={{ width: '100vw', height: '100vh' }} />;

  if (error.length) {
    return (
      <div className="errorMessageContainer">
        <img src={BadNavigationImage} alt="" className="imageContainer" />
        <div className="errorMessageHeader">Are you in the wrong place?</div>
        <div className="errorMessageContent">{error}</div>
        <a href="https://app2.inspectify.com" className={styles.primary}>
          Login as a client
        </a>
      </div>
    );
  }

  return (
    <div>
      <InspectorDashboardGlobalStyles />
      <Menu
        user={user}
        basicInformationRef={basicInformationRef}
        certificationsRef={certificationsRef}
        serviceableAreaRef={serviceableAreaRef}
        accessAndSoftwareRef={accessAndSoftwareRef}
        documentsRef={documentsRef}
      />
      <MobileMenu
        user={user}
        basicInformationRef={basicInformationRef}
        certificationsRef={certificationsRef}
        serviceableAreaRef={serviceableAreaRef}
        accessAndSoftwareRef={accessAndSoftwareRef}
        documentsRef={documentsRef}
      />
      <InspectorDashboardContent>
        {renderPageContent(state.page)}
      </InspectorDashboardContent>
    </div>
  );
};

export default InspectorDashboardPage;
