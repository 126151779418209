import { useQuery } from '@apollo/client';
import { INSPECTOR_ONBOARDING } from '../../graphql/queries';
import { captureException } from '../../utils/error';
import { OnboardingVariables } from './types';
import { OnboardingType } from '../../types';

export const useInspectorOnboarding = (variables?: OnboardingVariables) => useQuery<OnboardingType, OnboardingVariables>(INSPECTOR_ONBOARDING,
  {
    variables: { id: variables?.id },
    context: { headers: { onboarding_id: variables?.id } },
    onError: () => { captureException(new Error(variables?.errorMessage)); },
    onCompleted: (data: OnboardingType) => { variables?.onCompleted?.(data); },
  });
