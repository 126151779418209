import { useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageErrorIndicator from '../../../components/PageError';
import { PermitsBanner } from '../../../components/Permits/Permits/PermitsBanner';
import { PermitsPropertyImage } from '../../../components/PropertyImage/PropertyImage';
import Spinner from '../../../components/Spinner';
import { ORDER_PERMIT_FRAGMENT } from '../../../graphql/queries';
import {
  saveWorksForOrders,
  setActiveProperty,
  updateSplitioAttributes,
} from '../../../redux/actions';
import { selectWorks, selectWorksPropertyPermits } from '../../../redux/selectors';
import { useTypedSelector } from '../../../redux/store';
import { PageErrorType } from '../../../types';
import PermitsPageLayout from '../components/layout/PermitsPageLayout';
import PermitsTable from '../components/table/PermitsTable';
import { PermitsContext } from '../context';

import styles from './styles.module.scss';

interface Props {
  orderToken: string;
}

const PermitsPage: React.FC<Props> = ({
  orderToken,
}) => {
  const dispatch = useDispatch();
  const works = useTypedSelector(selectWorks);
  const history = useHistory();

  const {
    state,
    set,
    setOrderProperty,
  } = useContext(PermitsContext);

  useEffect(() => {
    if (orderToken) set('orderToken', orderToken);
  }, [orderToken]);

  useEffect(() => {
    if (state.orderId) setOrderProperty(state.orderId, works);
  }, [state.orderId, works]);

  useEffect(() => {
    if (state?.orderTeamId) {
      dispatch(updateSplitioAttributes({ teamId: state.orderTeamId }));
    }
  }, [state?.orderTeamId]);

  const permits = useTypedSelector(
    (reduxState) => selectWorksPropertyPermits(reduxState, state.orderId as number),
  );

  const { loading } = useQuery(ORDER_PERMIT_FRAGMENT, {
    variables: { token: orderToken },
    context: { headers: { order_token: orderToken } },
    onCompleted: (data) => {
      if (data?.order) {
        set('orderId', data.order.id);
        dispatch(saveWorksForOrders(data, works));
        dispatch(setActiveProperty(data.order.property.id));
      }
    },
    onError: () => {
      set('permitsQueryError', PageErrorType.GeneralError);
    },
  });

  useEffect(() => {
    if (!loading && state.property && permits.length === 0) {
      window.location.assign(`/orders/${state.orderToken}/reports`);
    }
  }, [history, loading, permits, state.orderToken, state.property]);

  if (loading) return <Spinner style={{ width: '100vw', height: '100vh' }} />;

  return (
    <PermitsPageLayout>
      <Container fluid className={styles.body}>
        <div className={styles.propertyInfosContainer}>
          <PermitsPropertyImage property={state.property} />

          <PermitsBanner permits={Object.values(permits)} />
        </div>

        <PermitsTable permits={permits} />

        {state.permitsQueryError && <PageErrorIndicator errorType={state.permitsQueryError} />}
      </Container>
    </PermitsPageLayout>
  );
};

export default PermitsPage;
