import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  ReportSubsystem,
  ReportSystem,
} from 'marketplace-common';
import { getSystemIssueCounts } from '../../../../utils/report';
import { useReportsPageContext } from '../../../../utils/reportsPageContext';
import { ReportDeliveryFilter } from '../../../../types/models';
import MenuBadge, { MenuBadgeType } from './MenuBadge';
import MenuSubsystem from './MenuSubsystem';
import { BadgeContainer, MenuItem, MenuText } from './styles';
import { ReportContext } from '../../context';
import { getGroupedSubsystemsByName } from '../../utils';

interface Props {
  systemName: string
  filter: ReportDeliveryFilter
  isMobileView?: boolean
  noDeficienciesRequired: boolean
  onMobileItemPress?: () => void
}

const MenuSystem: React.FC<Props> = ({
  systemName,
  isMobileView,
  onMobileItemPress,
  filter,
  noDeficienciesRequired,
}) => {
  const { state } = useContext(ReportContext);
  const { state: menuState, dispatch } = useReportsPageContext();
  const [showSubsystemMenuItems, setShowSubsystemMenuItems] = useState(false);
  const [isScrollingOverSubsystem, setIsScrollingOverSubsystem] = useState(false);

  const systems: ReportSystem[] = useMemo(() => (
    state.groupedReportSystemsByName?.[systemName] || []
  ), [state.groupedReportSystemsByName, systemName]);

  const groupedSubsystems: { [name: string]: ReportSubsystem[] } = useMemo(() => (
    systems && state?.selectedReport?.subsystems
      ? getGroupedSubsystemsByName(systems, state.selectedReport.subsystems) : {}
  ), [systems, state?.selectedReport?.subsystems]);

  const {
    safety: safetyDefectCount,
    monitor: monitorDefectCount,
    repair: repairDefectCount,
    fixed: fixedDefectCount,
    newDefects: newDefectCount,
    flagged: flaggedDefectCount,
    limitations: limitationsCount,
  } = getSystemIssueCounts(systems, state.selectedReport, filter);

  const remainingIssueCount = (
    safetyDefectCount
    + monitorDefectCount
    + repairDefectCount
    + flaggedDefectCount
    + limitationsCount
  );

  const greyOutSystemName = (
    filter !== ReportDeliveryFilter.FullReport
    && !fixedDefectCount
    && !monitorDefectCount
    && !newDefectCount
    && !repairDefectCount
    && !safetyDefectCount
    && !flaggedDefectCount
    && !limitationsCount
  );

  const onMenuItemClick = () => {
    if (systems.every((system) => system.reportSubsystemIds.length === 1)) {
      if (onMobileItemPress) { onMobileItemPress(); }
      dispatch({ type: 'set_active_item', payload: systemName });
    } else {
      setShowSubsystemMenuItems(!showSubsystemMenuItems);
    }
  };

  useEffect(() => {
    if (groupedSubsystems && menuState.activeMenuItem) {
      setIsScrollingOverSubsystem(
        Object.keys(groupedSubsystems).includes(menuState.activeMenuItem),
      );
    }
  }, [menuState.activeMenuItem, groupedSubsystems]);

  useEffect(() => {
    setShowSubsystemMenuItems(isScrollingOverSubsystem && systemName !== 'Home Inspection Details');
  }, [isScrollingOverSubsystem]);

  return (
    <>
      <MenuItem
        key={systemName}
        href={`#${systemName}`}
        isActive={menuState.activeMenuItem === systemName
          || (!showSubsystemMenuItems && isScrollingOverSubsystem)}
        isMobileView={isMobileView}
        isDisabled={greyOutSystemName}
        onClick={onMenuItemClick}
      >
        <MenuText style={{ color: greyOutSystemName ? 'gray' : '' }}>
          {systemName}
        </MenuText>
        {!noDeficienciesRequired && (
          <BadgeContainer>
            {fixedDefectCount > 0 && (
              <MenuBadge
                type={MenuBadgeType.FixedSystemDeficiency}
                number={fixedDefectCount}
              />
            )}
            {remainingIssueCount > 0 && (
              <MenuBadge
                type={MenuBadgeType.GeneralDeficiency}
                number={remainingIssueCount}
              />
            )}
          </BadgeContainer>
        )}
      </MenuItem>
      {showSubsystemMenuItems && Object.keys(groupedSubsystems).map((subsystemName) => (
        <MenuSubsystem
          key={subsystemName}
          subsystemName={subsystemName}
          subsystems={groupedSubsystems[subsystemName]}
          isMobileView={isMobileView}
          onMobileItemPress={onMobileItemPress}
          noDeficienciesRequired={noDeficienciesRequired}
        />
      ))}
    </>
  );
};

export default MenuSystem;
