import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useUrlQuery } from '../../utils/hooks';
import { captureException } from '../../utils/error';
import { CREATE_SURVEYOR_ONBOARDING } from '../../graphql/mutations/surveyorOnboarding';
import { CREATE_INSPECTOR_ONBOARDING } from '../../graphql/mutations/inspectorOnboarding';
import { ContractorTypeEnum, OnboardingCreateResponseType } from '../../types';

const OnboardingRedirectPage: React.FC = () => {
  const { onboardingContractorType } = useParams<{ onboardingContractorType: string }>();
  const query = useUrlQuery();
  const qUtmSource = query.get('utm_source');
  const qUtmCampaign = query.get('utm_campaign');

  const [createSurveyorOnboarding] = useMutation(
    CREATE_SURVEYOR_ONBOARDING,
    {
      onCompleted: (data: OnboardingCreateResponseType) => {
        if (data) {
          window.location.href = `/onboarding/${onboardingContractorType}/${data.createSurveyorOnboarding.surveyorOnboarding.id}`;
        }
      },
      onError: () => {
        captureException(new Error('Graphql failed to create surveyor onboarding'));
      },
    },
  );

  const [createInspectorOnboarding] = useMutation(
    CREATE_INSPECTOR_ONBOARDING,
    {
      onCompleted: (data: OnboardingCreateResponseType) => {
        if (data) {
          window.location.href = `/onboarding/${onboardingContractorType}/${data.createInspectorOnboarding.inspectorOnboarding.id}`;
        }
      },
      onError: () => {
        captureException(new Error('Graphql failed to create inspector onboarding'));
      },
    },
  );

  useEffect(() => {
    if (onboardingContractorType !== ContractorTypeEnum.Surveyor && onboardingContractorType !== ContractorTypeEnum.Inspector) {
      window.location.href = 'https://inspectify.com';
    }
    try {
      if (onboardingContractorType === ContractorTypeEnum.Surveyor) {
        createSurveyorOnboarding({
          variables: {
            input: {
              utmSource: qUtmSource,
              utmCampaign: qUtmCampaign,
            },
          },
        });
      }
      if (onboardingContractorType === ContractorTypeEnum.Inspector) {
        createInspectorOnboarding({
          variables: {
            input: {
              utmSource: qUtmSource,
              utmCampaign: qUtmCampaign,
            },
          },
        });
      }
    } catch (error) {
      captureException(error);
    }
  }, [onboardingContractorType]);

  return <div />;
};

export default OnboardingRedirectPage;
