import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import OnboardingLandingPage from './OnboardingLandingPage';
import InspectifyLayout from '../../../components/InspectifyLayout';
import { captureException } from '../../../utils/error';
import { ContractorTypeEnum, OnboardingCompletedStep } from '../../../types';
import {
  useInspectorOnboarding, useSurveyorOnboarding, useUpdateInspectorOnboarding, useUpdateSurveyorOnboarding,
} from '../../../hooks/onboarding';

const OnboardingLandingPageLoader: React.FC = () => {
  const { onboardingContractorType } = useParams<{ onboardingContractorType: ContractorTypeEnum }>();
  const { onboardingId } = useParams<{ onboardingId: string }>();
  const [errorLoadingVideo, setErrorLoadingVideo] = React.useState(false);

  const { data: surveyorOnboardingData } = useSurveyorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching surveyor onboarding',
    contractorType: onboardingContractorType,
  });

  const { data: inspectorOnboardingData } = useInspectorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching inspector onboarding',
    contractorType: onboardingContractorType,
  });

  const completedStep = surveyorOnboardingData?.surveyorOnboarding?.completedStep || inspectorOnboardingData?.inspectorOnboarding?.completedStep;

  const { updateSurveyorOnboarding } = useUpdateSurveyorOnboarding({ errorMessage: 'Graphql failed to update surveyor onboarding' });

  const { updateInspectorOnboarding } = useUpdateInspectorOnboarding({ errorMessage: 'Graphql failed to update inspector onboarding' });

  const handleStartOnboarding = () => {
    try {
      if (completedStep === OnboardingCompletedStep.LandingPageViewed) {
        if (onboardingContractorType === ContractorTypeEnum.Surveyor) {
          updateSurveyorOnboarding({
            variables: {
              input: {
                id: onboardingId,
                completedStep: OnboardingCompletedStep.FormViewed,
              },
            },
            context: { headers: { onboarding_id: onboardingId } },
          });
        }
        if (onboardingContractorType === ContractorTypeEnum.Inspector) {
          updateInspectorOnboarding({
            variables: {
              input: {
                id: onboardingId,
                completedStep: OnboardingCompletedStep.FormViewed,
              },
            },
            context: { headers: { onboarding_id: onboardingId } },
          });
        }
      }
      window.open(`/onboarding/${onboardingContractorType}/${onboardingId}/form`, '_blank');
    } catch (error) {
      captureException(error, { source: 'User clicked Start onboarding form' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Welcome to Inspectify!</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showSignIn={false} hideHeaderOnMobile />
      <OnboardingLandingPage
        onboardingContractorType={onboardingContractorType}
        handleStartOnboarding={handleStartOnboarding}
        errorLoadingVideo={errorLoadingVideo}
        setErrorLoadingVideo={setErrorLoadingVideo}
      />
    </>
  );
};

export default OnboardingLandingPageLoader;
