import { useQuery } from '@apollo/client';
import ConfirmIcon from 'haven-design-system/build/assets/svgs/action/confirm.svg?component';
import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FETCH_AVAILABLE_USERS } from '../../graphql/queries';
import { AvailableUser } from '../../types';
import './styles.scss';
import { captureException, errorMessage } from '../../utils/error';
import { getStateCodeFromStateName } from '../../utils/map';
import { handleSwitchAvailableUser } from '../../utils/user';
import Spinner from '../Spinner';

const AccountSwitcher: React.FC = () => {
  const { loading, error, data: availableUsersData } = useQuery(FETCH_AVAILABLE_USERS, {
    variables: { onlyContractors: false },
  });

  useEffect(() => {
    // set the first available user as the selected user if no selected user is found
    const selectedUser = localStorage.getItem('inspectifySelectedUser');
    if (!selectedUser || selectedUser === 'undefined') {
      localStorage.setItem('inspectifySelectedUser', availableUsersData?.availableUsers?.[0]?.id || null);
    }
    const selectedTeam = localStorage.getItem('inspectifySelectedTeamId');
    if (!selectedTeam || selectedTeam === 'undefined') {
      localStorage.setItem('inspectifySelectedTeamId', availableUsersData?.availableUsers?.[0]?.team?.id || null);
    }
  }, [availableUsersData]);

  if (loading) {
    return (
      <Spinner />
    );
  }

  if (error) {
    captureException(error, { source: 'Loading available users' });
    return (
      <div className="accountSwitcher__error">
        {errorMessage('loading available users')}
      </div>
    );
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className="accountSwitcher__dropdown__toggle">
        Accounts
      </Dropdown.Toggle>

      <Dropdown.Menu className="accountSwitcher__dropdown__menu">
        {availableUsersData.availableUsers?.map((availableUser: AvailableUser) => (
          <div key={availableUser.id}>
            {availableUser.contractor ? (
              <Dropdown.Item
                className="accountSwitcher__dropdown__menu__item"
                onClick={() => handleSwitchAvailableUser(availableUser)}
              >
                <div className="accountSwitcher__dropdown__menu__item__container">
                  <div className="accountSwitcher__dropdown__menu__item__container__text">
                    <div className="accountSwitcher__dropdown__menu__item__container__text__businessName">
                      {availableUser?.contractor?.businessName}
                    </div>
                    <div className="accountSwitcher__dropdown__menu__item__container__text__location">
                      {`${availableUser?.contractor?.city}, ${getStateCodeFromStateName(availableUser?.contractor?.state)}`}
                    </div>
                  </div>
                  <div className="accountSwitcher__dropdown__menu__item__container__selected">
                    {localStorage.getItem('inspectifySelectedUser') === availableUser?.id
                      ? (<ConfirmIcon className="accountSwitcher__dropdown__menu__item__container__selected__icon" />)
                      : null}
                  </div>
                </div>
              </Dropdown.Item>
            ) : null}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AccountSwitcher;
