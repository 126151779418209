import { useQuery } from '@apollo/client';
import { captureException } from '../../utils/error';
import { OnboardingVariables } from './types';
import { SURVEYOR_ONBOARDING } from '../../graphql/queries';
import { ContractorTypeEnum, OnboardingQueryResponseType } from '../../types';

export const useSurveyorOnboarding = (variables?: OnboardingVariables) => useQuery<OnboardingQueryResponseType, OnboardingVariables>(SURVEYOR_ONBOARDING,
  {
    variables: { id: variables?.id },
    skip: variables?.contractorType !== ContractorTypeEnum.Surveyor,
    context: { headers: { onboarding_id: variables?.id } },
    onError: () => { captureException(new Error(variables?.errorMessage)); },
    onCompleted: (data: OnboardingQueryResponseType) => { variables?.onCompleted?.(data); },
  });
