export interface ContactModalProps {
  header: string,
  subheader: string,
  url: string,
  onClose: () => void,
}

export enum ContactSupport {
  Header = 'Questions? Ask us!',
  Body = 'Please email our partnerships team at the following email: ',
}
