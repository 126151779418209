import { Team, TemplateWorkItemPricing } from '../../../../../types';

export enum TabOptions {
  WorkItemLibraryTab,
  WorkItemFormTab,
}

export type WorkItemFormState = {
  selectedTab: TabOptions
  systemName: string
  title: string
  description: string
  unit: string
  tags: string[]
  // type string for decimal support
  unitCost: string
  totalCost: string
  quantity: number
  saveToLibrary: boolean
  useLumpSumCost: boolean
  isRemoveModalOpen: boolean
  isContributionDistribution: boolean
  teamMarketId?: string
  templateWorkItemPricingId?: string
  pricingExternalId?: string
};

export type WorkItemFormComputedState = {
  team: Team
  rehabTags: string[]
  calculatedTotal: number
  workItemPricing?: TemplateWorkItemPricing | null
  workItemPricings: TemplateWorkItemPricing[]
};

export enum ExternalTagTeamIds {
  SECOND_AVE = '2426',
  SOVEREIGN = '2853',
}
