import React from 'react';
import styles from './styles.module.scss';
import { GroupedData, SowInspectionSummaryProps } from './types';

export const SowInspectionSummary = ({
  report,
}: SowInspectionSummaryProps) => {
  const isMultiUnit = report?.systemGroups && Object.keys(report.systemGroups).length > 0;

  const createRowData = () => {
    const data = report?.informations
      ? Object.values(report.informations).filter(
        (info) => info.dataName.includes('sow_completed'),
      ).map((info) => {
        const subsystem = report?.subsystems[info.reportSubsystemId];
        const system = report?.systems[subsystem.reportSystemId];
        const reportSystemGroup = report?.systemGroups?.[system?.reportSystemGroupId]?.name || null;
        const sanitizedDescription = info?.detail?.includes('Is the agreed upon work described complete?')
          ? info.detail.replace(/Is the agreed upon work described complete\?/g, '').trim()
          : info.detail;

        return {
          id: info.id,
          systemName: system?.name,
          subsystemName: subsystem?.name,
          description: sanitizedDescription,
          unit: reportSystemGroup,
          status: info?.value,
        };
      })
      : [];

    if (isMultiUnit) {
      const groupedData: GroupedData = {};

      data.forEach((row) => {
        if (!groupedData[row.systemName]) {
          groupedData[row.systemName] = {};
        }
        if (!groupedData[row.systemName][row.subsystemName]) {
          groupedData[row.systemName][row.subsystemName] = [];
        }
        groupedData[row.systemName][row.subsystemName].push(row);
      });

      return Object.values(groupedData).flatMap((subsystems) => Object.values(subsystems).flat());
    }

    return data;
  };

  const sowRowdata = createRowData();

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.dataCell}>System</th>
            <th className={styles.dataCell}>Title</th>
            <th className={styles.dataCell}>Description</th>
            {isMultiUnit && <th className={styles.dataCell}>Unit</th>}
            <th className={styles.dataCell}>Status</th>
          </tr>
        </thead>
        <tbody>
          {sowRowdata.map((row) => {
            const status = row?.status && JSON.parse(row.status) === 'Complete' ? 'Complete' : 'Incomplete';
            const isComplete = Boolean(status === 'Complete');

            return (
              <tr key={row.id}>
                <td className={styles.dataCell}>{row.systemName}</td>
                <td className={styles.dataCell}>{row.subsystemName}</td>
                <td className={styles.dataCell}>{row.description}</td>
                {isMultiUnit && <td className={styles.dataCell}>{row.unit}</td>}
                <td className={styles.dataCell}>
                  <a href={`#${row?.subsystemName}`} className={isComplete ? styles.completeLink : styles.incompleteLink}>
                    {status}
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
