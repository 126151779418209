import cn from 'classnames';
import Filter from 'haven-design-system/build/assets/svgs/action/filter-view.svg?component';
import { BETTER_INSPECT_PHONE_NUMBER_FORMATTED, BETTER_INSPECT_PHONE_NUMBER_LINK } from 'marketplace-common';
import React, { useContext, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import BetterLogo from '../../../../assets/better/BetterInspect.svg';
import RehabPreview from '../../../../assets/images/macbookProRehabDemo.png';
import InspectifyLogo from '../../../../assets/inspectify/Inspectify_Logo_Color.svg';
import Button from '../../../../components/Button';
import HubspotContactForm from '../../../../components/Hubspot/HubspotContactForm';
import { ReportPropertyImage } from '../../../../components/PropertyImage/PropertyImage';
import { client } from '../../../../graphql/createApolloClient';
import { FETCH_REHAB_PROJECT_IDS_FOR_REPORT } from '../../../../graphql/queries';
import { ReportDeliveryFilter } from '../../../../types';
import { captureException } from '../../../../utils/error';
import { useCurrentUser } from '../../../../utils/user';
import { ReportContext } from '../../context';
import ContractorDetails from '../ContractorDetails';
import MenuItems from './MenuItems';
import {
  LeaveModalText,
  LoginForm,
  LoginLink,
  LoginText,
  Logo,
  MainText,
  RehabForm,
  RehabSignupActions,
  RehabSignupModal,
  RehabSignupModalStyle,
  RehabSignupPic,
  SubText,
} from './styles';
import styles from './styles.module.scss';

interface Props {
  filter: ReportDeliveryFilter;
  groupNames: string[] | null;
  toggleReportFilterModal: (value: boolean) => void;
  teamId?: number;
  createRehabProject?: () => void;
}

const Menu: React.FC<Props> = ({
  filter,
  groupNames,
  toggleReportFilterModal,
  teamId,
  createRehabProject,
}) => {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { state } = useContext(ReportContext);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const isBetterInspect = useMemo(() => window.location.hostname.includes('betterinspect'), []);

  const showStartContinueButton: boolean = useMemo(() => (
    teamId && state.allowRehab
  ), [state.allowRehab, teamId, state.existingRehabProjectId]);

  const formattedSystemGroupNames = useMemo(() => {
    if (groupNames?.length > 1) {
      return `, ${groupNames.length} units`;
    }

    if (groupNames?.length > 0) {
      return `, ${groupNames[0]}`;
    }

    return null;
  }, [groupNames]);

  const handleContinueRehab = (projectId = state.existingRehabProjectId) => {
    history.push(`/rehab/${projectId}`);
  };

  const handleStartRehab = async () => {
    if (state.allowRehab && teamId && currentUser) {
      const { data, errors } = await client.query(
        {
          query: FETCH_REHAB_PROJECT_IDS_FOR_REPORT,
          variables: { reportId: state.selectedReport.report.id },
        },
      );
      if (errors) {
        captureException(errors, { source: 'Loading rehab project IDs' });
      } else if (data?.rehabProjects?.edges?.length) {
        const filteredProjects = data.rehabProjects.edges.filter(({ node }: any) => (
          node.teamId === `${teamId}`
        ));

        if (filteredProjects?.length > 0) {
          handleContinueRehab(filteredProjects[0].node.id);
        } else {
          createRehabProject();
        }
      } else {
        createRehabProject();
      }
    } else {
      setIsSignupModalOpen(true);
    }
  };

  return (
    <Container className={styles.container}>
      <Modal
        isOpen={isSignupModalOpen}
        onRequestClose={() => setIsSignupModalOpen(false)}
        ariaHideApp={false}
        style={RehabSignupModalStyle}
        preventScroll={false}
      >
        <RehabSignupModal>
          <RehabSignupActions>
            <Logo src={InspectifyLogo} alt="Inspectify" />
            <MainText>Interested in property rehab?</MainText>
            <SubText>
              Please provide your email and we&apos;ll reach out
              with more information on our Rehab Tool.
            </SubText>
            <RehabForm>
              <HubspotContactForm />
            </RehabForm>
            {!currentUser && (
              <LoginForm>
                <LoginText>Already have an Inspectify account?</LoginText>
                <LoginLink
                  onClick={() => {
                    window.location.assign(`/sign_in?from=${window.location.pathname}`);
                  }}
                >
                  Login
                </LoginLink>
              </LoginForm>
            )}
            <LeaveModalText onClick={() => setIsSignupModalOpen(false)}>
              Go back to my inspection report.
            </LeaveModalText>
          </RehabSignupActions>
          <RehabSignupPic src={RehabPreview} alt="RehabPreview" />
        </RehabSignupModal>
      </Modal>

      {isBetterInspect ? (
        <img
          src={BetterLogo}
          alt="Better Inspect"
          className={cn(styles.logo, styles.betterLogo)}
        />
      ) : <img src={InspectifyLogo} alt="Inspectify" className={styles.logo} />}

      <div className={styles.propertyImageAndButtonSection}>
        <ReportPropertyImage property={state.property} />

        <Button
          id="filter-report"
          onClick={() => toggleReportFilterModal(true)}
          style={{ width: '100%', marginBottom: '8px' }}
          secondary
        >
          <Filter /> Filter: {filter}{formattedSystemGroupNames}
        </Button>

        {showStartContinueButton && (
          state.existingRehabProjectId ? (
            <Button
              id="continue-rehab-project-button"
              onClick={() => handleContinueRehab()}
              onKeyPress={() => handleContinueRehab()}
              tabIndex={0}
              style={{ width: '100%', marginBottom: '8px' }}
            >
              Continue rehab project
            </Button>
          ) : (
            <Button
              id="start-rehab-project-button"
              onClick={handleStartRehab}
              onKeyPress={handleStartRehab}
              tabIndex={0}
              style={{ width: '100%', marginBottom: '8px' }}
            >
              Start rehab project
            </Button>
          )
        )}
      </div>

      <MenuItems filter={filter} />

      {isBetterInspect && (
        <div className={styles.menuPoweredByInspectify}>
          <div className={styles.menuPoweredBytext}>Powered by</div>
          <a href="https://www.inspectify.com">
            <img src={InspectifyLogo} alt="Inspectify" />
          </a>
        </div>
      )}

      {isBetterInspect && (
        <div className={styles.menuInspectifyContactInfo}>
          <a
            href="mailto:betterinspect@inspectify.com"
            target="_blank"
            rel="noreferrer"
          >
            betterinspect@inspectify.com
          </a>
        </div>
      )}

      {isBetterInspect && (
        <div className={styles.menuInspectifyContactInfo}>
          <a
            href={BETTER_INSPECT_PHONE_NUMBER_LINK}
            target="_blank"
            rel="noreferrer"
          >
            {BETTER_INSPECT_PHONE_NUMBER_FORMATTED}
          </a>
        </div>
      )}
      <br />

      {state.contractor && (
        <ContractorDetails
          contractor={state.contractor}
          state={state.property.state}
        />
      )}
    </Container>
  );
};

export default Menu;
