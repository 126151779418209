import React from 'react';
import styled from 'styled-components';
import LimitationIcon from 'haven-design-system/build/assets/svgs/issues/limitation_outlined.svg?component';
import IssueIcon from 'haven-design-system/build/assets/svgs/issues/deficiency_outlined.svg?component';
import SafetyIcon from 'haven-design-system/build/assets/svgs/issues/safety.svg?component';
import MonitorIcon from 'haven-design-system/build/assets/svgs/issues/monitor.svg?component';
import RepairIcon from 'haven-design-system/build/assets/svgs/issues/repair.svg?component';
import FixedIcon from 'haven-design-system/build/assets/svgs/action/confirm.svg?component';
import FlagIcon from 'haven-design-system/build/assets/svgs/issues/flag_filled.svg?component';
import CloseIcon from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import styles from '../IssueBadge/styles.module.scss';

export enum MenuBadgeType {
  GeneralDeficiency = 'GeneralDeficiency',
  SafetyDeficiency = 'SafetyDeficiency',
  RepairDeficiency = 'RepairDeficiency',
  MonitorDeficiency = 'MonitorDeficiency',
  FixedSystemDeficiency = 'FixedSystemDeficiency',
  FixedSubsystemDeficiency = 'FixedSubsystemDeficiency',
  FlaggedDeficiency = 'FlaggedDeficiency',
  Limitation = 'Limitation',
  Incomplete = 'Incomplete',
}

const Container = styled.div`
  min-width: 64px;
  border-radius: 50px;
  padding: 3px 8px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface MenuBadgeProps {
  type: MenuBadgeType
  number?: number
  text?: string
}

const MenuBadge: React.FC<MenuBadgeProps> = ({ type, number, text }) => {
  let icon = null;
  let issueType = 'issue';

  switch (type) {
    case MenuBadgeType.GeneralDeficiency:
      issueType = 'issue';
      icon = <IssueIcon className={styles.icon} />;
      break;
    case MenuBadgeType.FixedSystemDeficiency:
      issueType = 'fixed';
      icon = <FixedIcon />;
      break;
    case MenuBadgeType.FixedSubsystemDeficiency:
      issueType = 'fixed';
      break;
    case MenuBadgeType.SafetyDeficiency:
      issueType = 'safety';
      icon = <SafetyIcon className={styles.icon} />;
      break;
    case MenuBadgeType.MonitorDeficiency:
      issueType = 'monitor';
      icon = <MonitorIcon className={styles.icon} />;
      break;
    case MenuBadgeType.RepairDeficiency:
      issueType = 'repair';
      icon = <RepairIcon className={styles.icon} />;
      break;
    case MenuBadgeType.FlaggedDeficiency:
      issueType = 'Inspectify flagged';
      icon = <FlagIcon className={styles.icon} />;
      break;
    case MenuBadgeType.Limitation:
      issueType = 'limitation';
      icon = <LimitationIcon />;
      break;
    case MenuBadgeType.Incomplete:
      issueType = 'incomplete';
      icon = <CloseIcon className={styles.icon} />;
      break;
    default:
      return null;
  }

  return (
    <Container className={styles[issueType]}>
      {icon || null}
      {number ? <small className={styles.text}>{number}</small> : null}
      {text ? <small className={styles.text}>{text}</small> : null}
    </Container>
  );
};

export default MenuBadge;
