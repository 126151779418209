import { useLazyQuery, useMutation } from '@apollo/client';
import { getCurrentUnixTimestamp, ReportAttachment } from 'marketplace-common';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import MediaUploader, { Media } from '../../../components/MediaUploader';
import { UploadedMedia } from '../../../components/MediaUploader/useUpload';
import CREATE_REPORT_ATTACHMENT from '../../../graphql/mutations/createReportAttachment';
import { SUBMIT_FEEDBACK } from '../../../graphql/mutations/feedback';
import { CREATE_REHAB_PROJECT } from '../../../graphql/mutations/rehab';
import { FETCH_REHAB_PROJECT_IDS_FOR_REPORT } from '../../../graphql/queries';
import { addReportAttachment } from '../../../redux/actions';
import { ReportFeedbackStep, ReviewableType, UserContractor, UserFeedback } from '../../../types';
import { EventName, track } from '../../../utils/analytics';
import { captureException } from '../../../utils/error';
import { isFeedbackNoteValid } from '../../../utils/report';
import { ReportsPageProvider } from '../../../utils/reportsPageContext';
import { isUserUsingMobile } from '../../../utils/user';
import { ReportContext } from '../context';
import ReportsPage from './ReportsPage';

interface Props {
  imageWidth: number
  redirectToRehab: boolean
  user: UserContractor
  loading: boolean
}

const ReportsPageLoader: React.FC<Props> = ({
  imageWidth,
  redirectToRehab,
  user,
  loading,
}) => {
  const { state, set, setSelectedReportItem } = useContext(ReportContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMemo(() => isUserUsingMobile(), []);
  const [reportFeedbackModalIsOpen, setReportFeedbackModalIsOpen] = useState(false);
  const [isUploadMediaModalOpen, setIsUploadMediaModalOpen] = useState(false);
  const [reportFeedbackStep, setReportFeedbackStep] = useState(ReportFeedbackStep.INITIATION);
  const [reportFeedbackValues, setReportFeedbackValues] = useState({} as UserFeedback);

  const [submitFeedback] = useMutation(SUBMIT_FEEDBACK, {
    onCompleted: (data) => {
      setReportFeedbackValues(data.submitFeedback);
    },
    onError: (e) => {
      captureException(e, { source: 'Submitting feedback' });
    },
  });

  const handleSubmitFeedback = (step: ReportFeedbackStep, feedback?: string) => {
    switch (step) {
      case ReportFeedbackStep.INITIATION:
        setReportFeedbackModalIsOpen(true);
        submitFeedback({
          variables: {
            input: {
              reviewableId: String(state.selectedReportId),
              reviewableType: ReviewableType.REPORT,
            },
          },
        });
        setReportFeedbackStep(ReportFeedbackStep.SCORE);
        break;
      case ReportFeedbackStep.SCORE:
        setReportFeedbackValues({
          ...reportFeedbackValues,
          score: feedback,
        });
        setReportFeedbackStep(ReportFeedbackStep.NOTE);
        break;
      case ReportFeedbackStep.NOTE:
        // Do not submit feedback for scores > 3 unless there is also a valid note and the user
        // clicks "Submit" explicitly (opposed to escaping or closing the modal)
        if (parseInt(reportFeedbackValues.score, 10) > 3) {
          if (isFeedbackNoteValid(feedback)) {
            submitFeedback({
              variables: {
                input: {
                  id: reportFeedbackValues.id,
                  score: parseInt(reportFeedbackValues.score, 10),
                  note: feedback,
                },
              },
            });
          }
        } else {
          submitFeedback({
            variables: {
              input: {
                id: reportFeedbackValues.id,
                score: parseInt(reportFeedbackValues.score, 10),
                note: feedback,
              },
            },
          });
        }
        setReportFeedbackStep(ReportFeedbackStep.CONFIRMATION);
        break;
      default:
    }
  };

  const [createRehabProject] = useMutation(CREATE_REHAB_PROJECT, {
    onCompleted: (data) => {
      track(EventName.CreateRehabProject, {
        projectId: data.createRehabProject?.project.id,
        propertyId: state.property.id,
        teamId: state.orderTeamId,
        orderId: state.orderId,
        reportId: state.selectedReportId,
      });
      set('existingRehabProjectId', data.createRehabProject.project.id);
      history.push(`/rehab/${data.createRehabProject?.project.id}`);
    },
  });

  const [createReportAttachment] = useMutation(CREATE_REPORT_ATTACHMENT, {
    onCompleted: (data) => {
      const { reportAttachment } = data.createReportAttachment;
      if (reportAttachment) dispatch(addReportAttachment(state.selectedReportId, reportAttachment));
    },
    onError: (e) => {
      captureException(e, { source: 'Creating report attachment' });
    },
  });

  const [fetchRehabProjectIds, { loading: loadingRehabProject }] = useLazyQuery(
    FETCH_REHAB_PROJECT_IDS_FOR_REPORT,
    {
      onCompleted: ({ rehabProjects }) => {
        // Currently, mapping the first rehab project we find for the report. 1-1
        // In the future, we will support handling multiple projects for report. 1-many
        // https://inspectify.slack.com/archives/C02HK6HDYBC/p1660695474983649
        // Make sure to grab the rehab project on the same team so that Admin don't pickup
        // rehab project for Repair estimate (Team 38)
        if (rehabProjects?.edges?.length) {
          const filteredProjects = rehabProjects.edges.filter(({ node }: any) => (
            node.teamId === `${state.order?.teamId}`
          ));
          if (filteredProjects?.length > 0) set('existingRehabProjectId', filteredProjects[0].node.id);
        }
      },
    },
  );

  const reportAttachmentMedia = useMemo(() => Object.values(state?.selectedReport?.attachments || {}).reduce(
    (acc: Media[], { id, attachmentUrl: src, mediaType }) => [...acc, { id, src, mediaType }], [],
  ), [state?.selectedReport?.attachments]);

  const handleAddMediaToReportItem = (
    { attachmentIds, uploadedMedia }:
    { attachmentIds: string[], uploadedMedia: UploadedMedia[] },
  ) => {
    const attachments: ReportAttachment[] = [];
    attachmentIds.forEach((id) => {
      const attachment = state.selectedReport.attachments[id];
      if (attachment) {
        const attachmentId = uuid();
        attachments.push({
          ...attachment,
          id: attachmentId,
          fileName: `${attachmentId}-${attachment.fileName}`,
          itemId: state.selectedReportItemId,
          itemType: state.selectedReportItemType,
          reportSystemGroupId: state.selectedReportItemSystemGroupId,
          updatedAt: getCurrentUnixTimestamp(),
        });
      }
    });
    uploadedMedia.forEach((file) => {
      attachments.push({
        mediaType: file.contentType,
        reportId: state.selectedReportId as string,
        id: file.id,
        fileName: file.fileName,
        width: 0, // This doesn't matter for now, it's just that the type requires it
        height: 0, // This doesn't matter for now, it's just that the type requires it
        itemId: state.selectedReportItemId,
        itemType: state.selectedReportItemType,
        reportSystemGroupId: state.selectedReportItemSystemGroupId,
        s3ObjectKey: file.s3ObjectKey,
        attachmentUrl: file.contentType === 'video' ? null : file.signedUrl,
        thumbnailUrl: file.contentType === 'video' ? file.signedUrl : null,
        duration: null,
        updatedAt: getCurrentUnixTimestamp(),
        longLiveUrl: null,
      });
    });

    attachments.forEach((attachment) => {
      createReportAttachment({
        variables: {
          input: {
            reportId: attachment.reportId,
            reportSystemGroupId: attachment.reportSystemGroupId,
            itemId: attachment.itemId,
            itemType: attachment.itemType,
            fileName: attachment.fileName,
            s3ObjectKey: attachment.s3ObjectKey,
            updatedAt: getCurrentUnixTimestamp(),
          },
        },
      });
    });
    setIsUploadMediaModalOpen(false);
  };

  useEffect(() => {
    if (state?.selectedReport?.report && state.order) {
      fetchRehabProjectIds({
        variables: { reportId: state.selectedReport.report.id },
      });
    }
  }, [state.selectedReport, state.order]);

  useEffect(() => {
    if (user?.currentUser && state.orderTeamId) {
      const currentTeamUser = user?.currentUser?.teamUsers?.find((teamUser) => teamUser.team.id === state.orderTeamId);
      const teamAllowsRehab = currentTeamUser?.team?.allowRehabToolAccess;
      set('allowRehab', teamAllowsRehab || user?.currentUser?.admin);
    } else {
      set('allowRehab', true);
    }
  }, [state.orderTeamId, user]);

  const createRehab = () => {
    if (state.property && state.selectedReport && state.allowRehab) {
      createRehabProject({
        variables: {
          input: {
            name: `${state.property.address} ${Date.now().toString()}`,
            address: state.property.address,
            teamId: state.orderTeamId,
            reportId: state.selectedReportId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (redirectToRehab) {
      if (state.existingRehabProjectId) {
        history.push(`/rehab/${state.existingRehabProjectId}`);
      } else {
        createRehab();
      }
    }
  }, [
    state.existingRehabProjectId,
    redirectToRehab,
    state.property,
    state.selectedReport,
    state.allowRehab,
  ]);

  const openReportMediaUploader = (itemId: string, itemType: string, reportSystemGroupId?: string | null) => {
    setSelectedReportItem(itemId, itemType, reportSystemGroupId);
    setIsUploadMediaModalOpen(true);
  };

  const closeReportMediaUploader = () => {
    setSelectedReportItem(null, null, null);
    setIsUploadMediaModalOpen(false);
  };

  const handleSelectReport = (id: string) => {
    set('selectedReportId', id);
  };

  return (
    <ReportsPageProvider>
      <ReportsPage
        loading={loading || loadingRehabProject || redirectToRehab}
        imageWidth={imageWidth}
        selectReport={handleSelectReport}
        createRehabProject={createRehab}
        inspectifyAdmin={!!user?.currentUser?.admin}
        isMobile={isMobile}
        reportFeedbackModalIsOpen={reportFeedbackModalIsOpen}
        setReportFeedbackModalIsOpen={setReportFeedbackModalIsOpen}
        reportFeedbackStep={reportFeedbackStep}
        setReportFeedbackStep={setReportFeedbackStep}
        handleOpenReportMediaUploader={openReportMediaUploader}
        handleSubmitFeedback={handleSubmitFeedback}
      />

      <MediaUploader
        visible={isUploadMediaModalOpen}
        media={reportAttachmentMedia}
        title="Select media to add to report" // TODO - need to specifiy what adding to
        selectFromTitle="Select from inspection report"
        handleClose={closeReportMediaUploader}
        handleAdd={handleAddMediaToReportItem}
      />
    </ReportsPageProvider>
  );
};

export default ReportsPageLoader;
