import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  CREATE_REHAB_WORK_ITEM,
  CREATE_REHAB_WORK_ITEM_ASSOCIATION,
  CREATE_REHAB_WORK_ITEM_ATTACHMENT,
  CREATE_REHAB_WORK_ITEM_CONTRIBUTOR,
  DELETE_REHAB_WORK_ITEM,
  DELETE_REHAB_WORK_ITEM_ASSOCIATION,
  DELETE_REHAB_WORK_ITEM_ATTACHMENT,
  DELETE_REHAB_WORK_ITEM_CONTRIBUTOR,
  UPDATE_REHAB_WORK_ITEM,
  UPDATE_REHAB_WORK_ITEM_CONTRIBUTOR,
} from '../../../../../graphql/mutations/rehab';
import {
  addRehabWorkItemContributor,
  deleteRehabWorkItemAttachments,
  deleteWorkItem,
  deleteWorkItemAssociations,
  saveWorkItem,
  saveWorkItemAssociation,
  saveWorkItemAttachment,
  updateWorkItem,
} from '../../../../../redux/actions';
import { captureException } from '../../../../../utils/error';
import { onCreateRehabWorkItemCompleted, onUpdateRehabWorkItemCompleted } from '../../../../../utils/rehab';
import { WorkItemAttachment } from '../../../../../types';
import { RehabToolContext } from '../../../context';

type WorkItemMutationsParams = {
  close: () => void,
};

export const useWorkItemMutations = ({
  close,
}: WorkItemMutationsParams) => {
  const dispatch = useDispatch();

  const { state: contextState } = useContext(RehabToolContext);

  const [createRehabWorkItemAssociation, { loading: loadingAssociation }] = useMutation(
    CREATE_REHAB_WORK_ITEM_ASSOCIATION,
    {
      onCompleted: (data) => {
        if (data) {
          dispatch(saveWorkItemAssociation(data));
        } else {
          captureException(new Error('Failed to save new item association'), data);
        }
      },
      onError: () => {
        captureException(new Error('Graphql failed to save new item association'));
      },
    },
  );

  const [createRehabWorkItemAttachment, { loading: loadingAttachment }] = useMutation(
    CREATE_REHAB_WORK_ITEM_ATTACHMENT,
    {
      onCompleted: (data) => {
        if (data) {
          dispatch(saveWorkItemAttachment(data));
        } else {
          captureException(new Error('Failed to save new item attachment'), data);
        }
        close();
      },
      onError: () => {
        captureException(new Error('Graphql failed to save new item attachment'));
      },
    },
  );

  const [createRehabWorkItem, { loading: loadingCreateWorkItem }] = useMutation(
    CREATE_REHAB_WORK_ITEM,
    {
      onCompleted: async (data) => {
        if (data) {
          dispatch(saveWorkItem(data));
          onCreateRehabWorkItemCompleted(
            data,
            contextState.itemType,
            contextState.itemId,
            createRehabWorkItemAssociation,
            createRehabWorkItemAttachment,
            contextState.report,
            contextState.workItemModalAttachments,
          );
          toast.success('Work item was added to your project.');
        } else {
          captureException(new Error('Failed to save new work item'), data);
          toast.error('Failed to create new work item');
        }
        close();
      },
      onError: () => {
        captureException(new Error('Graphql failed to save new work item'));
        toast.error('Failed to create new work item');
      },
    },
  );

  const [updateRehabWorkItem, { loading: loadingUpdate }] = useMutation(
    UPDATE_REHAB_WORK_ITEM,
    {
      onCompleted: async (data) => {
        if (data) {
          const previousAttachments: string[] = (
            data.updateRehabWorkItem.workItem.workItemAttachments.map(
              (attachment: WorkItemAttachment) => attachment.id,
            )
          );
          const newAttachments = contextState.workItemModalAttachments.filter(
            (attachment) => !previousAttachments.includes(attachment.id),
          );
          dispatch(updateWorkItem(data));
          onUpdateRehabWorkItemCompleted(
            data,
            createRehabWorkItemAttachment,
            newAttachments,
          );
          toast.success('Work item was updated');
        } else {
          captureException(new Error('Failed to update work item'), data);
          toast.error('Failed to update work item');
        }
        close();
      },
      onError: () => {
        captureException(new Error('Graphql failed to update work item'));
        toast.error('Failed to update work item');
      },
    },
  );

  const [deleteRehabWorkItemAssociation] = useMutation(
    DELETE_REHAB_WORK_ITEM_ASSOCIATION,
    {
      onCompleted: (data) => {
        dispatch(deleteWorkItemAssociations([data.deleteRehabWorkItemAssociation.workItemAssociation.id]));
      },
      onError: () => {
        captureException(new Error('Graphql failed to delete work item association'));
      },
    },
  );

  const [deleteRehabWorkItemAttachment] = useMutation(
    DELETE_REHAB_WORK_ITEM_ATTACHMENT,
    {
      onCompleted: (data) => {
        dispatch(deleteRehabWorkItemAttachments([data.deleteRehabWorkItemAttachment.workItemAttachment.id]));
      },
      onError: () => {
        captureException(new Error('Graphql failed to delete work item attachment'));
      },
    },
  );

  const [deleteRehabWorkItem] = useMutation(
    DELETE_REHAB_WORK_ITEM,
    {
      onCompleted: (data) => {
        dispatch(deleteWorkItem(data.deleteRehabWorkItem.workItem.id));
        toast.success('Work item was deleted');
      },
      onError: () => {
        captureException(new Error('Graphql failed to delete work item'));
      },
    },
  );

  const [updateWorkItemContributor, { loading: loadingUpdateWorkItemContributor }] = useMutation(
    UPDATE_REHAB_WORK_ITEM_CONTRIBUTOR,
    {
      onError: (error) => {
        captureException(new Error(`Graphql failed to update work item contributor: ${error.message}`));
      },
    },
  );

  const [deleteWorkItemContributor, { loading: loadingDeleteWorkItemContributor }] = useMutation(
    DELETE_REHAB_WORK_ITEM_CONTRIBUTOR,
    {
      onError: (error) => {
        captureException(new Error(`Graphql failed to delete work item contributor: ${error.message}`));
      },
    },
  );

  const [createWorkItemContributor, { loading: loadingCreateWorkItemContributor }] = useMutation(
    CREATE_REHAB_WORK_ITEM_CONTRIBUTOR,
    {
      onCompleted: (data) => {
        // only needed when creating new work item with contributors
        // otherwise current reducers already handles CRUD ops for contributors
        dispatch(addRehabWorkItemContributor(data.createRehabWorkItemContributor.workItemContributor));
      },
      onError: (error) => {
        captureException(new Error(`Graphql failed to create work item contributor: ${error.message}`));
      },
    },
  );

  return {
    createRehabWorkItem,
    createRehabWorkItemAssociation,
    createRehabWorkItemAttachment,
    createWorkItemContributor,
    updateWorkItemContributor,
    updateRehabWorkItem,
    deleteRehabWorkItemAssociation,
    deleteRehabWorkItemAttachment,
    deleteRehabWorkItem,
    deleteWorkItemContributor,
    loadingAssociation,
    loadingAttachment,
    loadingCreateWorkItem,
    loadingUpdate,
    loading: loadingAssociation || loadingAttachment || loadingCreateWorkItem || loadingUpdate || loadingCreateWorkItemContributor || loadingDeleteWorkItemContributor || loadingUpdateWorkItemContributor,
  };
};
