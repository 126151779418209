import React, { useContext, useMemo } from 'react';
import { colorBaseBluePrimary } from 'haven-design-system/build/typescript/es6';
import assetIconActionClose from 'haven-design-system/build/assets/svgs/action/close.svg';
import BackIcon from 'haven-design-system/build/assets/svgs/controls/chevron_left.svg?component';
import { useTypedSelector } from '../../../../../redux/store';
import { selectTemplateWorkItems } from '../../../../../redux/selectors';
import {
  TemplateWorkItemsInterface,
} from '../../../../../types';
import IconButton from '../../../../../components/IconButton';
import { RehabToolContext } from '../../../context';
import { useWorkItemFormState } from './useWorkItemFormState';
import WorkItemForm from './components/WorkItemForm';
import WorkItemLibrary from './components/WorkItemLibrary';
import { TabOptions } from './types';
import styles from './styles.module.scss';

interface Props {
  systemNames: string[],
  close: () => void,
}

const RightPanel: React.FC<Props> = ({
  systemNames,
  close,
}) => {
  const { state: contextState } = useContext(RehabToolContext);

  const {
    state,
    computedState,
    update,
    set,
  } = useWorkItemFormState({
    teamId: contextState.teamId,
    systemNameOrigin: contextState.modalSystemName,
    workItem: contextState.modalWorkItem,
    modalTemplateWorkItemPricingId: contextState.modalTemplateWorkItemPricingId,
  });

  const templateWorkItems = useTypedSelector(selectTemplateWorkItems);

  const templateWorkItemsForSystem = useMemo(() => {
    if (templateWorkItems) {
      const filtered = Object.keys(templateWorkItems).reduce((hash, key) => {
        if (templateWorkItems[key].systemName === contextState.modalSystemName) {
          return { ...hash, [key]: templateWorkItems[key] };
        }
        return hash;
      }, {} as TemplateWorkItemsInterface);
      return Object.keys(filtered).length ? filtered : templateWorkItems;
    }
    return {};
  }, [templateWorkItems, contextState.modalSystemName]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1 className={styles.title}>{contextState.modalWorkItem ? 'Edit work item' : 'Add work item'}</h1>
        <IconButton onClick={close}>
          <img src={assetIconActionClose} alt="close-icon" />
        </IconButton>
      </div>

      <div className={styles.row}>
        {state.selectedTab === TabOptions.WorkItemLibraryTab ? (
          <>
            <button
              type="button"
              className={styles.tabButtonSelected}
              onClick={() => update('selectedTab', TabOptions.WorkItemLibraryTab)}
            >
              Select from library
            </button>
            <button
              type="button"
              className={styles.tabButton}
              onClick={() => update('selectedTab', TabOptions.WorkItemFormTab)}
            >
              {contextState.modalWorkItem ? 'Edit' : 'Create new'} work item
            </button>
          </>
        ) : (
          <button
            type="button"
            className={styles.backButton}
            onClick={() => update('selectedTab', TabOptions.WorkItemLibraryTab)}
          >
            <BackIcon color={colorBaseBluePrimary} /> Back to Library
          </button>
        )}
      </div>

      <div className={styles.row}>
        {state.selectedTab === TabOptions.WorkItemFormTab
          ? (
            <WorkItemForm
              state={state}
              computedState={computedState}
              setState={set}
              updateState={update}
              systemNames={systemNames}
              close={close}
            />
          ) : (
            <WorkItemLibrary
              templateWorkItemsForSystem={templateWorkItemsForSystem}
              state={state}
              setState={set}
              close={close}
            />
          )}
      </div>
    </div>
  );
};

export default RightPanel;
