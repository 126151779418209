import { fromUnixTime, max, min } from 'date-fns';
import ChecklistIcon from 'haven-design-system/build/assets/svgs/display/checklist.svg?component';
import ExportIcon from 'haven-design-system/build/assets/svgs/display/export.svg?component';
import { Permit } from 'marketplace-common';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { formatDate, getUsersTimezone } from '../../../utils/date';
import Button from '../../Button';

import styles from './styles.module.scss';

interface Props {
  permits: Permit[];
}

export const PermitsBanner: React.FC<Props> = ({ permits }) => {
  const { orderToken } = useParams<{ orderToken: string }>();
  const location = useLocation();

  const permitIssueDates = useMemo(
    () => permits.map((permit) => permit.issueDate)
      .filter((date) => date)
      .map((date) => new Date(date)),
    [permits],
  );

  const minIssueYear = useMemo(
    () => {
      if (permitIssueDates.length === 0) return null;

      return min(permitIssueDates).getFullYear();
    }, [permitIssueDates],
  );

  const maxIssueYear = useMemo(
    () => {
      if (permitIssueDates.length === 0) return null;

      return max(permitIssueDates).getFullYear();
    }, [permitIssueDates],
  );

  const lastUpdate = useMemo(
    () => {
      const createdAtDates = permits.map((permit) => fromUnixTime(permit.createdAt));

      return formatDate(max(createdAtDates), { format: "MMM d, yyyy 'at' h:mm a zzz", timezone: getUsersTimezone() });
    },
    [permits],
  );

  if (permits.length === 0) return null;

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.infosContainer}>
          <div className={styles.iconContainer}>
            <ChecklistIcon className={styles.icon} />
          </div>
          <div className={styles.infos}>
            <h2 className={styles.title}>
              {permits.length} permit{permits.length > 1 && 's'} available
            </h2>
            {minIssueYear && maxIssueYear && (
              <span>Between the years {minIssueYear} - {maxIssueYear}</span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        {location.pathname === `/orders/${orderToken}/permit_report` ? null : (
          <Button
            className={styles.button}
            onClick={() => window.open(`/orders/${orderToken}/permits`, '_blank')}
          >
            View permits <ExportIcon viewBox="0 0 24 24" width={18} height={18} />
          </Button>
        )}
        {lastUpdate ? (
          <span>Last updated on {lastUpdate}</span>
        ) : null}

      </div>
    </div>
  );
};
