import { ContractorTypeEnum, OnboardingType } from '../../../../types';
import { BusinessDetailsSection } from '../../BusinessDetails/types';

export interface OnboardingTrackerProps {
  contractorType: ContractorTypeEnum;
  setCurrentSection: (section: BusinessDetailsSection) => void;
  onboardingData: OnboardingType;
  handleContactModal: () => void;
}

export interface TrackerItemProps {
  header: string;
  instructions: string;
  isChecked: boolean;
  isOptional?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export enum TrackerItemHeader {
  MISA = 'Sign Master Inspection and Services Agreement',
  COI = 'Upload Certificate of Insurance',
  InspectionServices = 'Add additional inspection services',
  BackgroundCheck = 'Submit background check',
  Payment = 'Set up payments',
  AddUsers = 'Add users',
  App = 'Download the Inspectify App',
  Optional = '(optional)',
}

export enum TrackerItemInstructions {
  MISA = 'This is necessary before we can start sending you jobs.',
  COI = 'We require a Certificate of Insurance before we can send you job offers.',
  InspectionServices = 'Telling us your services lets us know the specialized jobs you can perform.',
  BackgroundCheck = 'Click here to submit your background check through Checkr. There may be a delay before the submission is reflected here.',
  BackgroundCheckDisabled = 'We are generating your background check invitation. Please check back soon!',
  Payment = 'This is necessary for us to pay you for your work.  There may be a delay before the submission is reflected here.',
  AddUsers = 'Include multiple inspectors on a single company account.',
  App = 'Our app can be found in the Apple App store or Google play store. Make sure you sign in once you have downloaded the app to complete this step.',
}

export enum MarketSaturationStatus {
  Header = 'Thanks for your interest!',
  Body = 'Our team will review your information to determine if we have opportunities in your area.  If there isn\'t an immediate need, we\'ll keep your application on file and contact you when work becomes available.',
}
