import { useEffect, useState } from 'react';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import uuid from 'react-uuid';
import { selectSplitIO } from '../redux/selectors';

export const getUID = () => {
  const webUid = 'inspectify_web_uid';
  const existingUid = Cookies.get(webUid);
  if (existingUid) {
    return existingUid;
  }

  const newUid = uuid();
  Cookies.set(webUid, newUid);

  return newUid;
};

export const useTreatment = (treatmentName: string) : (string | null) => {
  const splitio = useSelector(selectSplitIO);

  const [treatmentValue, setTreatmentValue] = useState(null as (string | null));

  const getTreatmentValue = async () => {
    const treatment = selectTreatmentValue(
      splitio,
      treatmentName,
    );

    setTreatmentValue(treatment);
  };

  useEffect(() => {
    getTreatmentValue();
  }, [splitio, treatmentName]);

  return treatmentValue;
};
