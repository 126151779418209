import { Context } from 'react';
import createDataContext from '../../utils/createDataContext';
import { EventName, track } from '../../utils/analytics';
import { Contractor } from '../../types';
import { DashboardPageTab } from './types';

export interface InspectorDashboardContextType {
  contractor: Contractor | null,
  page: DashboardPageTab,
  pageParams: any
}

export interface InspectorDashboardActionType {
  type: string,
  payload: any,
}

const reducer = (
  state: InspectorDashboardContextType,
  action: InspectorDashboardActionType,
) => {
  switch (action.type) {
    case 'setContractor':
      return { ...state, contractor: action.payload };
    case 'setPage':
      track(EventName.InspectorDashboardTabUsed, {
        contractor: state.contractor.id,
        tab: action.payload.page,
      });
      return { ...state, page: action.payload.page, pageParams: action.payload.pageParams };
    case 'setPageParams':
      return { ...state, pageParams: action.payload };
    default:
      return state;
  }
};

const setContractor = (dispatch: any) => (contractor: Contractor) => {
  dispatch({ type: 'setContractor', payload: contractor });
};

const setPage = (
  dispatch: any,
  history: any,
) => (page: string, pageParams: any = {}, callback = () => {}) => {
  let path = '/dashboard';
  if (pageParams && Object.keys(pageParams)) {
    const params = Object.keys(pageParams).map(
      (param) => `${param}=${pageParams[param]}`,
    ).join('&');
    path = `${path}?${params}`;
  }

  history.push(path);
  dispatch({ type: 'setPage', payload: { page, pageParams } });

  if (callback) callback();
};

const setPageParams = (
  dispatch: any,
) => (pageParams: any = {}) => {
  dispatch({ type: 'setPageParams', payload: pageParams });
};

type ContextType = { state: InspectorDashboardContextType } & {
  setContractor: (contractor: Contractor) => void,
  setPage: (page: string, pageParams?: any, callback?: any) => void,
  setPageParams: (pageParams: any) => void,
};

export type InspectorDashboardContextAndActionsType = {
  Context: Context<ContextType>,
  Provider: ({ children }: any) => JSX.Element,
};

export const {
  Context: InspectorDashboardContext,
  Provider: InspectorDashboardProvider,
}: InspectorDashboardContextAndActionsType = createDataContext(
  reducer,
  { setContractor, setPage, setPageParams },
  {
    contractor: null,
    page: DashboardPageTab.Jobs,
    pageParams: {},
  },
);
