import React from 'react';
import { Spinner } from 'react-bootstrap';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface Props extends Omit<MaskedInputProps, 'mask'> {
  name: string;
  value: number | string;
  tag?: string;
  className?: string;
  includeLabel?: boolean;
  loading?: boolean;
  // onValueChange prop only used in workitemform / rehab context
  onValueChange?: (value: string) => void;
  onValueBlur?: (value: number) => void;
}

const currencyMask = createNumberMask({
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  allowNegative: true,
  allowLeadingZeroes: false,
});

function getValue(e: React.ChangeEvent<HTMLInputElement>): number {
  const rawValue = e.target.value.replace(/[$,]/g, '');
  return parseFloat(rawValue) || 0;
}

function getRawValue(e: React.ChangeEvent<HTMLInputElement>): string {
  return e.target.value.replace(/[$,]/g, '');
}

const FormInputCurrency: React.FC<Props> = ({
  name,
  className,
  includeLabel = true,
  onValueChange,
  onValueBlur,
  min,
  loading,
  ...rest
}) => {
  const defaultStyles = {
    display: 'flex',
    // need to explicitly declare type for ts to understand its a valid css proprety
    flexDirection: 'column' as const,
    flex: 1,
  };

  const loadingContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
  };

  const containerStyles = loading !== undefined ? loadingContainerStyles : defaultStyles;

  return (
    <div className="inputContainer">
      {includeLabel && <label htmlFor={name} className="label"> {name} </label>}
      <div style={containerStyles}>
        <MaskedInput
          {...rest}
          id={name}
          inputMode="text"
          className={className || 'input'}
          mask={currencyMask}
          disabled={loading}
          onBlur={(e) => onValueBlur?.(getValue(e))}
          onChange={(e) => onValueChange?.(getRawValue(e))}
        />
        {loading && (
          <div style={{ position: 'relative', right: 30 }}>
            <Spinner animation="border" size="sm" />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormInputCurrency;
