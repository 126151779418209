import { gql } from '@apollo/client';

const CREATE_REPORT_ATTACHMENT = gql`
    mutation CreateReportAttachment($input: CreateReportAttachmentInput!) {
        createReportAttachment(input: $input) {
            reportAttachment {
                attachmentUrl
                longLiveUrl
                createdAt
                fileName
                id
                itemId
                itemType
                metadata
                reportId
                reportSystemGroupId
                resizedUrls {
                    url
                    width
                }
                rotatedUrls {
                    url
                    degree
                }
                s3ObjectKey
                thumbnailUrl
                updatedAt
            }
        }
    }
`;

export default CREATE_REPORT_ATTACHMENT;
