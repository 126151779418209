import React, { useMemo } from 'react';
import {
  Report,
  ReportSubsystem,
  ReportSystem,
  sortReportSystemOrSubsystemByDisplayIndex,
  ReportSeverityType,
  ReInspectionStatus,
  ReportDeficiency,
} from 'marketplace-common';
import LimitationIcon from 'haven-design-system/build/assets/svgs/issues/limitation_outlined.svg?component';
import SafetyIcon from 'haven-design-system/build/assets/svgs/issues/safety.svg?component';
import MonitorIcon from 'haven-design-system/build/assets/svgs/issues/monitor.svg?component';
import RepairIcon from 'haven-design-system/build/assets/svgs/issues/repair.svg?component';
import FixedIcon from 'haven-design-system/build/assets/svgs/action/confirm.svg?component';
import FlagIcon from 'haven-design-system/build/assets/svgs/issues/flag_filled.svg?component';
import { ReportDeliveryFilter } from '../../../../types';
import styles from './styles.module.scss';
import { selectWorksOrderUnits } from '../../../../redux/selectors';
import { useTypedSelector } from '../../../../redux/store';

interface Props {
  report: Report
  systems: ReportSystem[]
  filter?: ReportDeliveryFilter
  hideDeficiencies?: boolean
}

enum IssueType {
  fixed = 'fixed',
  still_exists = 'still_exists',
  limitation = 'limitation',
  flag = 'inspectify_flag',
  monitor = 'monitor',
  repair = 'repair',
  safety = 'safety',
}

type Issue = {
  id: string
  systemId: string
  subsystemItem: string
  type: IssueType
  title: string
  reInspectionStatus: ReInspectionStatus | null
  severity?: ReportSeverityType
  systemName?: string
  subsystemName?: string
  systemGroupName?: string;
};

type GroupedDeficienciesBySeverity = {
  [IssueType.safety]: Issue[]
  [IssueType.repair]: Issue[]
  [IssueType.monitor]: Issue[]
  [IssueType.flag]: Issue[]
  [IssueType.fixed]: Issue[]
};

const IssueSummaryTable: React.FC<Props> = ({ report, systems, filter, hideDeficiencies }) => {
  const orderUnits = useTypedSelector((reduxState) => selectWorksOrderUnits(reduxState));
  const isMultiUnit = orderUnits && (Object.keys(orderUnits).length > 1);

  const getIssueTypeForDefect = (deficiency: ReportDeficiency): IssueType => (
    deficiency.reInspectionStatus === ReInspectionStatus.Fixed
      ? IssueType.fixed
      : (deficiency.severity || IssueType.limitation) as IssueType
  );

  const getIssueLabel = (type: IssueType) => {
    switch (type) {
      case IssueType.flag:
        return 'Inspectify flag';
      default:
        return type;
    }
  };

  const renderIcon = (type: IssueType) => {
    let icon = null;
    switch (type) {
      case IssueType.safety:
        icon = <SafetyIcon />;
        break;
      case IssueType.repair:
        icon = <RepairIcon />;
        break;
      case IssueType.monitor:
        icon = <MonitorIcon />;
        break;
      case IssueType.flag:
        icon = <FlagIcon />;
        break;
      case IssueType.fixed:
        icon = <FixedIcon />;
        break;
      case IssueType.limitation:
        icon = <LimitationIcon />;
        break;
      default:
        return null;
    }

    return (
      <div className={styles[`icon--${type}`]}>
        <div>{icon}</div>
      </div>
    );
  };

  const renderCount = (count: number, type: IssueType) => (
    count ? (
      <div className={styles[`badge--${type}`]}>
        <div>{renderIcon(type)} {count} {getIssueLabel(type)}</div>
      </div>
    ) : null
  );

  const data = useMemo(() => (
    systems.length ? systems.reduce((issues: Issue[], system) => {
      const subsystems = sortReportSystemOrSubsystemByDisplayIndex(
        system.reportSubsystemIds.reduce((acc2: ReportSubsystem[], id: string) => (
          report.subsystems[id] ? [...acc2, report.subsystems[id]] : acc2
        ), []),
      ) as ReportSubsystem[];

      const systemIssues = subsystems.reduce((list, subsystem) => {
        const groupedDefects: GroupedDeficienciesBySeverity = subsystem.reportDeficiencyIds.reduce(
          (grouped: GroupedDeficienciesBySeverity, id) => {
            if (filter === ReportDeliveryFilter.MediaOnly) {
              return grouped;
            }

            const temp: GroupedDeficienciesBySeverity = { ...grouped };
            if (report.deficiencies[id]) {
              const deficiency = report.deficiencies[id];
              const type = getIssueTypeForDefect(deficiency);

              if (hideDeficiencies) {
                return temp;
              }
              if (filter === ReportDeliveryFilter.FixedDefectsOnly && type !== IssueType.fixed) {
                return temp;
              }
              if (filter === ReportDeliveryFilter.RemainingDefectsOnly
                  && type === IssueType.fixed) {
                return temp;
              }

              if (type === IssueType.still_exists || type === IssueType.limitation) return temp;
              temp[type] = [
                ...temp[type],
                {
                  id,
                  systemId: system.id,
                  severity: deficiency.severity,
                  subsystemItem: subsystem.id,
                  type,
                  title: deficiency.title,
                  reInspectionStatus: deficiency.reInspectionStatus as ReInspectionStatus,
                  systemGroupName: isMultiUnit ? report?.systemGroups?.[system.reportSystemGroupId]?.name : '',
                },
              ];
            }
            return temp;
          },
          {
            [IssueType.fixed]: [],
            [IssueType.safety]: [],
            [IssueType.repair]: [],
            [IssueType.monitor]: [],
            [IssueType.flag]: [],
          },
        );

        const limitations = subsystem.reportLimitationIds.reduce((ls: Issue[], id) => {
          if (filter === ReportDeliveryFilter.MediaOnly
             || filter === ReportDeliveryFilter.FixedDefectsOnly) {
            return ls;
          }
          if (report.limitations[id]) {
            const { title } = report.limitations[id];
            return [...ls, {
              id,
              systemId: system.id,
              subsystemId: subsystem.id,
              type: IssueType.limitation,
              title,
              systemGroupName: isMultiUnit ? report?.systemGroups?.[system.reportSystemGroupId]?.name : '',
            }] as Issue[];
          }
          return ls;
        }, []);

        const subsystemIssues = [
          ...(groupedDefects?.[IssueType.fixed] || []),
          ...(groupedDefects?.[IssueType.safety] || []),
          ...(groupedDefects?.[IssueType.repair] || []),
          ...(groupedDefects?.[IssueType.monitor] || []),
          ...limitations,
          ...(groupedDefects?.[IssueType.flag] || []),
        ];

        if (subsystemIssues.length) subsystemIssues[0].subsystemName = subsystem.name;

        return [...list, ...subsystemIssues];
      }, []);

      if (systemIssues.length) systemIssues[0].systemName = system.name;
      return [...issues, ...systemIssues];
    }, []) : []
  ), [systems, filter]);

  const counts = useMemo(() => {
    const defaultValue = {
      [IssueType.fixed]: 0,
      [IssueType.safety]: 0,
      [IssueType.repair]: 0,
      [IssueType.monitor]: 0,
      limitation: 0,
      [IssueType.flag]: 0,
    };
    return data ? data.reduce((acc, { type }) => {
      if (type === IssueType.still_exists) return acc;
      return ({ ...acc, [type]: acc[type] + 1 });
    }, defaultValue) : defaultValue;
  }, [data, filter]);

  const rows = useMemo(() => (
    data ? data.map(({
      id, systemName, subsystemName, title, type, systemGroupName,
    }) => (
      <tr key={id} className={styles.row}>
        <td className={styles.definition}>{systemName}</td>
        <td className={styles.definition}>{subsystemName}</td>
        {systemGroupName ? <td className={styles.definition}>{systemGroupName}</td> : null}
        <td className={styles.definition}>
          <a className={styles.issueLink} href={`#${id}`}>{renderIcon(type)} {title}</a>
        </td>
      </tr>
    )) : []
  ), [data]);

  if (rows.length === 0) return null;

  return (
    <div className={styles.container}>
      {Object.keys(counts).map((key) => (
        <React.Fragment key={`${key}-${(counts as any)[key]}`}>
          {renderCount((counts as any)[key], key as IssueType)}
        </React.Fragment>
      ))}
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.definition}>System</th>
            <th className={styles.definition}>Subsystem</th>
            {isMultiUnit ? <th className={styles.definition}>Location</th> : null}
            <th className={styles.definition}>Issues identified</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );
};

export default IssueSummaryTable;
