import { createSelector } from '@reduxjs/toolkit';
import { IdRef } from 'marketplace-common';
import { MarketplaceFrontendReduxState as ReduxState } from '../reducers';

export const selectSplitIO = (state: ReduxState) => state.splitio;
export const selectLayout = (state: ReduxState) => state.layout;
export const selectActive = (state: ReduxState) => state.active;

export const selectTeams = (state: ReduxState) => state?.teams;
export const selectTeam = (state: ReduxState, teamToken: IdRef) => state?.teams?.[teamToken];

export const selectProperties = (state: ReduxState) => state.properties;

export const selectReports = (state: ReduxState) => state.reports;
export const selectReport = (state: ReduxState, reportId: string) => (
  state.reports?.[reportId]
);

export const selectTemplates = (state: ReduxState) => state.templates;

export const selectWorks = (state: ReduxState) => state.works;
export const selectWorksOrders = (state: ReduxState) => state.works?.orders;
export const selectWorksOrder = (state: ReduxState, orderId: number) => (
  state.works?.orders?.[orderId]
);
export const selectWorksOrderUnits = (state: ReduxState) => state.works?.orderUnits;
export const selectWorksOrderStakeholders = (state: ReduxState) => state.works?.orderStakeholders;
export const selectWorksProperties = (state: ReduxState) => state.works?.properties;
export const selectWorksOrderProperty = createSelector(
  selectWorksOrder,
  selectWorksProperties,
  (order, properties) => properties?.[order?.propertyId],
);
export const selectWorksPermits = (state: ReduxState) => state.works?.permits;
export const selectWorksPropertyPermits = createSelector(
  selectWorksOrderProperty,
  selectWorksPermits,
  (property, permits) => (property ? property.permitIds.map((permitId) => permits[permitId]) : []),
);

export const selectWorksReports = (state: ReduxState) => state.works?.reports;
export const selectInspection = (state: ReduxState, inspectionId: string) => state.works?.inspections[inspectionId];
export const selectWorksInspectionProposals = (state: ReduxState) => (
  state.works?.inspectionProposals
);

export const selectRehab = (state: ReduxState) => state.rehab;
export const selectRehabProject = (state: ReduxState, projectId: string) => (
  state.rehab.projects?.[projectId]
);
export const selectRehabTeamMarkets = (state: ReduxState) => state?.rehab?.rehabTeamMarkets;
export const selectRehabTeamCustomSystems = (state: ReduxState) => state?.rehab?.rehabTeamCustomSystems;

export const selectWorkHistory = (state: ReduxState) => state.rehab?.workHistory;
export const selectProjectWorkHistory = (state: ReduxState, projectId: string) => (
  state.rehab?.workHistory?.[projectId]
);

export const selectRehabWorkItemAttachments = (state: ReduxState) => (
  state.rehab?.workItemAttachments
);

export const selectWorkItems = (state: ReduxState) => state.rehab?.workItems;
export const selectWorkItem = (state: ReduxState, workItemId: string) => (
  state.rehab?.workItems?.[workItemId]
);
export const selectWorkItemAttachments = createSelector(
  selectWorkItem,
  selectRehabWorkItemAttachments,
  (workItem, workItemAttachments) => (
    Object.values(workItemAttachments).filter((att) => att.workItemId === workItem?.id)
  ),
);

export const selectProjectContributors = createSelector(
  [selectWorkItems, (_: ReduxState, projectId: string) => projectId],
  (workItems, projectId) => Object.values(workItems || {})
    .filter((workItem) => workItem.projectId === projectId)
    .flatMap((workItem) => workItem.workItemContributors || []),
);

export const selectUniqueContributorNames = createSelector(
  [selectProjectContributors],
  (contributors) => Array.from(new Set(contributors.map((contributor) => contributor.name))),
);

export const selectWorkItemAssociations = createSelector(
  selectWorkItem,
  (state: ReduxState) => state.rehab?.workItemAssociations,
  (workItem, workItemAssociations) => (
    Object.values(workItemAssociations).filter((ass) => ass.workItemId === workItem?.id)
  ),
);

export const selectTemplateWorkItems = (state: ReduxState) => state.rehab?.templateWorkItems;
export const selectTemplateWorkItemPricings = (state: ReduxState) => (
  state.rehab?.templateWorkItemPricings
);
