import { gql } from '@apollo/client';
import { FULL_CONTRACTOR_FIELDS } from '../queries';

export const CREATE_INSPECTOR_ONBOARDING = gql`
  mutation($input: CreateInspectorOnboardingInput!) {
    createInspectorOnboarding(input: $input) {
      inspectorOnboarding {
        id
        completedStep
      }
    }
  }
`;

export const UPDATE_INSPECTOR_ONBOARDING = gql`
  ${FULL_CONTRACTOR_FIELDS}
  mutation($input: UpdateInspectorOnboardingInput!) {
    updateInspectorOnboarding(input: $input) {
      inspectorOnboarding {
        id
        appLoggedIn
        businessInformationAdded
        canSetPassword
        completedStep
        contractor {
          ...FullContractorFields
        }
        createdAt
        misaSigned
        onboardingTrackerDismissedAt
        paymentDetailsFilled
        status
        updatedAt
        usersAdded
      }
    }
  }
`;