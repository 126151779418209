import { gql } from '@apollo/client';

const ORDER_FRAGMENT = gql`
  fragment OrderFields on OrderType {
    accessInformation
    id
    orderUnits {
      id
      name
    }
    inspections {
      createdAt
      id
      inspectionScopeNumber
      inspectionReports {
        id
        inspection {
          id
        }
        report {
          createdAt
          id
          inspectionReports {
            inspection {
              id
            }
          }
          publishedAt
          reportSystemGroups {
            id
            name
          }
          reportSystems {
            id
          }
          templateReportIds
          updatedAt
        }
      }
      inspectionType
      customerFacingName
      inspectionWarranty {
        expirationDate
        id
        inspectionId
        startDate
        status
      }
      orderId
      scheduledProposal {
        availability
        contractorFee
        contractorId
        contractorPaidAt
        contractorRepresentative
        durationHour
        id
        inspectionId
        isGoAnytime
        totalFee
      }
      scheduledProposalId
      status
      templateReportId
      isPaid
    }
    inspectionSchedulingDeadline
    isPaywall
    orderStakeholders {
      email
      id
      isClient
      name
      phone
      stakeholderType
    }
    property {
      address
      bathrooms
      bedrooms
      city
      country
      homeType
      id
      photo {
        cdnUrl
      }
      state
      street
      street2
      timezone
      totalSquareFeet
      yearBuilt
      zipCode
      permits {
        id
        description
        fees
        fileDate
        finalDate
        issueDate
        jurisdiction
        number
        status
        jobValue
        permitSubtype
        permitType
        source
        status
        shovelsContractorId
        shovelsPermitId
        createdAt
        updatedAt
        permitContractorId
        permitContractor {
          id
          businessName
          city
          email
          license
          name
          phone
          state
          street
          streetNo
          zipcode
          shovelsContractorId
          createdAt
          updatedAt
        }
      }
    }
    propertyId
    readOnlyToken
    status
    teamExternalId
    communityName
    floorPlanName
    teamId
    team {
      drawSummaryTableDisplay
      sowSummaryTableDisplay
    }
  }
`;

export const ORDER_BY_TOKEN = gql`
  ${ORDER_FRAGMENT}

  query orderByToken($token: String!) {
    order: orderByToken(token: $token) {
      ...OrderFields
    }
  }
`;

const TEAM_FRAGMENT = gql`
  fragment OrderFields on OrderType {
    team {
      drawSummaryTableDisplay
      sowSummaryTableDisplay
    }
  }
`

export const ORDER_BY_TOKEN_TEAM = gql`
  ${TEAM_FRAGMENT}
  query orderByToken($token: String!) {
    order: orderByToken(token: $token) {
      ...OrderFields
    }
  }
`;

// order fragment
export const ORDER_PERMIT_FRAGMENT = gql`
    query orderByToken($token: String!) {
        order: orderByToken(token: $token) {
            accessInformation
            id
            inspections {
                id
                inspectionReports {
                    id
                    report {
                        id
                        inspectionDocuments {
                            id
                            updatedAt
                            document {
                                id
                                filename
                                url
                                cdnUrl
                            }
                        }
                    }
                }
            }
            property {
                id
                photo { cdnUrl }
                state
                street
                street2
                zipCode
                permits {
                    id
                    description
                    fees
                    fileDate
                    finalDate
                    issueDate
                    jurisdiction
                    number
                    status
                    jobValue
                    permitSubtype
                    permitType
                    source
                    status
                    shovelsContractorId
                    shovelsPermitId
                    createdAt
                    updatedAt
                    permitContractorId
                    permitContractor {
                        id
                        businessName
                        city
                        email
                        license
                        name
                        phone
                        state
                        street
                        streetNo
                        zipcode
                        shovelsContractorId
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    }
`;
