import React from 'react';
import '../../styles.scss';
import Checkbox from '../../../../components/Checkbox';
import { CertificationLicenseEnum, CertificationsSectionProps } from './types';
import InformationSection from '../../../../components/InformationSection';
import { ContractorTypeEnum } from '../../../../types';
import { OnboardingFormQuestions } from '../../../Onboarding/OnboardingFormPage/types';

const CertificationsSection: React.FC<CertificationsSectionProps> = ({
  sectionRef,
  title,
  instructions,
  onboardingContractorType,
  stateLicenseNumber,
  setStateLicenseNumber,
  ashiLicenseNumber,
  setAshiLicenseNumber,
  internachiLicenseNumber,
  setInternachiLicenseNumber,
  otherLicenseNumber,
  setOtherLicenseNumber,
  inspectorLicenseHeld,
  setInspectorLicenseHeld,
  contractorLicenseHeld,
  setContractorLicenseHeld,
  realEstateLicenseHeld,
  setRealEstateLicenseHeld,
}) => (
  <InformationSection
    title={title}
    sectionRef={sectionRef}
  >
    {onboardingContractorType === ContractorTypeEnum.Inspector ? (
      <>
        {instructions?.length > 0 && (
          <div className="userProfile__section--large">
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label" style={{ whiteSpace: 'normal' }}>
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {OnboardingFormQuestions.Required}
                  </div>
                  {OnboardingFormQuestions.InspectorLicense}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {CertificationLicenseEnum.State}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={stateLicenseNumber}
              name="state_license_number"
              onChange={(e) => setStateLicenseNumber(e.target.value)}
            />
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {CertificationLicenseEnum.Ashi}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={ashiLicenseNumber}
              name="ashi_license_number"
              onChange={(e) => setAshiLicenseNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {CertificationLicenseEnum.InterNachi}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={internachiLicenseNumber}
              name="internachi_license_number"
              onChange={(e) => setInternachiLicenseNumber(e.target.value)}
            />
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {CertificationLicenseEnum.Other}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={otherLicenseNumber}
              name="other_license"
              onChange={(e) => setOtherLicenseNumber(e.target.value)}
            />
          </div>
        </div>
      </>
    ) : (
      <div className="userProfile__section__inputRow">
        <div className="userProfile__section__inputRow__inputContainer">
          <div className="questionContainer">
            <span className="questionText">{OnboardingFormQuestions.SurveyorLicense}</span>&nbsp;
            <span className="questionTextSecondary">{OnboardingFormQuestions.SurveyorLicenseSelect}</span>
          </div>
          <Checkbox
            isChecked={inspectorLicenseHeld}
            toggle={() => setInspectorLicenseHeld(!inspectorLicenseHeld)}
            label={OnboardingFormQuestions.Inspector}
          />
          <Checkbox
            isChecked={contractorLicenseHeld}
            toggle={() => setContractorLicenseHeld(!contractorLicenseHeld)}
            label={OnboardingFormQuestions.Contractor}
          />
          <Checkbox
            isChecked={realEstateLicenseHeld}
            toggle={() => setRealEstateLicenseHeld(!realEstateLicenseHeld)}
            label={OnboardingFormQuestions.Realtor}
          />
        </div>
      </div>
    )}

  </InformationSection>
);

export default CertificationsSection;
