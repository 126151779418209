import React, { useContext, useMemo, useState } from 'react';
import {
  BETTER_INSPECT_PHONE_NUMBER_FORMATTED,
  BETTER_INSPECT_PHONE_NUMBER_LINK,
  IdRef,
} from 'marketplace-common';
import { Container } from 'react-bootstrap';
import cn from 'classnames';
import Modal from 'react-modal';
import Filter from 'haven-design-system/build/assets/svgs/action/filter-view.svg?component';
import RehabPreview from '../../../../assets/images/macbookProRehabDemo.png';
import InspectifyLogo from '../../../../assets/inspectify/Inspectify_Logo_Color.svg';
import BetterLogo from '../../../../assets/better/BetterInspect.svg';
import { Tabs } from '../../../../components/Tabs';
import HubspotContactForm from '../../../../components/Hubspot/HubspotContactForm';
import { RehabTabs } from '../../types';
import RehabMenuContainer from '../RehabMenuContainer/RehabMenuContainer';
import { useCurrentUser } from '../../../../utils/user';
import { ReportPropertyImage } from '../../../../components/PropertyImage/PropertyImage';
import MenuItems from './MenuItems';
import {
  RehabSignupModal,
  RehabSignupModalStyle,
  RehabSignupActions,
  RehabSignupPic,
  Logo,
  MainText,
  SubText,
  RehabForm,
  LoginForm,
  LoginLink,
  LoginText,
  LeaveModalText,
} from '../../../Reports/components/Menu/styles';
import styles from '../../../Reports/components/Menu/styles.module.scss';
import { RehabToolContext } from '../../context';
import { useTypedSelector } from '../../../../redux/store';
import { selectProperties, selectRehab, selectRehabTeamMarkets } from '../../../../redux/selectors';
import ReactSelect from '../../../../components/ReactSelect';

interface Props {
  toggleReportFilterModal: (value: boolean) => void;
  publishProject: () => void;
  handleChangeTeamMarket: (teamMarketId: IdRef) => void;
}

const Menu: React.FC<Props> = ({
  toggleReportFilterModal,
  publishProject,
  handleChangeTeamMarket,
}) => {
  const currentUser = useCurrentUser();
  const properties = useTypedSelector(selectProperties);
  const rehabTeamMarkets = useTypedSelector(selectRehabTeamMarkets);
  const rehab = useTypedSelector(selectRehab);
  const isBetterInspect = useMemo(() => window.location.hostname.includes('betterinspect'), []);
  const {
    state, set, setWorkItemModalOptions,
  } = useContext(RehabToolContext);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const project = useMemo(() => rehab.projects[state.projectId], [rehab]);

  return (
    <Container className={styles.container}>
      <Modal
        isOpen={isSignupModalOpen}
        onRequestClose={() => setIsSignupModalOpen(false)}
        ariaHideApp={false}
        style={RehabSignupModalStyle}
        preventScroll={false}
      >
        <RehabSignupModal>
          <RehabSignupActions>
            <Logo src={InspectifyLogo} alt="Inspectify" />
            <MainText>Interested in property rehab?</MainText>
            <SubText>
              Please provide your email and we&apos;ll reach out
              with more information on our Rehab Tool.
            </SubText>
            <RehabForm>
              <HubspotContactForm />
            </RehabForm>
            {!currentUser && (
              <LoginForm>
                <LoginText>Already have an Inspectify account?</LoginText>
                <LoginLink
                  onClick={() => {
                    window.location.assign(`/sign_in?from=${window.location.pathname}`);
                  }}
                >
                  Login
                </LoginLink>
              </LoginForm>
            )}
            <LeaveModalText onClick={() => setIsSignupModalOpen(false)}>
              Go back to my inspection report.
            </LeaveModalText>
          </RehabSignupActions>
          <RehabSignupPic src={RehabPreview} alt="RehabPreview" />
        </RehabSignupModal>
      </Modal>

      <img
        src={isBetterInspect ? BetterLogo : InspectifyLogo}
        alt={InspectifyLogo ? 'Better Inspect' : 'Inspectify'}
        className={cn(styles.logo, InspectifyLogo ? styles.betterLogo : null)}
      />

      <div className={styles.propertyImageAndButtonSection}>
        {properties && properties[state.propertyId] && (
          <ReportPropertyImage property={properties[state.propertyId]} />
        ) }

        {state.tab !== RehabTabs.Rehab && (
          <button
            id="filter-report"
            type="button"
            className={styles.buttonOutlined}
            onClick={() => toggleReportFilterModal(true)}
          >
            <Filter /> Filter: {state.filter}
          </button>
        )}

        {project?.teamMarketId
         && !state.isRehabReadOnly
         && Object.keys(rehabTeamMarkets).length > 0
         && (
         <div className={styles.selectMarketContainer}>
           <ReactSelect
             value={{
               label: state.isUpdatingProject ? '(updating...)' : `${rehabTeamMarkets[project.teamMarketId]?.name}, ${rehabTeamMarkets[project.teamMarketId]?.stateAbbreviation}`,
               value: project.teamMarketId,
             }}
             onChange={(selectedOption) => handleChangeTeamMarket(selectedOption.value)}
             options={(Object.values(rehabTeamMarkets) || []).map(
               ({ name, stateAbbreviation, id }) => ({ label: `${name}, ${stateAbbreviation}`, value: id }),
             )}
             placeholder="Select the market"
             width="100%"
           />
         </div>
         )}
      </div>

      {state.isRehabReadOnly ? (
        <RehabMenuContainer
          isRehabReadOnly={state.isRehabReadOnly}
          teamId={state.teamId}
          projectId={state.projectId}
          isPublishing={state.isPublishing}
          handlePublishRehabProject={publishProject}
        />
      ) : (
        <>
          {state.report && (
            <Tabs
              tab={state.tab}
              tabs={Object.values(RehabTabs)}
              setTab={(value) => set('tab', value)}
              isFullWidth
            />
          )}
          {(state.tab === RehabTabs.Report) && (
          <>
            {Object.keys(state.groupedReportSystemsByName) && (
              <MenuItems />
            )}

            {isBetterInspect && (
              <div className={styles.menuPoweredByInspectify}>
                <div className={styles.menuPoweredBytext}>Powered by</div>
                <a href="https://www.inspectify.com">
                  <img src={InspectifyLogo} alt="Inspectify" />
                </a>
              </div>
            )}

            {isBetterInspect && (
              <div className={styles.menuInspectifyContactInfo}>
                <a
                  href="mailto:betterinspect@inspectify.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  betterinspect@inspectify.com
                </a>
              </div>
            )}

            {InspectifyLogo && (
              <div className={styles.menuInspectifyContactInfo}>
                <a
                  href={BETTER_INSPECT_PHONE_NUMBER_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  {BETTER_INSPECT_PHONE_NUMBER_FORMATTED}
                </a>
                <br />
              </div>
            )}
          </>
          )}
          {state.tab === RehabTabs.Rehab && (
            <RehabMenuContainer
              isRehabReadOnly={state.isRehabReadOnly}
              teamId={state.teamId}
              projectId={state.projectId}
              isPublishing={state.isPublishing}
              handleWorkItemModal={setWorkItemModalOptions}
              handlePublishRehabProject={publishProject}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Menu;
