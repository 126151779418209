import React, { useContext, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { IdRef } from 'marketplace-common';
import BetterLogo from '../../../assets/better/BetterInspect.svg';
import InspectifyLogo from '../../../assets/inspectify/Inspectify_Logo_Color.svg';
import { FooterPrint, FooterPrintText, HeaderPrint } from '../../../components/PDF/styles';
import PageErrorIndicator from '../../../components/PageError';
import { PermitsBanner } from '../../../components/Permits/Permits/PermitsBanner';
import Spinner from '../../../components/Spinner';
import { useOrderByToken } from '../../../hooks/order';
import { selectWorks, selectWorksPropertyPermits } from '../../../redux/selectors';
import { useTypedSelector } from '../../../redux/store';
import { UserContractor } from '../../../types';
import { ReportDeliveryFilter } from '../../../types/models';
import { getAddressLabel, getInspectionLabelOnlyDate } from '../../../utils/report';
import { includesDrawInspection, includesSowInspection } from '../../../utils/tables';
import { toCapitalizedSentence } from '../../../utils/string';
import DrawInspectionBudgetSummary from '../components/DrawInspectionBudgetSummary';
import IssueSummaryTable from '../components/IssueSummaryTable';
import Paywall from '../components/Paywall';
import { SowInspectionSummary } from '../components/SowInspectionSummary';
import StateCompliance from '../components/StateCompliance';
import SystemDetails from '../components/SystemDetails';
import { ReportContext } from '../context';
import Cover from './components/Cover';
import styles from './styles.module.scss';

interface Props {
  imageWidth: number
  loading: boolean
  user: UserContractor
}

const ReportsPdfPageLoader: React.FC<Props> = ({
  imageWidth,
  loading,
  user,
}) => {
  const { state } = useContext(ReportContext);
  const {
    selectedReport,
    sortedReportSystems,
    orderToken,
    property,
    order,
    inspectionDate,
    clientStakeholder,
    isAppliancesOnly,
  } = state;

  const works = useTypedSelector(selectWorks);
  const permits = useTypedSelector(
    (reduxState) => selectWorksPropertyPermits(reduxState, state.orderId as number),
  );

  const inspectionType = useMemo(() => {
    const inspectionIds = state?.selectedReport?.report?.inspectionIds || [];
    const inspectionTypes = inspectionIds.reduce((acc: string[], id: IdRef) => {
      const insType = works?.inspections?.[id]?.inspectionType;
      if (insType) acc.push(insType);
      return acc;
    }, []);
    const sentence = toCapitalizedSentence(inspectionTypes);
    return `${sentence} Report - ${state.property?.street || ''}`;
  }, [state?.selectedReport?.report, works.inspections]);

  const showPermitData = useMemo(() => (
    selectedReport?.report?.includePermitData && Object.keys(permits).length > 0
  ), [permits, selectedReport?.report?.includePermitData]);

  const inspectifyAdmin = !!user?.currentUser?.admin;
  const isBetterInspect = useMemo(() => window.location.hostname.includes('betterinspect'), []);

  const {
    displayWithTotal,
    displaySowSummary,
    isDrawSummaryHidden,
  } = useOrderByToken({ token: orderToken });

  const containsDrawInspections = includesDrawInspection(selectedReport);
  const containsSowInspections = includesSowInspection(selectedReport);

  const handleFilterValue = isAppliancesOnly ? ReportDeliveryFilter.AppliancesOnly
    : ReportDeliveryFilter.FullReport;

  if (loading) return <Spinner style={{ width: '100vw', height: '100vh' }} />;

  return (
    <>
      <Container fluid className={styles.reportsPage}>
        <table>
          <thead>
            <tr>
              <td>
                <HeaderPrint className="hideOnFirstPage">
                  {isBetterInspect
                    ? <img src={BetterLogo} alt="Better Inspect" className={styles.betterLogo} />
                    : <img src={InspectifyLogo} alt="Inspectify" />}
                  <a
                    style={{ display: 'block', padding: 16 }}
                    href={`${window.location.origin}/orders/${orderToken}/reports?report_id=${state.selectedReportId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View web report
                  </a>

                  <div className="float-right">
                    {isBetterInspect && (
                      <>
                        <div className={styles.menuPoweredBytext}>Powered by</div>
                        <a href="https://www.inspectify.com"><img src={InspectifyLogo} alt="Inspectify" /></a>
                      </>
                    )}
                  </div>
                </HeaderPrint>
              </td>
            </tr>
          </thead>
          <tbody className={styles.body}>
            <tr>
              <td>
                <Container fluid>
                  <Cover
                    property={property}
                    teamExternalId={order?.teamExternalId}
                    communityName={order?.communityName}
                    floorPlanName={order?.floorPlanName}
                    contractor={state.contractor}
                    scheduledDate={inspectionDate}
                    completedDate={selectedReport?.report?.publishedAt}
                    clientName={clientStakeholder?.name}
                    inspectionType={inspectionType}
                  />

                  {!isAppliancesOnly ? (
                    <IssueSummaryTable
                      report={selectedReport}
                      systems={sortedReportSystems}
                      noDeficienciesRequired={state.noDeficienciesRequired}
                    />
                  ) : null}

                  {showPermitData && (
                    <PermitsBanner permits={permits} />
                  )}

                  {(containsDrawInspections && !isDrawSummaryHidden)
                    ? (
                      <DrawInspectionBudgetSummary
                        displayWithTotal={displayWithTotal}
                        isPrint
                      />
                    )
                    : null}

                  {(containsSowInspections && displaySowSummary) ? (
                    <SowInspectionSummary report={selectedReport} />
                  ) : null}

                  {Object.keys(state?.groupedReportSystemsByName || {}).map((group, index) => (
                    <SystemDetails
                      report={selectedReport}
                      groupedReportSystemsByName={state.groupedReportSystemsByName}
                      key={`${group}-${index + 1}`}
                      systemName={group}
                      imageWidth={imageWidth}
                      index={index}
                      filter={handleFilterValue}
                      isPrint
                      allowCopyLongLiveUrl={false}
                      isAppliancesOnly={isAppliancesOnly}
                      inspectifyAdmin={inspectifyAdmin}
                      inspectionWarranty={state.inspectionWarranty}
                      isInspectionWarrantyExpired={state.isInspectionWarrantyExpired}
                      noDeficienciesRequired={state.noDeficienciesRequired}
                    />
                  ))}

                  {selectedReport?.report?.complianceText && (
                    <StateCompliance
                      stateComplianceText={selectedReport.report.complianceText}
                    />
                  )}
                </Container>

                {state.isPaywalled && (
                  <>
                    <div className={styles.paywallSpacer} />
                    <Paywall order={order} contractor={state.contractor} />
                  </>
                )}

                {state.reportQueryError && (
                  <PageErrorIndicator errorType={state.reportQueryError} />
                )}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <FooterPrint>
                  <FooterPrintText>
                    {getAddressLabel(property)} {inspectionDate && `- ${getInspectionLabelOnlyDate(inspectionDate)}`}
                  </FooterPrintText>
                </FooterPrint>
              </td>
            </tr>
          </tfoot>
        </table>
      </Container>

      {state.displayPrintReady && (<span id="printReady" />)}
    </>
  );
};

export default ReportsPdfPageLoader;
