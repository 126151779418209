import { OnboardingCompletedStep } from '../types';

export const handleOnboardingPageNavigation = (
  completedStep: OnboardingCompletedStep,
  canSetPassword: boolean,
  onboardingContractorType: string,
  onboardingId: string,
  history: any,
) => {
  switch (completedStep) {
    case OnboardingCompletedStep.FormSubmitted:
      if (canSetPassword === true) {
        history.push(`/onboarding/${onboardingContractorType}/${onboardingId}/password`);
      }
      if (canSetPassword === false) {
        history.push('/sign_in?next=/dashboard');
      }
      break;
    case OnboardingCompletedStep.PasswordSet:
      if (canSetPassword === false) {
        history.push('/sign_in?next=/dashboard');
      }
      break;
    default:
      break;
  }
};

export const calculateSliderWidth = (
  isMobile: boolean,
  serviceAreaRadiusInMiles: number,
) : number => {
  if (isMobile) {
    return serviceAreaRadiusInMiles - 25;
  }
  return ((serviceAreaRadiusInMiles * 450) / 200) - 25;
};
