import React, {
  useEffect, useState, useReducer, useRef, useContext,
} from 'react';
import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import { usePlacesWidget } from 'react-google-autocomplete';
import { UPDATE_CONTRACTOR } from '../../../graphql/mutations';
import { captureException } from '../../../utils/error';
import { ContractorFileAttachment, UpdateContractorReturnDataType } from '../../../types';
import { ContractorState } from './types';
import { uploadContractorFile } from '../../../utils/file_upload';
import { EventName, track } from '../../../utils/analytics';
import { InspectorDashboardContext } from '../context';
import ContractorBusinessDetails from './ContractorBusinessDetails';

const reducer = (state: ContractorState, action: any): ContractorState => {
  switch (action.type) {
    case 'serviceAreaRadiusMileage':
      return {
        hasStateChanged: true,
        contractor: {
          ...state.contractor,
          serviceAreaRadiusMileage: parseFloat(action.payload),
        },
      };
    case 'hasSupra':
      return {
        hasStateChanged: true,
        contractor: {
          ...state.contractor,
          hasSupra: action.payload === 'true',
        },
      };
    case 'resetHasStateChanged':
      return {
        ...state,
        hasStateChanged: false,
      };
    default:
      return {
        hasStateChanged: true,
        contractor: {
          ...state.contractor,
          [action.type]: action.payload,
        },
      };
  }
};

const ContractorBusinessDetailsLoader: React.FC = () => {
  const { state: contextState, setContractor } = useContext(InspectorDashboardContext);
  const { contractor } = contextState;
  const [state, dispatch] = useReducer(
    reducer,
    { hasStateChanged: false, contractor: { ...contractor } },
  );

  const [update, { data, loading, error }] = useMutation<UpdateContractorReturnDataType>(
    UPDATE_CONTRACTOR,
  );

  const [certFiles, setCertFiles] = useState([] as ContractorFileAttachment[]);
  const [contractFiles, setContractFiles] = useState([] as ContractorFileAttachment[]);
  const [errorEditingInfo, setErrorEditingInfo] = useState(null);
  const [loadingCert, setLoadingCert] = useState(false);
  const sectionTitleRef = useRef(null);
  const [indicateRequiredFields, setIndicateRequiredFields] = useState(false);

  const sortFiles = () => {
    if (contractor.fileAttachments) {
      const tempCert: ContractorFileAttachment[] = [];
      const tempContract: ContractorFileAttachment[] = [];
      contractor.fileAttachments.forEach((file: ContractorFileAttachment) => {
        if (file.category === 'insurance_certificate') {
          tempCert.push(file);
        } else if (file.category === 'contract') {
          tempContract.push(file);
        }
      });
      setCertFiles(tempCert);
      setContractFiles(tempContract);
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place: any) => {
      dispatch({ type: 'businessAddress', payload: place.formatted_address });
    },
    options: {
      types: ['address'],
      field: 'place_id',
    },
  });

  useEffect(() => {
    if (data?.updateContractor?.contractor) {
      setContractor({
        ...contractor,
        ...(data.updateContractor.contractor),
      });
    }
  }, [data?.updateContractor?.contractor]);

  useEffect(() => {
    sortFiles();
  }, [contractor.fileAttachments]);

  const handleClick = async () => {
    const { businessName, businessEmail, businessPhone } = state.contractor;
    // Check required fields
    if (businessName === '' || businessEmail === '' || businessPhone === '') {
      setIndicateRequiredFields(true);
      sectionTitleRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      setIndicateRequiredFields(false);

      try {
        await update({
          variables: {
            input: {
              id: state.contractor.id,
              businessName: state.contractor.businessName,
              businessSite: state.contractor.businessSite,
              certifications: state.contractor.certifications,
              inBusinessSince: state.contractor.inBusinessSince,
              businessContactName: state.contractor.businessContactName,
              businessPhone: state.contractor.businessPhone,
              businessEmail: state.contractor.businessEmail,
              schedulerName: state.contractor.schedulerName,
              schedulerPhone: state.contractor.schedulerPhone,
              schedulerEmail: state.contractor.schedulerEmail,
              businessAddress: state.contractor.businessAddress,
              sendAutomatedSms: state.contractor.sendAutomatedSms,
              serviceZipCodes: state.contractor.serviceZipCodes,
              serviceAreaRadiusMileage: state.contractor.serviceAreaRadiusMileage,
              hasSupra: state.contractor.hasSupra,
              reportWritingSoftware: state.contractor.reportWritingSoftware,
              ashiLicenseNumber: state.contractor.ashiLicenseNumber,
              internachiLicenseNumber: state.contractor.internachiLicenseNumber,
              stateLicenseNumber: state.contractor.stateLicenseNumber,
              otherLicenseNumber: state.contractor.otherLicenseNumber,
            },
          },
        });

        track(EventName.InspectorUpdatedDetails, { contractor });
      } catch (e: any) {
        captureException(e);
        setErrorEditingInfo(e);
      }
      dispatch({ type: 'resetHasStateChanged' });
    }
  };

  const addFile = async (uploadedFile: File, category: string) => {
    setLoadingCert(true);

    try {
      const res = await uploadContractorFile(uploadedFile, category, state.contractor.id);
      if (res) {
        track(EventName.InspectorUploadedFile, { contractorId: state.contractor.id, category });

        const resFileAttachment = camelizeKeys(res) as any;
        const fileAttachment = {
          ...resFileAttachment,
          attachment: {
            filename: resFileAttachment.filename,
            url: resFileAttachment.url,
          },
        } as ContractorFileAttachment;

        setContractor({
          ...contractor,
          fileAttachments: contractor.fileAttachments.concat([fileAttachment] as any),
        });
      }
    } catch (e: any) {
      setErrorEditingInfo(e.message);
    } finally {
      setLoadingCert(false);
    }
  };

  return (
    <ContractorBusinessDetails
      state={state}
      dispatch={dispatch}
      loading={loading}
      error={error}
      sectionTitleRef={sectionTitleRef}
      certFiles={certFiles}
      contractFiles={contractFiles}
      errorEditingInfo={errorEditingInfo}
      handleClick={handleClick}
      indicateRequiredFields={indicateRequiredFields}
      data={data as unknown as boolean}
      setErrorEditingInfo={setErrorEditingInfo}
      mapsRef={ref}
      addFile={addFile}
      loadingCert={loadingCert}
    />
  );
};

export default ContractorBusinessDetailsLoader;
