import { gql } from '@apollo/client';
import { REHAB_WORK_ITEM_FRAGMENT } from '../queries/rehab';

export const CREATE_REHAB_WORK_ITEM = gql`
  mutation CreateRehabWorkItem($input: CreateWorkItemInput!) {
    createRehabWorkItem(input: $input) {
      workItem {
        id
        systemName
        title
        details
        unit
        quantity
        pricePerUnit
        totalPrice
        projectId
        pricingExternalId
        tags
        workItemAssociations {
          id
          workItemId
          itemId
          itemType
          data
        }
        workItemAttachments {
          id
          workItemId
          workItemAssociationId
          s3ObjectKey
          attachmentUrl
          resizedUrls {
            url
            width
          }
        }
        templateWorkItemPricing {
          id
          pricingExternalId 
          details 
          unit 
          pricePerUnit
          lumpSumPrice
          teamMarketId
          templateWorkItem {
            id
            teamId
            systemName
            title
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const UPDATE_REHAB_WORK_ITEM = gql`
  ${REHAB_WORK_ITEM_FRAGMENT}

  mutation UpdateRehabWorkItem($input: UpdateWorkItemInput!) {
    updateRehabWorkItem(input: $input) {
      workItem {
        ...RehabWorkItemFields
      }
    }
  }
`;

export const UPDATE_REHAB_PROJECT = gql`
  mutation UpdateRehabProject($input: UpdateProjectInput!) {
    updateRehabProject(input: $input) {
      project {
        id
        name
        propertyId
        teamId
        reportId
        publishedAt
        teamMarketId
      }
    }
  }
`;

export const DELETE_REHAB_WORK_ITEM = gql`
  mutation DeleteRehabWorkItem($input: DeleteWorkItemInput!) {
    deleteRehabWorkItem(input: $input) {
      workItem {
        id
      }
    }
  }
`;

export const CREATE_REHAB_WORK_ITEM_ASSOCIATION = gql`
  mutation CreateRehabWorkItemAssociation($input: CreateWorkItemAssociationInput!) {
    createRehabWorkItemAssociation(input: $input) {
      workItemAssociation {
        id
        workItemId
        itemId
        itemType
        data
      }
    }
  }
`;

export const CREATE_REHAB_WORK_ITEM_ATTACHMENT = gql`
  mutation CreateRehabWorkItemAttachment($input: CreateWorkItemAttachmentInput!) {
    createRehabWorkItemAttachment(input: $input) {
      workItemAttachment {
        id
        workItemId
        workItemAssociationId
        s3ObjectKey
        attachmentUrl
        resizedUrls {
          url
          width
        }
      }
    }
  }
`;

export const DELETE_REHAB_WORK_ITEM_ATTACHMENT = gql`
  mutation DeleteRehabWorkItemAttachment($input: DeleteWorkItemAttachmentInput!) {
    deleteRehabWorkItemAttachment(input: $input) {
      workItemAttachment {
        id
      }
    }
  }
`;

export const DELETE_REHAB_WORK_ITEM_ASSOCIATION = gql`
  mutation DeleteRehabWorkItemAssociation($input: DeleteWorkItemAssociationInput!) {
    deleteRehabWorkItemAssociation(input: $input) {
      workItemAssociation {
        id
      }
    }
  }
`;

export const CREATE_REHAB_PROJECT = gql`
  mutation CreateRehabProject($input: CreateProjectInput!) {
    createRehabProject(input: $input) {
      project {
        id
      }
    }
  }
`;

export const PUBLISH_REHAB_PROJECT = gql`
  mutation PublishRehabProject($input: PublishProjectInput!) {
    publishRehabProject(input: $input) {
      project {
        id
        publishedAt
      }
    }
  }
`;

export const CREATE_REHAB_WORK_ITEM_CONTRIBUTOR = gql`
  mutation createRehabWorkItemContributor($input: CreateWorkItemContributorInput!) {
    createRehabWorkItemContributor(input: $input) {
      clientMutationId
      workItemContributor {
        id
        workItemId
        name
        amount
        createdAt
        updatedAt
      }
    }
  }
`

// TODO: refetch query rehabproject? 
export const UPDATE_REHAB_WORK_ITEM_CONTRIBUTOR = gql`
  mutation updateWorkItemContributor($input: UpdateWorkItemContributorInput!) {
    updateRehabWorkItemContributor(input: $input) {
      clientMutationId
      workItemContributor{
        id
        workItemId
        name
        amount
        createdAt
        updatedAt
      }
    }
  }
`

export const DELETE_REHAB_WORK_ITEM_CONTRIBUTOR = gql`
  mutation deleteRehabWorkItemContributor($input: DeleteWorkItemContributorInput!) {
    deleteRehabWorkItemContributor(input:$input) {
      clientMutationId
      workItemContributor{
        id
        workItemId
        name
        amount
        createdAt
        updatedAt
      }
    }
  }
`