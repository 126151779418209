import React from 'react';
import './styles.scss';
import { BusinessDetailsProps, BusinessDetailsSections, BusinessDetailsText } from './types';
import { OnboardingFormQuestions } from '../../Onboarding/OnboardingFormPage/types';
import InformationSection from '../../../components/InformationSection/InformationSection';
import { calculateSliderWidth } from '../../../utils/onboarding';
import BasicInformationInputs from '../../Onboarding/OnboardingFormPage/BasicInformationInputs';
import Checkbox from '../../../components/Checkbox';
import FormFile from '../../../components/FormElements/FormFile';
import { FileTypes } from '../../../types';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';

const BusinessDetails: React.FC<BusinessDetailsProps> = ({
  loading,
  certificationsRef,
  basicInformationRef,
  serviceableAreaRef,
  accessAndSoftwareRef,
  documentsRef,
  misaFiles,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  companyName,
  setCompanyName,
  website,
  setWebsite,
  phoneNumber,
  setPhoneNumber,
  streetAddress,
  setStreetAddress,
  city,
  setCity,
  state,
  setState,
  zipCode,
  setZipCode,
  showEmailError,
  setShowEmailError,
  showPhoneError,
  setShowPhoneError,
  showZipCodeError,
  setShowZipCodeError,
  addressRef,
  serviceAreaRadiusInMiles,
  setServiceAreaRadiusInMiles,
  hasSupra,
  setHasSupra,
  // TODO GA9-1507: will be added as part of Inspector Onboarding
  // hasSentriLock,
  // setHasSentriLock,
  isMobile,
  inspectorLicenseHeld,
  setInspectorLicenseHeld,
  contractorLicenseHeld,
  setContractorLicenseHeld,
  realEstateLicenseHeld,
  setRealEstateLicenseHeld,
  msaSigned,
  updateBusinessDetails,
  businessDetailsComplete,
  onboardingContractorType,
}) => (
  <div className="userManagement__manageUsers">
    <div className="userManagement__manageUsers__header">
      <div className="userManagement__manageUsers__header__text">
        Business Details
      </div>
      <Button
        onClick={() => updateBusinessDetails()}
        disabled={loading || !businessDetailsComplete()}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {loading ? <Spinner /> : 'Update'}
      </Button>
    </div>
    <div className="businessDetailsContainer">
      <InformationSection
        title={BusinessDetailsSections.BasicInformation}
        sectionRef={basicInformationRef}
      >
        <BasicInformationInputs
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          email={email}
          setEmail={setEmail}
          companyName={companyName}
          setCompanyName={setCompanyName}
          website={website}
          setWebsite={setWebsite}
          streetAddress={streetAddress}
          setStreetAddress={setStreetAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zipCode={zipCode}
          setZipCode={setZipCode}
          showEmailError={showEmailError}
          setShowEmailError={setShowEmailError}
          showPhoneError={showPhoneError}
          setShowPhoneError={setShowPhoneError}
          showZipCodeError={showZipCodeError}
          setShowZipCodeError={setShowZipCodeError}
          isMobile={isMobile}
          addressRef={addressRef}
          onboardingContractorType={onboardingContractorType}
        />
      </InformationSection>
      <div className="divider" />
      <InformationSection
        title={BusinessDetailsSections.Certifications}
        sectionRef={certificationsRef}
      >
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="questionContainer">
              <span className="questionText">{OnboardingFormQuestions.License}</span>&nbsp;
              <span className="questionTextSecondary">{OnboardingFormQuestions.LicenseSelect}</span>
            </div>
            <Checkbox
              isChecked={inspectorLicenseHeld}
              toggle={() => setInspectorLicenseHeld(!inspectorLicenseHeld)}
              label={OnboardingFormQuestions.Inspector}
            />
            <Checkbox
              isChecked={contractorLicenseHeld}
              toggle={() => setContractorLicenseHeld(!contractorLicenseHeld)}
              label={OnboardingFormQuestions.Contractor}
            />
            <Checkbox
              isChecked={realEstateLicenseHeld}
              toggle={() => setRealEstateLicenseHeld(!realEstateLicenseHeld)}
              label={OnboardingFormQuestions.Realtor}
            />
          </div>
        </div>
      </InformationSection>
      <div className="divider" />
      <InformationSection
        title={BusinessDetailsSections.ServiceableArea}
        sectionRef={serviceableAreaRef}
      >
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {OnboardingFormQuestions.Required}
              </div>
              <div className="questionContainer">
                <span className="questionText">{OnboardingFormQuestions.Distance}</span>&nbsp;
                <span className="questionTextSecondary">{OnboardingFormQuestions.DistanceSelect}</span>
              </div>
            </div>
            <div className="sliderContainer">
              <div
                id="handle_follower"
                className="sliderTooltip"
                style={{
                  position: 'absolute',
                  left: calculateSliderWidth(isMobile, serviceAreaRadiusInMiles),
                  top: 5,
                }}
              >
                {serviceAreaRadiusInMiles?.toString()?.concat(' miles') || 'No value selected'}
              </div>
              <input
                id="distance_slider"
                type="range"
                className={!serviceAreaRadiusInMiles && 'no-value'}
                min={5}
                max={200}
                step={5}
                value={serviceAreaRadiusInMiles}
                onChange={(e) => setServiceAreaRadiusInMiles(parseInt(e.target.value, 10))}
              />
              <div className="sliderLabelContainer">
                <div>{OnboardingFormQuestions.DistanceMin}</div>
                <div>{OnboardingFormQuestions.DistanceMax}</div>
              </div>
            </div>
          </div>
        </div>
      </InformationSection>
      <div className="divider" />
      <InformationSection
        title={BusinessDetailsSections.AccessAndSoftware}
        sectionRef={accessAndSoftwareRef}
      >
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {BusinessDetailsText.SupraAccess}
            </div>
            <label htmlFor="supra_yes" className="radioButtonContainer">
              <input
                type="radio"
                id="supra_yes"
                className="radioButton"
                name="supra"
                checked={hasSupra}
                onChange={(e) => setHasSupra(e.target.checked)}
              />
              <div className={hasSupra ? 'responseTextSelected' : 'responseText'}>
                {OnboardingFormQuestions.Yes}
              </div>
            </label>
            <label htmlFor="supra_no" className="radioButtonContainer">
              <input
                type="radio"
                id="supra_no"
                className="radioButton"
                name="supra"
                checked={hasSupra === false}
                onChange={(e) => setHasSupra(!e.target.checked)}
              />
              <div className={hasSupra === false ? 'responseTextSelected' : 'responseText'}>
                {OnboardingFormQuestions.No}
              </div>
            </label>
          </div>
        </div>
        {/* TODO GA9-1507: will be added as part of Inspector Onboarding */}
        {/* <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {BusinessDetailsText.SentriLockAccess}
            </div>
            <label htmlFor="sentrilock_yes" className="radioButtonContainer">
              <input
                type="radio"
                id="sentrilock_yes"
                className="radioButton"
                name="sentrilock"
                checked={hasSentriLock}
                onChange={(e) => setHasSentriLock(e.target.checked)}
              />
              <div className={hasSentriLock ? 'responseTextSelected' : 'responseText'}>
                {OnboardingFormQuestions.Yes}
              </div>
            </label>
            <label htmlFor="sentrilock_no" className="radioButtonContainer">
              <input
                type="radio"
                id="sentrilock_no"
                className="radioButton"
                name="sentrilock"
                checked={hasSentriLock === false}
                onChange={(e) => setHasSentriLock(!e.target.checked)}
              />
              <div className={hasSentriLock === false ? 'responseTextSelected' : 'responseText'}>
                {OnboardingFormQuestions.No}
              </div>
            </label>
          </div>
        </div> */}
      </InformationSection>
      <div className="divider" />
      <InformationSection
        title={BusinessDetailsSections.Documents}
        sectionRef={documentsRef}
      >
        {msaSigned
          && (
          <FormFile
            name="Master Inspection Agreement"
            category={FileTypes.CONTRACT}
            files={misaFiles}
            upload={false}
          />
          )}
      </InformationSection>
      <div className="divider" />
      <div className="buttonContainer">
        <Button
          onClick={() => updateBusinessDetails()}
          disabled={loading || !businessDetailsComplete()}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {loading ? <Spinner /> : 'Update'}
        </Button>
      </div>
    </div>
  </div>
);

export default BusinessDetails;
