import cn from 'classnames';
import React, { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { formatMoney } from '../../../../utils/money';
import styles from './styles.module.scss';
import { DrawTotalRowProps } from './types';

const initialValue = {
  totalBudget: 0,
  totalDisbursement: 0,
  totalRemainingBalance: 0,
  totalPreviousDisbursement: 0,
};

const DrawTotalRow: React.FC<DrawTotalRowProps> = ({
  data,
  displayFinancialColumns,
  displayUnitColumns,
  displayDrawDisbursementFields,
}) => {
  const {
    totalBudget,
    totalDisbursement,
    totalRemainingBalance,
    totalPreviousDisbursement,
  } = useMemo(() => (
    displayFinancialColumns
      ? data.reduce((acc, row) => row.units.reduce((
        acc2,
        {
          budget,
          disbursement,
          clientDisbursement,
          remainingBalance,
          previousDisbursement,
        },
      ) => ({
        totalBudget: acc2.totalBudget + budget,
        totalDisbursement: acc2.totalDisbursement + (clientDisbursement || disbursement),
        totalPreviousDisbursement: acc2.totalPreviousDisbursement + previousDisbursement,
        totalRemainingBalance: acc2.totalRemainingBalance + remainingBalance,
      }), acc), initialValue)
      : initialValue
  ), [data]);

  const isEdited = data.some((row) => row.units.some((unit) => unit.clientProgress));

  const totalProgress = useMemo(() => Math.round((displayFinancialColumns
    ? ((totalDisbursement + totalPreviousDisbursement) / totalBudget)
    : 0) * 100), [data, displayFinancialColumns, totalBudget]);

  const progressError = totalProgress > 100 || totalProgress < 0;
  const previousDisbursementError = totalPreviousDisbursement > totalBudget
    && totalPreviousDisbursement > 0;
  const disbursementError = totalDisbursement
    ? totalDisbursement > totalBudget
    : totalDisbursement > totalBudget;

  return (
    <tfoot>
      <tr>
        <td colSpan={displayUnitColumns ? 3 : 2}>
          Total
        </td>
        {displayFinancialColumns && (
          <td>
            {formatMoney(totalBudget)}
          </td>
        )}
        {displayFinancialColumns && displayDrawDisbursementFields && (
          <td className={previousDisbursementError ? styles.error : null}>
            {previousDisbursementError && (
              <Tooltip
                anchorSelect="#totalPreviousDisbursement"
                place="bottom"
                content="Cannot exceed item budget"
              />
            )}
            <span id="totalPreviousDisbursement">
              {formatMoney(totalPreviousDisbursement)}
            </span>
          </td>
        )}
        {displayFinancialColumns && (
          <td className={styles.verticalAlignMiddle}>
            {formatMoney(totalRemainingBalance)}
          </td>
        )}
        <td className={styles.separator}>
          {progressError && (
            <Tooltip
              anchorSelect="#progressTotal"
              place="bottom"
              content={
                totalProgress > 1
                  ? 'Cannot exceed item budget'
                  : 'Adjusted based on disbursement'
              }
            />
          )}
          <span
            id="progressTotal"
            className={cn(progressError ? styles.error : null)}
          >
            {totalProgress}%
            {isEdited ? (
              <span className={styles.editedProgress}><br />(edited)</span>
            ) : null}
          </span>
        </td>
        {displayFinancialColumns && displayDrawDisbursementFields && (
          <td className={cn(styles.verticalAlignMiddle, disbursementError ? styles.error : null)}>
            {disbursementError && (
              <Tooltip
                anchorSelect="#totalDisbursement"
                place="bottom"
                content="Cannot exceed item budget"
              />
            )}
            <span id="totalDisbursement">
              {formatMoney(totalDisbursement)}
            </span>
          </td>
        )}
      </tr>
    </tfoot>
  );
};

export default DrawTotalRow;
