import { normalize, schema } from 'normalizr';
import {
  Property,
  Report,
  ReportAttachment,
  ReportNested,
  ReportSubsystemNested,
  ReportSystemNested,
} from 'marketplace-common';
import { flatten, groupBy, isEmpty, isNil } from 'lodash';
import { Project } from '../types';
import { captureException } from './error';
import {
  ProjectHistory,
  ProjectHistoryItem,
  RehabAttachmentData,
  RehabDeficiencyData,
  RehabInformationData,
  RehabItemType,
  RehabLimitationData,
  RehabSubsystemData,
  Team,
  TeamCustomSystem,
  TeamMarket,
  TemplateWorkItemPricingsInterface,
  TemplateWorkItemsInterface,
  WorkItem,
  WorkItemAssociationsInterface,
  WorkItemAttachment,
  WorkItemAttachmentsInterface,
  WorkItemsInterface,
} from '../types/models';
import { PriceBySystem } from '../pages/RehabToolPage/types';
import { formatMoney } from './money';
import { GENERATE_REHAB_PDF, GENERATE_REHAB_CSV } from '../graphql/mutations/generateRehabPdf';
import { client } from '../graphql/createApolloClient';

export const downloadProjectCsv = async (projectId: any, params: any = {}) => {
  const input: any = { projectId };
  if (params?.tags) input.tags = params.tags;

  const { data, errors } = await client.mutate({
    mutation: GENERATE_REHAB_CSV,
    variables: { input },
  });
  if (data?.generateRehabProjectCsv?.rehabProjectCsvActiveStorageBlob.url) {
    window.open(
      data.generateRehabProjectCsv.rehabProjectCsvActiveStorageBlob.url,
      '_open',
    );
  } else {
    captureException(new Error(`Failed to generate csv for rehab report id ${projectId}`), data);
  }
  if (errors) captureException(errors);
};

export const downloadProjectPdf = async (projectId: string, params: any = {}) => {
  const input: any = { projectId };
  if (params?.tags) input.tags = params.tags;
  if (params?.contributors) input.contributors = params.contributors;

  try {
    const { data, errors } = await client.mutate({
      mutation: GENERATE_REHAB_PDF,
      variables: { input },
    });

    if (errors) {
      captureException(errors);
      return { success: false, error: 'GraphQL error occurred' };
    }

    const pdfUrl = data?.generateRehabProjectPdf?.rehabProjectPdfActiveStorageBlob?.url;

    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
      return { success: true };
    }
    throw new Error(`Failed to generate PDF for rehab report ID ${projectId}`);
  } catch (error) {
    captureException(error);
    return { success: false, error };
  }
};

const templateWorkItemPricingsSchema = new schema.Entity('templateWorkItemPricings');
const templateWorkItemsSchema = new schema.Entity('templateWorkItems', {
  templateWorkItemPricings: [templateWorkItemPricingsSchema],
});
const workItemAssociationsSchema = new schema.Entity('workItemAssociations');
const workItemAttachmentsSchema = new schema.Entity('workItemAttachments');
const workItemsSchema = new schema.Entity('workItems', {
  workItemAssociations: [workItemAssociationsSchema],
  workItemAttachments: [workItemAttachmentsSchema],
});
const reportRecordSchema = new schema.Entity('reportRecord');
const propertySchema = new schema.Entity('property');
const rehabTeamMarketSchema = new schema.Entity('rehabTeamMarkets');
const rehabTeamCustomSystemSchema = new schema.Entity('rehabTeamCustomSystems');
const teamSchema = new schema.Entity('team', {
  rehabTeamMarkets: [rehabTeamMarketSchema],
  rehabTeamCustomSystems: [rehabTeamCustomSystemSchema],
});
const rehabProjectSchema = new schema.Entity('projects', {
  workItems: [workItemsSchema],
  report: reportRecordSchema,
  property: propertySchema,
  team: teamSchema,
});

export const normalizeGraphqlRehabProjectResponse = (response: any) => {
  const { entities, result } = normalize(
    response.rehabProject,
    rehabProjectSchema,
  );

  const {
    projects,
    workItems,
    workItemAssociations,
    workItemAttachments,
    property,
    reportRecord,
    rehabTeamMarkets,
    rehabTeamCustomSystems,
    team,
  } = entities;
  if (projects === undefined || property === undefined) {
    captureException('Rehab project normalized doesn\'t have all required entities:', entities);
    return null;
  }

  const ret = {
    projectData: projects[result] as Project,
    workItemsData: workItems as WorkItemsInterface,
    workItemAttachmentsData: workItemAttachments as WorkItemAttachmentsInterface,
    workItemAssociationsData: {} as WorkItemAssociationsInterface,
    reportData: { report: {} as ReportNested },
    propertyData: {} as Property,
    rehabTeamMarkets: rehabTeamMarkets as TeamMarket[],
    rehabTeamCustomSystems: rehabTeamCustomSystems as TeamCustomSystem[],
    teamData: {} as Team,
  };

  if (team && Object.keys(team).length) {
    const teamId = Object.keys(team)[0];
    ret.teamData = team[teamId];
  }

  if (property && Object.keys(property).length) {
    const propertyId = Object.keys(property)[0];
    const {
      photo,
      ...propertyData
    } = property[propertyId];
    ret.propertyData = {
      photo: {
        url: photo?.cdnUrl || null,
      },
      ...propertyData,
    };
  }

  if (reportRecord) {
    ret.reportData = {
      report: Object.keys(reportRecord).map(
        (reportRecordId) => reportRecord[reportRecordId],
      )[0],
    };
  }

  if (workItemAssociations) {
    ret.workItemAssociationsData = Object.keys(workItemAssociations).reduce(
      (associations: WorkItemAssociationsInterface, workItemAssociationId: string) => {
        const { itemType } = workItemAssociations[workItemAssociationId];
        let parsedData = null;

        switch (itemType) {
          case RehabItemType.ReportDeficiency:
            parsedData = JSON.parse(
              workItemAssociations[workItemAssociationId].data,
            ) as RehabDeficiencyData;
            break;
          case RehabItemType.ReportInformation:
            parsedData = JSON.parse(
              workItemAssociations[workItemAssociationId].data,
            ) as RehabInformationData;
            break;
          case RehabItemType.ReportLimitation:
            parsedData = JSON.parse(
              workItemAssociations[workItemAssociationId].data,
            ) as RehabLimitationData;
            break;
          default:
            parsedData = JSON.parse(workItemAssociations[workItemAssociationId].data);
        }

        return {
          ...associations,
          [workItemAssociationId]: {
            ...workItemAssociations[workItemAssociationId],
            data: parsedData,
          },
        };
      }, {},
    );
  }

  return ret;
};

const templateWorkItemSchema = new schema.Entity('templateWorkItem');
const templateWorkItemPricingSchema = new schema.Entity('templateWorkItemPricing', {
  templateWorkItem: templateWorkItemSchema,
});
const workItemSchema = new schema.Entity('workItem', {
  templateWorkItemPricing: templateWorkItemPricingSchema,
  workItemAttachments: [workItemAttachmentsSchema],
  workItemAssociations: [workItemAssociationsSchema],
});

export const normalizeGraphqlCreateWorkItemResponse = (response: any) => {
  const { entities } = normalize(
    response.createRehabWorkItem.workItem, workItemSchema,
  );

  const {
    workItem,
    templateWorkItem,
    templateWorkItemPricing,
  } = entities;

  if (workItem === undefined) {
    captureException('Create work item normalized doesn\'t have all required entities:', entities);
    return null;
  }

  const templateWorkItemPricingsData: TemplateWorkItemPricingsInterface = Object.values(
    templateWorkItemPricing || {},
  ).reduce((acc, value) => ({
    ...acc,
    [value.id]: {
      id: value.id,
      pricingExternalId: value.pricingExternalId,
      details: value.details,
      lumpSumPrice: value.lumpSumPrice,
      pricePerUnit: value.pricePerUnit,
      unit: value.unit,
      templateWorkItemId: value.templateWorkItem,
    },
  }), {});

  const templateWorkItemData: TemplateWorkItemsInterface = Object.values(
    templateWorkItem || {},
  ).reduce((acc, value) => ({
    ...acc,
    [value.id]: {
      id: value.id,
      teamId: value.teamId,
      systemName: value.systemName,
      title: value.title,
      createdAt: value.createdAt,
      updatedAt: value.updatedAt,
      templateWorkItemPricings: Object.keys(
        templateWorkItemPricingsData || {},
      ).filter((id) => templateWorkItemPricingsData[id].templateWorkItemId === value.id),
    },
  }), {});

  const workItemData: WorkItemsInterface = Object.values(workItem).reduce(
    (acc, { templateWorkItemPricing: _, ...values }) => ({
      ...acc, [values.id]: { ...values },
    }), {},
  );

  return {
    workItemData,
    templateWorkItemData,
    templateWorkItemPricingsData,
  };
};

export const normalizeGraphqlUpdateWorkItemResponse = (response: any) => {
  const { entities } = normalize(
    response.updateRehabWorkItem.workItem, workItemSchema,
  );

  const { workItem } = entities;

  if (workItem === undefined) {
    captureException('Update work item normalized doesn\'t have all required entities:', entities);
    return null;
  }

  return Object.values(workItem).reduce(
    (acc, { templateWorkItemPricing: _, ...values }) => ({
      ...acc, [values.id]: { ...values },
    }), {},
  ) as WorkItemsInterface;
};

const workItemAssociationSchema = new schema.Entity('workItemAssociation');
export const normalizeGraphqlCreateWorkItemAssociationResponse = (response: any) => {
  const { entities } = normalize(
    response.createRehabWorkItemAssociation.workItemAssociation,
    workItemAssociationSchema,
  );

  const { workItemAssociation } = entities;

  if (workItemAssociation === undefined) {
    captureException('Create work item association normalized doesn\'t have all required entities:', entities);
    return null;
  }

  // data is a json string, which was needed for gql mutation
  // but we don't want that when adding to redux
  Object.keys(workItemAssociation).forEach((wIA) => {
    if (typeof workItemAssociation[wIA].data === 'string') {
      workItemAssociation[wIA].data = JSON.parse(workItemAssociation[wIA].data);
    }
  });

  return workItemAssociation as WorkItemAssociationsInterface;
};

const workItemAttachmentSchema = new schema.Entity('workItemAttachment');
export const normalizeGraphqlCreateWorkItemAttachmentResponse = (response: any) => {
  const { entities } = normalize(
    response.createRehabWorkItemAttachment.workItemAttachment,
    workItemAttachmentSchema,
  );

  const { workItemAttachment } = entities;

  if (workItemAttachment === undefined) {
    captureException('Create work item attachment normalized doesn\'t have all required entities:', entities);
    return null;
  }

  return workItemAttachment as WorkItemAttachmentsInterface;
};

export const normalizeProjectHistoryGqlResponse = (response: any): ProjectHistory => (
  response as any
).edges.reduce((history: ProjectHistory, cur: { node: ProjectHistoryItem }) => ({
  ...history,
  [cur.node.id]: cur.node,
}), {});

export const normalizeGraphqlTemplateWorkItemsResponse = (templateWorkItemNodes: any) => {
  const { entities } = normalize(
    templateWorkItemNodes,
    [templateWorkItemsSchema],
  );

  const { templateWorkItems, templateWorkItemPricings } = entities;

  if (templateWorkItems === undefined) {
    captureException('Template work items normalized doesn\'t have all required entities:', entities);
    return null;
  }

  return {
    templateWorkItems,
    templateWorkItemPricings,
  };
};

export const isUndefinedWorkItem = (item: WorkItem) => isEmpty(item?.title) || isNil(item.totalPrice);

export const getPricesBySystem = (
  workItems: WorkItemsInterface,
): { [id: string]: PriceBySystem } => {
  const priceInterface: { [id: string]: PriceBySystem } = {};

  if (Object.values(workItems)?.length) {
    Object.keys(workItems).forEach((id: string) => {
      const item: WorkItem = workItems[id];
      const missingPrice = isNil(item.totalPrice);
      const displayIndex = item.systemDisplayIndex ?? Infinity;

      if (!priceInterface[item.systemName]) {
        priceInterface[item.systemName] = {
          id: item.systemName,
          price: item.totalPrice,
          missingPrice,
          displayIndex,
        };
      } else {
        const newTotalPrice = priceInterface[item.systemName].price + item.totalPrice;
        priceInterface[item.systemName] = {
          id: item.systemName,
          price: newTotalPrice,
          missingPrice: missingPrice || isNil(newTotalPrice) || priceInterface[item.systemName].missingPrice,
          displayIndex,
        };
      }
    });

    return Object.fromEntries(
      Object.entries(priceInterface).sort(([, a], [, b]) => {
        if (a.displayIndex === Infinity && b.displayIndex === Infinity) {
          return a.id.localeCompare(b.id);
        }
        if (a.displayIndex === Infinity) return 1;
        if (b.displayIndex === Infinity) return -1;
        return a.displayIndex - b.displayIndex;
      }),
    );
  }

  return priceInterface;
};

export const getTotalToDisplay = (pricesBySystem: { [id: string]: PriceBySystem; }) => {
  let ret = 0;
  if (Object.values(pricesBySystem)?.length) {
    Object.values(pricesBySystem).forEach((system: PriceBySystem) => {
      if (system.price !== undefined) ret += system.price;
    });
  }
  return formatMoney(ret);
};

export const filterTemplateWorkItemsBySearchTerms = (
  items: TemplateWorkItemsInterface,
  pricings: TemplateWorkItemPricingsInterface,
  searchTerms: string[],
): TemplateWorkItemsInterface => {
  const filteredArr = Object.values(items).filter(
    ({ title, systemName, templateWorkItemPricings }) => (
      searchTerms.every((term) => title?.toLowerCase()?.includes(term)
      || systemName?.toLowerCase()?.includes(term)
      || templateWorkItemPricings.map((pricingId) => pricings[pricingId]).some(
        (pricing) => (pricing?.details?.toLowerCase() || '').includes(term),
      ))
    ),
  );
  return Object.assign({}, ...filteredArr.map((item) => ({ [item.id]: item })));
};

export const onCreateRehabWorkItemCompleted = (
  data: any,
  itemType: string,
  itemId: string,
  createRehabWorkItemAssociation: any,
  createRehabWorkItemAttachment: any,
  report: Report,
  workItemModalAttachments: (ReportAttachment | WorkItemAttachment)[],
) => {
  const promises: Promise<any>[] = [];

  switch (itemType) {
    case 'ReportSubsystem': {
      promises.push(createRehabWorkItemAssociation({
        variables: {
          input: {
            workItemId: data.createRehabWorkItem.workItem.id,
            itemId,
            itemType,
            data: JSON.stringify({ ...report.subsystems[itemId] as RehabSubsystemData }),
          },
        },
      }));
      const { reportInformationIds } = report.subsystems[itemId];
      reportInformationIds.forEach((rII) => {
        promises.push(createRehabWorkItemAssociation({
          variables: {
            input: {
              workItemId: data.createRehabWorkItem.workItem.id,
              itemId: rII,
              itemType: 'ReportInformation',
              data: JSON.stringify({ ...report.informations[rII] as RehabInformationData }),
            },
          },
        }));
      });
      break;
    }
    case 'ReportDeficiency': {
      promises.push(createRehabWorkItemAssociation({
        variables: {
          input: {
            workItemId: data.createRehabWorkItem.workItem.id,
            itemId,
            itemType,
            data: JSON.stringify({ ...report.deficiencies[itemId] as RehabDeficiencyData }),
          },
        },
      }));
      break;
    }
    case 'ReportLimitation': {
      promises.push(createRehabWorkItemAssociation({
        variables: {
          input: {
            workItemId: data.createRehabWorkItem.workItem.id,
            itemId,
            itemType,
            data: JSON.stringify({ ...report.limitations[itemId] as RehabLimitationData }),
          },
        },
      }));
      break;
    }
    case 'ReportAttachment': {
      promises.push(createRehabWorkItemAssociation({
        variables: {
          input: {
            workItemId: data.createRehabWorkItem.workItem.id,
            itemId,
            itemType,
            data: JSON.stringify({ ...report.attachments[itemId] as RehabAttachmentData }),
          },
        },
      }));
      break;
    }
    default:
      break;
  }

  Promise.all(promises).then(() => {
    workItemModalAttachments.forEach((attachment: any) => {
      if (attachment.s3ObjectKey) { // this is always null in testing
        createRehabWorkItemAttachment({
          variables: {
            input: {
              s3ObjectKey: attachment.s3ObjectKey,
              workItemId: data.createRehabWorkItem.workItem.id,
            },
          },
        });
      }
    });
    // eslint-disable-next-line no-useless-return
    return;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  }).catch((error) => {
    captureException(new Error('Failed to save work item associations ids for new work item'), data);
  });
};

export const onUpdateRehabWorkItemCompleted = (
  data: any,
  createRehabWorkItemAttachment: any,
  workItemModalAttachments: (ReportAttachment | WorkItemAttachment)[],
) => {
  try {
    workItemModalAttachments.forEach((attachment: any) => {
      if (attachment.s3ObjectKey) { // this is always null in testing
        createRehabWorkItemAttachment({
          variables: {
            input: {
              s3ObjectKey: attachment.s3ObjectKey,
              workItemId: data.updateRehabWorkItem.workItem.id,
            },
          },
        });
      }
    });
  } catch (error) {
    captureException(new Error('Failed to save work item attachments ids for updated work item'), { error, data });
  }
};

export function extractSystemData(reportSystems: ReportSystemNested[]) {
  return reportSystems.map((system) => {
    const { name, displayIndex, reportSubsystems, includeOnReport } = system;

    const subsystemArray: ReportSubsystemNested[] = reportSubsystems;

    return {
      name,
      displayIndex,
      includeOnReport,
      reportSubsystems: subsystemArray.map((subsystem: ReportSubsystemNested) => ({
        name: subsystem.name,
        displayIndex: subsystem.displayIndex,
        includeOnReport: subsystem.includeOnReport,
        status: subsystem.status,
        reportLimitations: subsystem.reportLimitations,
        note: subsystem.note,
        reportAttachments: subsystem.reportAttachments,
        reportDeficiencies: subsystem.reportDeficiencies,
        includeOnReportIfNotPresent: subsystem.includeOnReportIfNotPresent,
      })),
    };
  });
}

export const groupAndSortWorkItemsBySystem = (
  workItems: WorkItem[],
): {
  [systemName: string]: {
    subsystems: { [subsystemName: string]: WorkItem[] };
    displayIndex: number;
    name: string;
  };
} => {
  // Group work items by system and subsystem
  const grouped = workItems.reduce(
    (acc, item) => {
      const { systemName, subsystemName, systemDisplayIndex } = item;

      if (!acc[systemName]) {
        acc[systemName] = {
          name: systemName,
          displayIndex: systemDisplayIndex ?? Infinity,
          subsystems: {},
        };
      }

      if (!acc[systemName].subsystems[subsystemName]) {
        acc[systemName].subsystems[subsystemName] = [];
      }

      acc[systemName].subsystems[subsystemName].push(item);
      return acc;
    },
    {} as {
      [key: string]: {
        name: string;
        displayIndex: number;
        subsystems: { [key: string]: WorkItem[] };
      };
    },
  );

  // Sort systems by display index, with Infinity values sorted alphabetically
  const sortedSystems = Object.keys(grouped)
    .sort((a, b) => {
      const indexA = grouped[a].displayIndex;
      const indexB = grouped[b].displayIndex;

      if (indexA === Infinity && indexB === Infinity) {
        return a.localeCompare(b); // Alphabetical sort if both have Infinity index
      }
      if (indexA === Infinity) return 1;
      if (indexB === Infinity) return -1;
      return indexA - indexB;
    })
    .reduce(
      (acc, systemName) => {
        const system = grouped[systemName];

        // Keep subsystems sorted numerically by subsystemDisplayIndex
        const sortedSubsystems = Object.keys(system.subsystems)
          .sort((subA, subB) => {
            const subIndexA = system.subsystems[subA][0].subsystemDisplayIndex ?? Infinity;
            const subIndexB = system.subsystems[subB][0].subsystemDisplayIndex ?? Infinity;
            return subIndexA - subIndexB; // No alphabetical sorting for Infinity here
          })
          .reduce<{ [subsystemName: string]: WorkItem[] }>(
          (subAcc, subName) => ({ ...subAcc, [subName]: system.subsystems[subName] }),
          {},
        );

        acc[systemName] = { ...system, subsystems: sortedSubsystems };
        return acc;
      },
      {} as {
        [systemName: string]: {
          name: string;
          displayIndex: number;
          subsystems: { [subsystemName: string]: WorkItem[] };
        };
      },
    );

  return sortedSystems;
};

// Rehab PDF helpers
export interface GroupedWorkItems {
  [systemName: string]: {
    [subsystemName: string]: WorkItem[];
  };
}

export const groupWorkItemsBySystemAndSubsystem = (
  workItems: WorkItem[],
): GroupedWorkItems => {
  const groupedBySystem = groupBy(workItems, 'systemName');
  const result: GroupedWorkItems = {};

  // Sort systems by displayIndex, with Infinity values sorted alphabetically
  const sortedSystems = Array.from(new Set(workItems.map((item) => item.systemName)))
    .map((name) => ({
      name,
      displayIndex: workItems.find((item) => item.systemName === name)?.systemDisplayIndex ?? Infinity,
    }))
    .sort((a, b) => {
      if (a.displayIndex === Infinity && b.displayIndex === Infinity) {
        return a.name.localeCompare(b.name); // Alphabetical sort if both have Infinity index
      }
      if (a.displayIndex === Infinity) return 1;
      if (b.displayIndex === Infinity) return -1;
      return a.displayIndex - b.displayIndex;
    });

  sortedSystems.forEach(({ name: systemName }) => {
    const subsystems = groupBy(groupedBySystem[systemName], 'subsystemName');

    // Keep subsystems sorted numerically by subsystemDisplayIndex
    const sortedSubsystems = Object.keys(subsystems)
      .map((name) => ({
        name,
        displayIndex: subsystems[name][0]?.subsystemDisplayIndex ?? Infinity,
      }))
      .sort((a, b) => a.displayIndex - b.displayIndex); // No alphabetical sorting for Infinity here

    result[systemName] = sortedSubsystems.reduce(
      (acc, { name: subsystemName }) => ({
        ...acc,
        [subsystemName]: subsystems[subsystemName],
      }),
      {} as { [subsystemName: string]: WorkItem[] },
    );
  });

  return result;
};

export const calculateWorkItemSummary = (groupedWorkItems: GroupedWorkItems) => {
  const allItems = flatten(
    Object.values(groupedWorkItems).flatMap((subsystems) => Object.values(subsystems)),
  );

  return {
    totalCount: allItems.length,
    totalCost: allItems.reduce((sum, cur) => sum + (cur.totalPrice || 0), 0),
    isAnyPriceMissing: allItems.some((item) => isNil(item.totalPrice)),
  };
};
