import React from 'react';
import Modal from 'react-modal';
import assetIconActionClose from 'haven-design-system/build/assets/svgs/action/close.svg';
import {
  colorBaseWhitePrimary,
  colorPaletteGrayscale50,
  colorPaletteRed600,
} from 'haven-design-system/build/typescript/es6';
import IconButton from '../../../../../../components/IconButton';
import Button from '../../../../../../components/Button';
import styles from '../styles.module.scss';

const RemoveRehabItemModalStyle = {
  overlay: {
    backgroundColor: colorPaletteGrayscale50 + 75, // appending opacity
    zIndex: 6,
  },
  content: {
    backgroundColor: colorBaseWhitePrimary,
    border: 'none',
    padding: 0,
    width: '430px',
    height: '217px',
    top: '35%',
    left: '35%',
  },
};

interface Props {
  isRemoveModalOpen: boolean,
  setIsRemoveModalOpen: (bool: boolean) => void,
  handleRemoveWorkItemFromProject: () => void,
}

const RemoveItemModal: React.FC<Props> = ({
  isRemoveModalOpen,
  setIsRemoveModalOpen,
  handleRemoveWorkItemFromProject,
}) => (
  <Modal isOpen={isRemoveModalOpen} ariaHideApp={false} style={RemoveRehabItemModalStyle}>
    <div className={styles.removeRehabItemContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.removeRehabMainText}>Remove work item?</div>
        <IconButton onClick={() => setIsRemoveModalOpen(false)}>
          <img src={assetIconActionClose} alt="close-icon" />
        </IconButton>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.removeRehabBodyText}>
          You will lose all information and uploaded media associated to this item.
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button data-testid="closeModalButton" secondary onClick={() => setIsRemoveModalOpen(false)}>
          Cancel
        </Button>
        <Button
          data-testid="closeModalButton"
          onClick={handleRemoveWorkItemFromProject}
          style={{ backgroundColor: colorPaletteRed600, border: 'none' }}
        >
          Remove Item
        </Button>
      </div>
    </div>
  </Modal>
);

export default RemoveItemModal;
