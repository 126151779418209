import React from 'react';
import { MenuButtonSectionProps } from './types';

const Spinner: React.FC<MenuButtonSectionProps> = ({ sectionName, sectionRef }) => {
  const scrollToMenuButtonSection = () => {
    window.scrollTo({
      top: sectionRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div
      role="button"
      className="menuButtonSection"
      onClick={() => scrollToMenuButtonSection()}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      {sectionName}
    </div>
  );
};

export default Spinner;
