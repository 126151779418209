// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useMemo, useState } from 'react';
import uniq from 'lodash/uniq';
import SearchIcon from '../../../../../../assets/icons/search.svg';
import { useTypedSelector } from '../../../../../../redux/store';
import {
  selectRehabProject,
  selectTemplateWorkItemPricings,
  selectTemplateWorkItems,
} from '../../../../../../redux/selectors';
import {
  TemplateWorkItemsInterface,
} from '../../../../../../types';
import { filterTemplateWorkItemsBySearchTerms } from '../../../../../../utils/rehab';
import Button from '../../../../../../components/Button';
import { TabOptions, WorkItemFormState } from '../types';
import styles from '../styles.module.scss';
import { RehabToolContext } from '../../../../context';
import { getDefaultTagsForTeam } from '../useWorkItemFormState';

interface Props {
  templateWorkItemsForSystem: TemplateWorkItemsInterface;
  state: WorkItemFormState;
  setState: (value: any) => void;
  close: () => void;
}

const WorkItemLibraryLoader: React.FC<Props> = ({
  templateWorkItemsForSystem,
  state,
  setState,
  close,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { state: contextState } = useContext(RehabToolContext);
  const isAddingWorkItemWithNoReportReference = !contextState.isAddingFromReport;
  const hasNoWorkItemAssocations = !contextState.modalWorkItem || contextState.modalWorkItem?.workItemAssociations?.length === 0 ;

  const project = useTypedSelector((reduxState) => selectRehabProject(reduxState, contextState.projectId));
  const templateWorkItems = useTypedSelector(selectTemplateWorkItems);
  const templateWorkItemPricings = useTypedSelector(selectTemplateWorkItemPricings);

  const filteredTemplateWorkItemsForSystem = useMemo(() => (
    searchTerm.length > 2
      ? filterTemplateWorkItemsBySearchTerms(
        templateWorkItems,
        templateWorkItemPricings,
        searchTerm.trim().toLowerCase().split(' '),
      )
      : templateWorkItemsForSystem
  ), [templateWorkItemsForSystem, searchTerm]);

  const handleSelectWorkItem = (id: string) => {
    const libraryItem = filteredTemplateWorkItemsForSystem[id];
    const pricingId = libraryItem.templateWorkItemPricings[0] || null;
    const pricing = pricingId ? templateWorkItemPricings[pricingId] : null;

    setState({
      selectedTab: TabOptions.WorkItemFormTab,
      systemName: hasNoWorkItemAssocations && isAddingWorkItemWithNoReportReference ? libraryItem.systemName : state.systemName,
      title: libraryItem.title,
      description: pricing?.details || state.description,
      unit: pricing?.unit || '',
      unitCost: pricing?.pricePerUnit || state.unitCost,
      teamMarketId: pricing?.teamMarketId || '',
      totalCost: pricing?.lumpSumPrice || state.totalCost,
      pricingExternalId: pricing?.pricingExternalId || '',
      templateWorkItemPricingId: pricingId || '',
      saveToLibrary: false,
      useLumpSumCost: pricing?.lumpSumPrice != null,
      tags: uniq([...getDefaultTagsForTeam(project.teamId), ...(pricing?.tags?.length > 0 ? pricing?.tags : state.tags)]),
    });
  };

  return (
    <div className={styles.workItemLibrary}>
      <div className={styles.workItemLibraryContainer}>
        <img className={styles.searchIcon} src={SearchIcon} alt="search" />
        <input
          id="template-work-item-search"
          className={styles.searchInput}
          placeholder="Search"
          onChange={(e: any) => setSearchTerm(e?.target?.value?.length ? e.target.value : '')}
        />

        {Object.values(filteredTemplateWorkItemsForSystem).map(({
          id,
          title,
          templateWorkItemPricings: pricings,
        }) => {
          const firstId = pricings.length ? pricings[0] : null;
          const pricing = firstId && templateWorkItemPricings[firstId]
            ? templateWorkItemPricings[firstId] : null;
          const lumpSumPrice = pricing?.lumpSumPrice || null;
          return (
            <div
              className={styles.templateWorkItem}
              key={id}
              id={`template-work-item-${id}`}
              onClick={() => handleSelectWorkItem(id)}
            >
              <div className={styles.templateWorkItemTitle}>{title}</div>
              <div className={styles.templateWorkItemPricingDetails}>{pricing?.details}</div>
              <div className={styles.templateWorkItemPricing}>
                {lumpSumPrice != null ? `$${lumpSumPrice}` : (
                  <>
                    {pricing?.pricePerUnit != null && `$${pricing.pricePerUnit}`}
                    &nbsp;
                    <div className={styles.templateWorkItemPricingDetails}>
                      {pricing?.unit && `per ${pricing.unit}`}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.workItemLibraryBottomRow}>
        <Button secondary onClick={close}>Cancel</Button>
      </div>
    </div>
  );
};

export default WorkItemLibraryLoader;
