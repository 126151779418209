import { Context, Dispatch } from 'react';
import {
  Report,
  ReportSystem,
  ReportAttachment,
  ReportSeverityType,
  IdRef,
  sortReportSystemOrSubsystemByDisplayIndex,
} from 'marketplace-common';
import createDataContext from '../../utils/createDataContext';
import {
  WorkItemAttachment,
  WorkItem,
  WorkItemsInterface,
  PageErrorType,
  ReportDeliveryFilter,
} from '../../types';
import { hideSystem } from '../../utils/report';
import { ModalInformations, RehabTabs, WorkItemModalOptions } from './types';

export interface RehabToolContextType {
  projectId: string,
  propertyId: number,
  reportId: IdRef,
  report: Report,
  sortedReportSystems: ReportSystem[],
  groupedReportSystemsByName: { [name: string]: ReportSystem[] },
  loading: boolean,
  isRehabReadOnly: boolean,
  isCostSplittingEnabled: boolean,
  imageWidth: number,
  isReInspection: boolean,
  noDeficienciesRequired: boolean,
  isWorkItemModalOpen: boolean,
  workItemModalAttachments: (ReportAttachment | WorkItemAttachment)[],
  modalSystemName: string,
  modalSubsystemName: string,
  modalInformations: ModalInformations,
  modalNoteTitle: string,
  modalNoteDetails: string,
  modalNote: string,
  modalBadgeSeverity: ReportSeverityType,
  modalWorkItem: WorkItem,
  modalPricingExternalId: string | null,
  modalTemplateWorkItemPricingId: string | null,
  isLimitation: boolean,
  workItems: WorkItemsInterface,
  incompleteWorkItemsForModal: WorkItemModalOptions[]
  itemType: string,
  itemId: string,
  teamId: IdRef,
  projectQueryError: PageErrorType | null,
  inspectifyAdmin: boolean,
  filter: ReportDeliveryFilter,
  tab: RehabTabs,
  isPublishing: boolean,
  isUpdatingProject: boolean,
  isUploadMediaModalOpen: boolean,
  isAddingFromReport: boolean, 
}

export interface RehabToolActionType {
  type: string,
  payload: { key?: string, value: any },
}

const reducer = (
  context: RehabToolContextType,
  action: RehabToolActionType,
) => {
  const { type, payload: { key, value } } = action;
  switch (type) {
    case 'SET':
      return { ...context, [key]: value };
    case 'MULTI_SET':
      return { ...context, ...value };
    case 'SET_INCOMPLETE_WORK_ITEMS':
      return { ...context, incompleteWorkItems: value };
    case 'SET_INCOMPLETE_WORK_ITEM_MODAL_OPTIONS':
      return {
        ...context,
        incompleteWorkItemsForModal: [
          ...context.incompleteWorkItemsForModal,
          value,
        ],
      };
    case 'COMPLETE_WORK_ITEM_MODAL_OPTIONS': {
      const completedWorkItemId = value;

      return {
        ...context,
        incompleteWorkItemsForModal: [
          ...context.incompleteWorkItemsForModal.filter(
            (item) => item.workItem?.id !== completedWorkItemId,
          ),
        ],
      };
    }
    default:
      return context;
  }
};

const set = (dispatch: Dispatch<RehabToolActionType>) => (key: string, value: any) => {
  dispatch({ type: 'SET', payload: { key, value } });
};

const setPropertyAndTeam = (
  dispatch: Dispatch<RehabToolActionType>,
) => (
  propertyId: number,
  reportId: IdRef,
  isRehabReadOnly: boolean,
  teamId?: string,
) => {
  const context = {
    propertyId,
    reportId,
    isRehabReadOnly,
  } as RehabToolContextType;

  if (teamId) context.teamId = teamId;

  dispatch({ type: 'MULTI_SET', payload: { value: context } });
};

const setReport = (
  dispatch: Dispatch<RehabToolActionType>,
) => (report: Report) => {
  if (report) {
    const context = {
      reportId: report.report.id,
    } as RehabToolContextType;
    context.report = report;

    const isReInspection = Object.values(report?.deficiencies || {}).some(
      (deficiency) => deficiency?.originalReportDeficiencyId,
    );
    context.isReInspection = isReInspection;

    const noDeficienciesRequired = report?.report?.noDeficienciesAllowed || false;
    context.noDeficienciesRequired = noDeficienciesRequired;

    if (report?.systems) {
      const sortedReportSystems = sortReportSystemOrSubsystemByDisplayIndex(
        Object.values(report.systems).filter(
          (system) => system.includeOnReport && !hideSystem(system, report),
        ),
      ) as ReportSystem[];
      context.sortedReportSystems = sortedReportSystems;

      const groupedReportSystemsByName = sortedReportSystems.reduce(
        (acc: { [name: string]: ReportSystem[] }, system) => ({
          ...acc, [system.name]: [...(acc[system.name] || []), system],
        }), {},
      );
      context.groupedReportSystemsByName = groupedReportSystemsByName;
    }

    dispatch({ type: 'MULTI_SET', payload: { value: context } });
  }
};

const setProjectWorkItems = (
  dispatch: Dispatch<RehabToolActionType>,
) => (projectId: IdRef, rehabWorkItems: WorkItemsInterface) => {
  if (projectId && rehabWorkItems) {
    const context = {} as RehabToolContextType;

    const workItems: WorkItemsInterface = Object.keys(rehabWorkItems).reduce(
      (acc, workItemId) => {
        if (rehabWorkItems?.[workItemId]?.projectId === projectId) {
          return { ...acc, [workItemId]: rehabWorkItems[workItemId] };
        }
        return acc;
      }, {} as WorkItemsInterface,
    );
    context.workItems = workItems;

    dispatch({ type: 'MULTI_SET', payload: { value: context } });
  }
};

const setIncompleteWorkItems = (
  dispatch: Dispatch<RehabToolActionType>,
) => (incompleteWorkItems: WorkItemsInterface) => {
  dispatch({
    type: 'SET_INCOMPLETE_WORK_ITEMS',
    payload: {
      value: incompleteWorkItems,
    },
  });
};

const setIncompleteWorkItemOptions = (
  dispatch: Dispatch<RehabToolActionType>,
) => (workItemModalOptions: WorkItemModalOptions) => {
  dispatch({
    type: 'SET_INCOMPLETE_WORK_ITEM_MODAL_OPTIONS',
    payload: {
      value: workItemModalOptions,
    },
  });
};

const completeWorkItemOptions = (
  dispatch: Dispatch<RehabToolActionType>,
) => (workItemId: IdRef) => {
  dispatch({
    type: 'COMPLETE_WORK_ITEM_MODAL_OPTIONS',
    payload: {
      value: workItemId,
    },
  });
};

// despite system details being a report page component, system only calls
// setWorkItemModalOptions only within Rehab Tool Context... 

const setWorkItemModalOptions = (
  dispatch: Dispatch<RehabToolActionType>,
) => (options: WorkItemModalOptions) => {
  dispatch({
    type: 'MULTI_SET',
    payload: {
      value: {
        isWorkItemModalOpen: options.isOpen,
        workItemModalAttachments: options.attachments,
        modalSystemName: options.systemName,
        modalSubsystemName: options.subsystemName,
        modalInformations: options.informations,
        modalNoteTitle: options.noteTitle,
        modalNoteDetails: options.noteDetails,
        modalNote: options.note,
        modalBadgeSeverity: options.severity,
        modalPricingExternalId: options.pricingExternalId,
        modalTemplateWorkItemPricingId: options.templateWorkItemPricingId,
        isLimitation: options.limitation,
        modalWorkItem: options.workItem,
        itemType: options.itemType,
        itemId: options.itemId,
        isAddingFromReport: options.isAddingFromReport, 
      },
    },
  });
};

export const initialRehabToolContext: RehabToolContextType = {
  projectId: null,
  propertyId: null,
  reportId: null,
  report: null,
  sortedReportSystems: [],
  groupedReportSystemsByName: {},
  loading: false,
  isRehabReadOnly: false,
  isCostSplittingEnabled: false,
  imageWidth: 180,
  isReInspection: false,
  noDeficienciesRequired: false,
  isWorkItemModalOpen: false,
  workItemModalAttachments: [],
  modalSystemName: null,
  modalSubsystemName: null,
  modalInformations: [],
  modalNoteTitle: null,
  modalNoteDetails: null,
  modalNote: null,
  modalBadgeSeverity: null,
  modalPricingExternalId: null,
  modalTemplateWorkItemPricingId: null,
  modalWorkItem: null,
  isLimitation: false,
  workItems: {},
  incompleteWorkItemsForModal: [],
  itemType: null,
  itemId: null,
  teamId: null,
  projectQueryError: null,
  inspectifyAdmin: false,
  filter: ReportDeliveryFilter.FullReport,
  tab: RehabTabs.Rehab,
  isPublishing: false,
  isUpdatingProject: false,
  isUploadMediaModalOpen: false,
  isAddingFromReport: false,
};

export const rehabToolActions = {
  set,
  setPropertyAndTeam,
  setReport,
  setProjectWorkItems,
  setWorkItemModalOptions,
  setIncompleteWorkItems,
  setIncompleteWorkItemOptions,
  completeWorkItemOptions,
};

type ContextType = { state: RehabToolContextType } & {
  set: (key: string, value: any) => void,
  setPropertyAndTeam: (
    propertyId: number,
    reportId: IdRef,
    isRehabReadOnly: boolean,
    teamId?: string,
  ) => void,
  setReport: (report: Report) => void,
  setProjectWorkItems: (projectId: IdRef, rehabWorkItems: WorkItemsInterface) => void,
  setWorkItemModalOptions: (options: WorkItemModalOptions) => void,
  setIncompleteWorkItems: (items: WorkItemsInterface) => void;
  setIncompleteWorkItemOptions: (options: WorkItemModalOptions) => void,
  completeWorkItemOptions: (workItemId: IdRef) => void,
};

export type RehabToolContextAndActionsType = {
  Context: Context<ContextType>,
  Provider: ({ children }: any) => JSX.Element,
};

export const {
  Context: RehabToolContext,
  Provider: RehabToolProvider,
}: RehabToolContextAndActionsType = createDataContext(
  reducer,
  rehabToolActions,
  initialRehabToolContext,
);
