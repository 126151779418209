import { useMemo } from 'react';
import { toast } from 'react-toastify';

import { client } from '../../graphql/createApolloClient';
import { GENERATE_REPORT_PDF } from '../../graphql/mutations/generateReportPdf';
import { selectActive, selectReports } from '../../redux/selectors';
import { useTypedSelector } from '../../redux/store';
import { EventName, track } from '../../utils/analytics';

export const useReportPdf = () => {
  const active = useTypedSelector(selectActive);
  const reports = useTypedSelector(selectReports);

  const report = useMemo(() => (
    active.report?.id && reports[active.report.id] ? reports[active.report.id] : null
  ), [active.report, reports]);

  const downloadReportPdf = async () => {
    if (!report?.report?.id) return;

    const input: any = {
      reportId: report.report.id,
      systemGroupIds: active.systemGroupFilters.ids,
    };

    const pdfPromise = client.mutate({
      mutation: GENERATE_REPORT_PDF,
      variables: { input },
    });

    toast.promise(pdfPromise, {
      pending: 'Downloading inspection report PDF...',
      success: 'Successfully downloaded inspection report PDF.',
      error: 'There was an error generating the report PDF.',
    });

    const { data } = await pdfPromise;

    if (data?.generatePdfReport?.pdfReportActiveStorageBlob?.url) {
      window.open(
        data.generatePdfReport.pdfReportActiveStorageBlob.url,
        '_blank',
      );
    }

    track(EventName.DownloadInspectionReport, { reportId: report.report.id });
  };

  return {
    downloadReportPdf,
  };
};
