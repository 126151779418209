import React, { useMemo } from 'react';
import { Property } from 'marketplace-common';
import { isNil } from 'lodash';
import BetterLogoSvg from '../../assets/better/BetterInspect.svg';
import InspectifyLogoSvg from '../../assets/inspectify/Inspectify_Logo_Color.svg';
import Spinner from '../../components/Spinner';
import { getAddressLabel } from '../../utils/report';
import Cover from './sections/Cover';
import Summary from './sections/Summary';
import Content from './sections/Content';
import {
  Page,
  HeaderPrint,
  FooterPrint,
  FooterPrintText,
  MenuPoweredByText,
} from '../../components/PDF/styles';
import { GroupedWorkItems } from '../../utils/rehab';

interface Props {
  property: Property,
  loading: boolean,
  imageWidth: number,
  workItems: GroupedWorkItems
}

const RehabToolPdfPage: React.FC<Props> = ({
  loading,
  property,
  imageWidth,
  workItems,
}) => {
  const isBetterInspect = useMemo(() => window.location.hostname.includes('betterinspect'), []);
  if (loading || isNil(property)) return <Spinner />;

  return (
    <Page>
      <table>
        <thead>
          <tr>
            <td>
              <HeaderPrint className="hideOnFirstPage">
                {isBetterInspect ? (
                  <img src={BetterLogoSvg} alt="Better Inspect" style={{ maxWidth: '190px' }} />
                ) : (
                  <img src={InspectifyLogoSvg} alt="Inspectify" />
                )}

                <div className="float-right">
                  {isBetterInspect && (
                  <>
                    <MenuPoweredByText>Powered by</MenuPoweredByText>
                    <a href="https://www.inspectify.com">
                      <img src={InspectifyLogoSvg} alt="Inspectify" />
                    </a>
                  </>
                  )}
                </div>
              </HeaderPrint>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Cover property={property} />
              {!property?.photo?.url && <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p> /* force page break */}
            </td>
          </tr>
          <tr>
            <td>
              <Summary workItems={workItems} />
            </td>
          </tr>
          <tr>
            <td>
              <Content workItems={workItems} imageWidth={imageWidth} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <FooterPrint>
                <FooterPrintText>{getAddressLabel(property)}</FooterPrintText>
              </FooterPrint>
            </td>
          </tr>
        </tfoot>
      </table>
    </Page>
  );
};

export default RehabToolPdfPage;
