import React, { useEffect } from 'react';
import { Container } from './styles';

const Calendar: React.FC = () => {
  useEffect(() => {
    // eslint-disable-next-line no-console
  }, []);

  return <Container />;
};

export default Calendar;
