import React, { useMemo } from 'react';
import cn from 'classnames';
import {
  Report,
  ReportAttachment,
  ReportLimitation,
  ReportLocation,
} from 'marketplace-common';
import { WorkItemModalOptions } from '../../RehabToolPage/types';
import Locations from './Locations';
import Attachments from './Attachments';
import IssueBadge from './IssueBadge';
import styles from './styles.module.scss';
import { RectangularAddWorkItemButton } from '../../RehabToolPage/components/AddWorkItemButton/AddWorkItemButton';

interface Props {
  report?: Report
  limitation: ReportLimitation
  limitationLocations: ReportLocation[]
  imageWidth: number
  handleWorkItemModal?: (options: WorkItemModalOptions) => void
  systemName: string
  subsystemName: string
  isMediaOnly: boolean
  isRehab?: boolean
  isPrint?: boolean
  handleOpenReportMediaUploader?: (itemId: string, itemType: string, reportSystemGroupId?: string | null) => void;
}

const LimitationDetails: React.FC<Props> = ({
  report,
  limitation,
  limitationLocations,
  imageWidth,
  isRehab,
  handleWorkItemModal,
  systemName,
  subsystemName,
  isMediaOnly,
  handleOpenReportMediaUploader,
  isPrint,
}) => {
  // NOTE stef: can't consume context at component level to avoid prop drilling
  // consider creating separate components inside rehab directory
  // or move shared components to common components directory
  const subsystem = report?.subsystems[limitation.reportSubsystemId];
  const system = report?.systems[subsystem?.reportSystemId];

  const limitationAttachments: ReportAttachment[] = useMemo(
    () => ((limitation?.reportAttachmentIds || []) as string[]).reduce(
      (acc, id) => {
        if (report?.attachments[id]) { return [...acc, report.attachments[id]]; }
        return acc;
      }, [],
    ), [limitation, report?.attachments],
  );

  return (
    <div id={limitation.id} data-testid="limitation" className={styles.limitationContainer}>
      <div className={styles.limitationHeaderDetailContainer}>
        <hr className={styles.divider} />
        <div className={styles.displayRowSpacedBetween}>
          <IssueBadge type="limitation" />
          {(isRehab && limitationAttachments.length > 0) && (
          <RectangularAddWorkItemButton onClick={() => handleWorkItemModal({
            isOpen: true,
            attachments: limitationAttachments,
            systemName,
            subsystemName,
            noteTitle: limitation.title,
            noteDetails: limitation.detail,
            note: limitation.note,
            limitation: true,
            itemType: 'ReportLimitation',
            itemId: limitation.id,
            isAddingFromReport: true,
          })}
          />
          )}
        </div>
        <div className={styles.headerContainer}>
          <div className={cn(styles.indicator, styles.limitationBackground)} />
          <p className={styles.labelValue}>{limitation.title}</p>
        </div>
        {!isMediaOnly && <p className={styles.limitationDetailText}>{limitation.detail}</p>}
      </div>

      {!isMediaOnly && limitationLocations.length > 0 && (
      <Locations
        reportLocations={limitationLocations}
      />
      )}

      <Attachments
        title="Limitation photos / videos"
        attachments={limitationAttachments}
        imageWidth={imageWidth}
        isRehab={isRehab}
        handleWorkItemModal={handleWorkItemModal}
        systemName={systemName}
        subsystemName={subsystemName}
        noteTitle={limitation.title}
        noteDetails={limitation.detail}
        note={limitation.note}
        isLimitation
        isPrint={isPrint}
        handleOpenReportMediaUploader={() => {
          handleOpenReportMediaUploader(limitation.id, 'ReportLimitation', system?.reportSystemGroupId);
        }}
      />

      {!isMediaOnly && limitation.note !== null && (
      <div className={styles.commentContainer}>
        <p className={styles.labelHeader}>Note</p>
        <p className={styles.commentText}>{limitation.note}</p>
      </div>
      )}
    </div>
  );
};

export default LimitationDetails;
