import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IdRef } from 'marketplace-common';
import { toast } from 'react-toastify';
import {
  FETCH_REHAB_PROJECT,
  FETCH_REHAB_PROJECT_READ_ONLY,
  FETCH_TEMPLATE_WORK_ITEMS,
  FETCH_WORK_HISTORY_FOR_PROJECT,
  USER_CONTRACTOR,
} from '../../graphql/queries';
import {
  SplitFlags,
  setActiveProject,
  setActiveReport,
  saveProjectHistory,
  saveRehabProject,
  setRehabProject,
  saveTemplateWorkItems,
  clearTemplateWorkItems,
} from '../../redux/actions';
import { ReportsPageProvider } from '../../utils/reportsPageContext';
import { useCurrentUser } from '../../utils/user';
import { useTreatment } from '../../utils/splitio';
import { captureException } from '../../utils/error';
import InspectifyLayout from '../../components/InspectifyLayout';
import {
  PageErrorType,
  User,
  UserContractor,
} from '../../types';
import RehabToolPage from './RehabToolPage';
import { RehabToolContext } from './context';
import { useTypedSelector } from '../../redux/store';
import {
  selectProperties, selectRehab, selectReports, selectWorkItems,
} from '../../redux/selectors';
import { PUBLISH_REHAB_PROJECT, UPDATE_REHAB_PROJECT } from '../../graphql/mutations/rehab';

interface Props { projectId: string }

const TEMPLATE_WORK_ITEMS_PER_PAGE = 1000;

const RehabToolPageLoader: React.FC<Props> = ({ projectId }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const reports = useTypedSelector(selectReports);
  const rehab = useTypedSelector(selectRehab);
  const workItems = useTypedSelector(selectWorkItems);
  const properties = useTypedSelector(selectProperties);

  const {
    state,
    set,
    setPropertyAndTeam,
    setReport,
    setProjectWorkItems,
  } = useContext(RehabToolContext);

  const [nextHistoryCursor, setNextHistoryCursor] = useState('');
  const featureRehabHistory = useTreatment(SplitFlags.FeatureRehabHistory);
  const projectHistoryFetchLimit = 25;
  const project = useMemo(() => rehab.projects[projectId], [rehab]);

  const [fetchTemplateWorkItems] = useLazyQuery(FETCH_TEMPLATE_WORK_ITEMS, {
    onCompleted: (data) => {
      if (data.templateWorkItems) {
        dispatch(saveTemplateWorkItems(data.templateWorkItems.nodes));

        if (data.templateWorkItems.pageInfo.hasNextPage) {
          fetchTemplateWorkItems({
            variables: {
              first: TEMPLATE_WORK_ITEMS_PER_PAGE,
              after: data.templateWorkItems.pageInfo.endCursor,
            },
          });
        }
      } else {
        set('projectQueryError', PageErrorType.GeneralError);
      }
    },
    onError: (e) => {
      if (e.message === '403: Forbidden') {
        set('projectQueryError', PageErrorType.Unauthorized);
      } else {
        set('projectQueryError', PageErrorType.GeneralError);
      }
    },
  });

  const [fetchRehabProject,
    { loading: loadingRehabProject }] = useLazyQuery(FETCH_REHAB_PROJECT, {
    variables: {
      id: projectId,
      resizedAttachmentWidths: [state.imageWidth],
      rotatedAttachmentDegrees: [90, 180, 270],
    },
    context: { headers: { project_id: projectId } },
    onCompleted: (data) => {
      if (data.rehabProject) {
        set('isCostSplittingEnabled', data.rehabProject.team.enableRehabCostSplitting);
        dispatch(saveRehabProject(data));

        setPropertyAndTeam(
          data.rehabProject.propertyId,
          data.rehabProject.reportId,
          false,
          data.rehabProject.teamId,
        );
      } else {
        set('projectQueryError', PageErrorType.NotFound);
      }
    },
    onError: (e) => {
      if (e.message === '403: Forbidden') {
        set('projectQueryError', PageErrorType.Unauthorized);
      } else {
        set('projectQueryError', PageErrorType.GeneralError);
      }
    },
  });

  const [fetchRehabProjectReadOnly, { loading: loadingRehabProjectReadOnly }] = useLazyQuery(
    FETCH_REHAB_PROJECT_READ_ONLY, {
      variables: {
        id: projectId,
        resizedAttachmentWidths: [state.imageWidth],
        rotatedAttachmentDegrees: [90, 180, 270],
      },
      context: { headers: { project_id: projectId } },
      onCompleted: (data) => {
        if (data.rehabProject) {
          dispatch(saveRehabProject(data, true));

          setPropertyAndTeam(
            data.rehabProject.propertyId,
            data.rehabProject.reportId,
            true,
          );
        } else {
          set('projectQueryError', PageErrorType.NotFound);
        }
      },
      onError: (e) => {
        if (e.message === '403: Forbidden') {
          set('projectQueryError', PageErrorType.Unauthorized);
        } else {
          set('projectQueryError', PageErrorType.GeneralError);
        }
      },
    },
  );

  const validateTeamMembership = (userData: User) => {
    const { teamId, teamUsers } = userData;
    const isUserATeamMember = teamUsers?.some((user) => user?.team?.id === teamId);

    return isUserATeamMember;
  };

  const [fetchUserContractor,
    { loading: loadingUser }] = useLazyQuery<UserContractor>(USER_CONTRACTOR, {
    onCompleted: (data) => {
      set('inspectifyAdmin', !!data.currentUser?.admin);

      const isUserValid = validateTeamMembership(data?.currentUser);

      if (isUserValid) {
        fetchRehabProject();
      } else {
        fetchRehabProjectReadOnly();
      }
    },
    onError: () => {
      set('projectQueryError', PageErrorType.GeneralError);
    },
  });

  const [
    fetchProjectHistory,
    { loading: loadingProjectHistory },
  ] = useLazyQuery(FETCH_WORK_HISTORY_FOR_PROJECT, {
    context: { headers: { project_id: projectId } },
    onCompleted: (data) => {
      if (data.projectHistory) {
        dispatch(saveProjectHistory(projectId, data.projectHistory));
        const { pageInfo, edges } = data.projectHistory as any;
        let nextCursor = '';
        if (pageInfo?.hasNextPage && edges?.length) {
          nextCursor = edges[edges.length - 1]?.cursor || '';
        }
        setNextHistoryCursor(nextCursor);
      }
    },
    onError: (e) => {
      captureException(e, { projectId });
    },
  });

  const [publishProject] = useMutation(
    PUBLISH_REHAB_PROJECT,
    {
      onCompleted: (data) => {
        set('isPublishing', false);
        dispatch(setRehabProject(data.publishRehabProject.project));
      },
      onError: () => {
        set('isPublishing', false);
        // eslint-disable-next-line no-alert
        window.alert('Failed to publish project, Please retry or contact customer support for assistance');
        captureException(new Error('Graphql failed to delete work item'));
      },
    },
  );

  const [updateProject] = useMutation(
    UPDATE_REHAB_PROJECT,
    {
      onCompleted: (data) => {
        set('isUpdatingProject', false);

        toast.info('Market updated! New work items created will use the new market pricing');
        dispatch(setRehabProject(data.updateRehabProject.project));
      },
      onError: (e) => {
        set('isUpdatingProject', false);
        // eslint-disable-next-line no-alert
        window.alert('Failed to change the market of this project, Please retry or contact customer support for assistance');
        captureException(e);
      },
    },
  );

  const handleFetchProjectHistory = (firstFetch = false) => {
    if (featureRehabHistory === 'on' && !loadingProjectHistory) {
      if (firstFetch) {
        fetchProjectHistory({ variables: { first: projectHistoryFetchLimit, projectId } });
      } else if (nextHistoryCursor.length > 0) {
        fetchProjectHistory(
          { variables: { first: projectHistoryFetchLimit, projectId, after: nextHistoryCursor } },
        );
      }
    }
  };

  const handlePublishProject = () => {
    set('isPublishing', true);
    publishProject({ variables: { input: { projectId } } });
  };

  const handleChangeTeamMarket = (teamMarketId: IdRef) => {
    set('isUpdatingProject', true);
    dispatch(clearTemplateWorkItems());
    updateProject({ variables: { input: { id: projectId, teamMarketId } } });
  };

  useEffect(() => {
    if (projectId && state.projectId === null) set('projectId', projectId);
  }, [projectId]);

  useEffect(() => {
    if (state?.reportId && reports && reports[state.reportId]) {
      setReport(reports[state.reportId]);
    }
  }, [state?.reportId, reports]);

  useEffect(() => {
    if (projectId && workItems) setProjectWorkItems(projectId, workItems);
  }, [projectId, workItems]);

  useEffect(() => {
    if (projectId) dispatch(setActiveProject(projectId, {}));
  }, [projectId]);

  useEffect(() => {
    if (state.reportId) dispatch(setActiveReport(state.reportId, {}));
  }, [state.reportId]);

  useEffect(() => {
    if (currentUser) {
      fetchUserContractor();
    } else if (currentUser === false) {
      fetchRehabProjectReadOnly();
    }
  }, [currentUser]);

  useEffect(() => {
    if (project) {
      fetchTemplateWorkItems({
        variables: {
          marketId: project.teamMarketId,
          first: TEMPLATE_WORK_ITEMS_PER_PAGE,
        },
      });
    }
  }, [project, project?.teamMarketId]);

  useEffect(() => {
    window.onpopstate = (e: any) => {
      // Reload if going back to Report page, which consists of a url of /orders/:orderToken/reports
      if (e.target?.location?.href.includes('orders') && e.target?.location?.href.includes('reports')) {
        window.location.reload();
      }
    };
    return () => {
      window.onpopstate = () => {};
    };
  }, []);

  return (
    <ReportsPageProvider>
      <Helmet>
        <title>{`Rehab Tool - ${properties?.[state.propertyId]?.street}`}</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showFooter={false} />
      <RehabToolPage
        loading={
          loadingUser
          || loadingRehabProject
          || loadingRehabProjectReadOnly
        }
        handleFetchProjectHistory={handleFetchProjectHistory}
        publishProject={handlePublishProject}
        handleChangeTeamMarket={handleChangeTeamMarket}
      />
    </ReportsPageProvider>
  );
};

export default RehabToolPageLoader;
