import React from 'react';
import './styles.scss';
import '../Onboarding/styles.scss';
import '../Onboarding/OnboardingLandingPage/styles.scss';
import { useHistory, useParams } from 'react-router-dom';
import AlertOutlinedIcon from 'haven-design-system/build/assets/svgs/display/alert_outlined.svg?component';
import Button from '../../components/Button';
import InspectifyAppQRCode from '../../assets/qrcodes/download-app-qrcode.svg';
import GooglePlayStoreBadge from '../../assets/images/googlePlayStoreBadge.png';
import AppleAppStoreBadge from '../../assets/images/appleAppStoreBadge.svg';
import { ContractorTypeEnum } from '../../types';

const DownloadAppPage: React.FC = () => {
  const history = useHistory();
  const { onboardingContractorType } = useParams<{ onboardingContractorType: string }>();
  const [errorLoadingVideo, setErrorLoadingVideo] = React.useState(false);

  return (
    <div className="backdrop">
      <div className="container">
        <p className="text__header">
          Download the Inspectify App
        </p>
        <p className="text__instructions">
          Download the Inspectify Home Inspection App and sign in using your new Inspectify account.&nbsp;
          Then, watch the video below for a step-by-step guide on how to use the app to accept and manage job offers and complete inspection reports.
        </p>
        <div className="contentContainer">
          <div className="videoInstructionsContainer">
            <div className="instructionsContainer">
              <p className="downloadText">
                Available on the Apple App store and Google Play store
              </p>
              <div className="downloadButtonsContainer">
                <a
                  href="https://apps.apple.com/us/app/inspectify-home-inspection-app/id1577408351"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={AppleAppStoreBadge} alt="AppleAppStoreBadge" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.inspectify.homeInspectionApp&hl=en_US"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img style={{ height: '40px', width: '124px', borderRadius: '8px' }} src={GooglePlayStoreBadge} alt="GooglePlayStoreBadge" />
                </a>
              </div>
              <img className="downloadQRCode" src={InspectifyAppQRCode} alt="InspectifyAppQRCode" />
            </div>
            <div className="videoContainer">
              {errorLoadingVideo ? (
                <div className="errorContainer">
                  <div>
                    <AlertOutlinedIcon />
                  </div>
                  <div className="errorContainerText">
                    There was an error loading the video.  Please try reloading the page or go back to the dashboard.
                  </div>
                </div>
              ) : (
                <video
                  className="video"
                  width="100%"
                  border-radius="8px"
                  controls
                  onError={() => setErrorLoadingVideo(true)}
                  src={onboardingContractorType === ContractorTypeEnum.Inspector
                    ? 'https://public-inspectify.s3.us-east-1.amazonaws.com/videos/inspector-onboarding/InspectorAppDemo.mov'
                    : 'https://public-inspectify.s3.us-east-1.amazonaws.com/videos/surveyor-onboarding/SurveyorAppDemo.mp4'}
                />
              )}
            </div>
          </div>
          <div className="divider" />
          <div className="buttonContainer">
            <Button onClick={() => history.push('/dashboard')}>Back to dashboard</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPage;
