import React from 'react';
import { MenuButtonSectionProps } from './types';

const MenuButtonSection: React.FC<MenuButtonSectionProps> = ({
  sectionName,
  isMobile = false,
  sectionRef,
  setCurrentSection,
  setShowMenu,
}) => {
  const handleChangeSection = () => {
    if (sectionRef) {
      window.scrollTo({
        top: sectionRef.current.offsetTop - 200,
        behavior: 'smooth',
      });
    } else {
      setCurrentSection(sectionName);
    }
    if (isMobile) setShowMenu(false);
  };

  return (
    <div
      role="button"
      className="menuButtonSection"
      onClick={handleChangeSection}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      {sectionName}
    </div>
  );
};

export default MenuButtonSection;
