import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { usePlacesWidget } from 'react-google-autocomplete';
import OnboardingFormPage from './OnboardingFormPage';
import InspectifyLayout from '../../../components/InspectifyLayout';
import { OnboardingFormInput } from '../types';
import { isUserUsingMobile } from '../../../utils/user';
import { StateCodeEnum, StateEnum } from '../../../types/address';
import { captureException } from '../../../utils/error';
import { handleOnboardingPageNavigation } from '../../../utils/onboarding';
import { getStateNameFromStateCode } from '../../../utils/map';
import { ContractorTypeEnum, OnboardingCompletedStep } from '../../../types';
import {
  useInspectorOnboarding, useSurveyorOnboarding, useUpdateInspectorOnboarding, useUpdateSurveyorOnboarding,
} from '../../../hooks/onboarding';

const OnboardingFormPageLoader: React.FC = () => {
  const history = useHistory();
  const isMobile = useMemo(() => isUserUsingMobile(), []);
  const { onboardingContractorType } = useParams<{ onboardingContractorType: ContractorTypeEnum }>();
  const { onboardingId } = useParams<{ onboardingId: string }>();
  const [surveyorFormInput, setSurveyorFormInput] = useState<OnboardingFormInput>();
  const [inspectorFormInput, setInspectorFormInput] = useState<OnboardingFormInput>();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [address, setAddress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState<StateEnum | StateCodeEnum>();
  const [zipCode, setZipCode] = useState('');
  const [hasMultipleInspectors, setHasMultipleInspectors] = useState<boolean>();
  const [hasModeOfTransportation, setHasModeOfTransporation] = useState<boolean>();
  const [hasReliablePhone, setHasReliablePhone] = useState<boolean>();
  const [sendAutomatedSms, setSendAutomatedSms] = useState<boolean>();
  const [serviceAreaRadiusInMiles, setServiceAreaRadiusInMiles] = useState<number>(0);
  const [inspectorLicenseHeld, setInspectorLicenseHeld] = useState(false);
  const [contractorLicenseHeld, setContractorLicenseHeld] = useState(false);
  const [realEstateLicenseHeld, setRealEstateLicenseHeld] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showZipCodeError, setShowZipCodeError] = useState(false);

  useEffect(() => {
    if (onboardingContractorType === ContractorTypeEnum.Surveyor) {
      setSurveyorFormInput({
        firstName,
        lastName,
        email,
        phoneNumber,
        address: address || `${streetAddress}, ${city}, ${state} ${zipCode}, USA`,
        hasReliableModeOfTransportation: hasModeOfTransportation,
        hasReliablePhone,
        sendAutomatedSms,
        serviceAreaRadiusMileage: serviceAreaRadiusInMiles,
        inspectorLicenseHeld,
        contractorLicenseHeld,
        realEstateLicenseHeld,
      });
    }
    if (onboardingContractorType === ContractorTypeEnum.Inspector) {
      setInspectorFormInput({
        firstName,
        lastName,
        email,
        phoneNumber,
        businessName: companyName,
        businessSite: website,
        address: address || `${streetAddress}, ${city}, ${state} ${zipCode}, USA`,
        isMultiInspectorFirm: hasMultipleInspectors,
      });
    }
  }, [firstName, lastName, email, phoneNumber, companyName, website, address, city, state, zipCode,
    hasMultipleInspectors, hasModeOfTransportation, hasReliablePhone, sendAutomatedSms, serviceAreaRadiusInMiles,
    inspectorLicenseHeld, contractorLicenseHeld, realEstateLicenseHeld,
  ]);

  const { data: surveyorOnboardingData } = useSurveyorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching surveyor onboarding',
    contractorType: onboardingContractorType,
  });

  const { data: inspectorOnboardingData } = useInspectorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching inspector onboarding',
    contractorType: onboardingContractorType,
  });

  const { updateSurveyorOnboarding, updateSurveyorOnboardingLoading, updateSurveyorOnboardingData } = useUpdateSurveyorOnboarding({
    errorMessage: 'Graphql failed to update surveyor onboarding',
  });

  const { updateInspectorOnboarding, updateInspectorOnboardingLoading, updateInspectorOnboardingData } = useUpdateInspectorOnboarding({
    errorMessage: 'Graphql failed to update inspector onboarding',
  });

  const completedStep = updateSurveyorOnboardingData?.updateSurveyorOnboarding?.surveyorOnboarding?.completedStep
    || updateInspectorOnboardingData?.updateInspectorOnboarding?.inspectorOnboarding?.completedStep
    || surveyorOnboardingData?.surveyorOnboarding?.completedStep
    || inspectorOnboardingData?.inspectorOnboarding?.completedStep;

  const canSetPassword = updateSurveyorOnboardingData?.updateSurveyorOnboarding?.surveyorOnboarding?.canSetPassword
    || updateInspectorOnboardingData?.updateInspectorOnboarding?.inspectorOnboarding?.canSetPassword
    || surveyorOnboardingData?.surveyorOnboarding?.canSetPassword
    || inspectorOnboardingData?.inspectorOnboarding?.canSetPassword;

  // Prevent the user from returning to the form once submitted
  useEffect(() => {
    if (completedStep) {
      handleOnboardingPageNavigation(completedStep, canSetPassword, onboardingContractorType, onboardingId, history);
    }
  }, [completedStep]);

  const formComplete = () : boolean => {
    if (onboardingContractorType === ContractorTypeEnum.Surveyor
      && firstName?.length > 0 && lastName?.length > 0
      && !showPhoneError && !showEmailError
      && streetAddress?.length > 0 && city?.length > 0
      && state?.length > 0 && zipCode?.length > 0 && !showZipCodeError
      && hasModeOfTransportation !== undefined
      && hasReliablePhone !== undefined
      && sendAutomatedSms !== undefined
      && serviceAreaRadiusInMiles > 0
    ) return true;
    if (onboardingContractorType === ContractorTypeEnum.Inspector
      && firstName?.length > 0 && lastName?.length > 0
      && !showPhoneError && !showEmailError
      && companyName?.length > 0
      && streetAddress?.length > 0 && city?.length > 0
      && state?.length > 0 && zipCode?.length > 0 && !showZipCodeError
      && hasMultipleInspectors !== undefined
    ) return true;
    return false;
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place: any) => {
      const formattedAddress: string[] = place.formatted_address.split(',');
      setAddress(place.formatted_address);
      setStreetAddress(formattedAddress[0]);
      setCity(formattedAddress[1]);
      setState(getStateNameFromStateCode(formattedAddress[2].split(' ')[1]) as StateEnum);
      setZipCode(formattedAddress[2].split(' ')[2]);
    },
    options: {
      types: ['address'],
      field: 'place_id',
    },
  });

  const handleSubmitOnboardingForm = () : void => {
    if (formComplete()) {
      try {
        if (onboardingContractorType === ContractorTypeEnum.Surveyor) {
          updateSurveyorOnboarding({
            variables: {
              input: {
                id: onboardingId,
                completedStep: OnboardingCompletedStep.FormSubmitted,
                onboardingFormInput: surveyorFormInput,
              },
            },
            context: { headers: { onboarding_id: onboardingId } },
          });
        }
        if (onboardingContractorType === ContractorTypeEnum.Inspector) {
          updateInspectorOnboarding({
            variables: {
              input: {
                id: onboardingId,
                completedStep: OnboardingCompletedStep.FormSubmitted,
                onboardingFormInput: inspectorFormInput,
              },
            },
            context: { headers: { onboarding_id: onboardingId } },
          });
        }
      } catch (error) {
        captureException(error, { source: 'User clicked Sign Up' });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Onboarding Form</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showSignIn={false} hideHeaderOnMobile />
      <OnboardingFormPage
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        email={email}
        setEmail={setEmail}
        companyName={companyName}
        setCompanyName={setCompanyName}
        website={website}
        setWebsite={setWebsite}
        streetAddress={streetAddress}
        setStreetAddress={setStreetAddress}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        zipCode={zipCode}
        setZipCode={setZipCode}
        hasMultipleInspectors={hasMultipleInspectors}
        setHasMultipleInspectors={setHasMultipleInspectors}
        hasModeOfTransportation={hasModeOfTransportation}
        setHasModeOfTransporation={setHasModeOfTransporation}
        hasReliablePhone={hasReliablePhone}
        setHasReliablePhone={setHasReliablePhone}
        sendAutomatedSms={sendAutomatedSms}
        setSendAutomatedSms={setSendAutomatedSms}
        serviceAreaRadiusInMiles={serviceAreaRadiusInMiles}
        setServiceAreaRadiusInMiles={setServiceAreaRadiusInMiles}
        inspectorLicenseHeld={inspectorLicenseHeld}
        setInspectorLicenseHeld={setInspectorLicenseHeld}
        contractorLicenseHeld={contractorLicenseHeld}
        setContractorLicenseHeld={setContractorLicenseHeld}
        realEstateLicenseHeld={realEstateLicenseHeld}
        setRealEstateLicenseHeld={setRealEstateLicenseHeld}
        showEmailError={showEmailError}
        setShowEmailError={setShowEmailError}
        showPhoneError={showPhoneError}
        setShowPhoneError={setShowPhoneError}
        showZipCodeError={showZipCodeError}
        setShowZipCodeError={setShowZipCodeError}
        formComplete={formComplete}
        isMobile={isMobile}
        addressRef={ref}
        submitOnboardingForm={handleSubmitOnboardingForm}
        loading={updateSurveyorOnboardingLoading || updateInspectorOnboardingLoading}
        onboardingContractorType={onboardingContractorType}
      />
    </>
  );
};

export default OnboardingFormPageLoader;
