import {
  colorBaseBlackLight,
  colorBaseBlueLight,
  colorBaseBluePrimary,
  colorBaseTealLight,
} from 'haven-design-system/build/typescript/es6';
import { sortBy } from 'lodash';
import { ReportRecord } from 'marketplace-common';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { formatReportDate } from '../../../utils/date';
import { ReportContext, ReportContextType } from '../context';
import styles from './styles.module.scss';

interface Props {
  title: string,
  selectedDate: string,
  selectReport: (id: string) => void,
}

const renderLabel = (state: ReportContextType, record: ReportRecord) => {
  const inspection = state.inspections.find(({ id }) => record.inspectionIds.includes(id));
  const inspectionScopeNumber = inspection?.inspectionScopeNumber;
  const inspectionType = inspection?.inspectionType;
  const isDraw = inspectionType?.includes('draw_inspection');

  let label = `${formatReportDate(record)} ${record.publishedAt ? '(Published)' : '(Last updated)'}`;
  if (isDraw) {
    const number = inspectionScopeNumber === null ? '' : `#${inspectionScopeNumber}`;
    label = `Draw Inspection ${number}\n${label}`;
  }
  return label;
};

const SelectReport: React.FC<Props> = ({
  title,
  selectedDate,
  selectReport,
}) => {
  const [reportOptions, setReportOptions] = useState([] as any);
  const { state } = useContext(ReportContext);
  const { selectedReport: report, reportRecords } = state;

  useEffect(() => {
    if (reportRecords) {
      setReportOptions(
        sortBy<ReportRecord>(
          reportRecords, ['publishedAt', 'updatedAt'],
        ).map((record) => ({
          value: record.id,
          label: renderLabel(state, record),
        })).reverse(),
      );
    }
  }, [reportRecords]);

  return (
    <div className={styles.lastEdit}>
      <label htmlFor={title} id={title}>{title}:</label>
      <Select
        isSearchable={false}
        aria-label={title}
        value={{
          value: report?.report.id,
          label: renderLabel(state, report.report),
        }}
        options={reportOptions}
        onChange={(option) => {
          selectReport(option.value);
        }}
        styles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            color: colorBaseBluePrimary,
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
          }),
          control: (provided) => ({
            ...provided,
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            width: '100%',
            minWidth: `${(9 * selectedDate.length) + 150}px`,
          }),
          valueContainer: (provided) => ({
            ...provided,
            height: '3em',
          }),
          menu: (provided) => ({
            ...provided,
            color: 'red',
            width: `${(9 * selectedDate.length) + 150}px`,
          }),
          singleValue: (provided) => ({
            ...provided,
            fontWeight: 700,
            color: colorBaseBluePrimary,
            whiteSpace: 'pre-wrap',
          }),
          option: (provided: any, option) => ({
            ...provided,
            // justifyContent: 'center',
            background: option.isSelected ? colorBaseTealLight : 'none',
            ':focus': {
              ...provided[':focus'],
              background: colorBaseBlueLight,
            },
            ':active': {
              ...provided[':active'],
              background: colorBaseBlueLight,
            },
            color: colorBaseBlackLight,
            cursor: 'pointer',
            whiteSpace: 'pre-wrap',
            // height: isDraw ? '4em' : 'initial',
          }),
        }}
      />
    </div>
  );
};

export default SelectReport;
