import styled from 'styled-components';
import {
  colorBaseBlackLight,
  colorBaseGreyPrimary,
} from 'haven-design-system/build/typescript/es6';

export const SummaryTableHeader = styled.th`
  font-size: 32px;
  font-weight: 700;
  line-height: 43.65px;
  color: ${colorBaseBlackLight};
  padding-bottom: 24px;
`;

export const SummaryTableSubheader = styled.th<{ isCost?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  line-height: 31.28px;
  color: ${colorBaseBlackLight};
  width: 75%;
  text-align: ${(props) => (props.isCost ? 'right' : 'inherit')};
  padding: 24px 0;
`;

export const SummaryItemName = styled.td<{ isBold?: boolean }>`
  font-size: 18px;
  font-weight: ${(props) => (props.isBold ? 700 : 400)};
  line-height: 20px;
  color: ${(props) => (props.isBold ? colorBaseBlackLight : colorBaseGreyPrimary)};
  width: 75%;
  padding: ${(props) => (props.isBold ? '20px 0px' : '10px 0px')};
`;

export const SummaryItemCost = styled.td<{ isBold?: boolean }>`
  font-size: 18px;
  font-weight: ${(props) => (props.isBold ? 700 : 400)};
  line-height: 20px;
  color: ${(props) => (props.isBold ? colorBaseBlackLight : colorBaseGreyPrimary)};
  width: 25%;
  text-align: right;
  padding: ${(props) => (props.isBold ? '20px 0px' : '10px 0px')};
`;

export const MissingPricesWarningText = styled.div`
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  width: 75%;
  color: ${colorBaseGreyPrimary};
`;

export const InfoIconMarginRight = styled.img`
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-right: 8px;
`;
