import { ContractorFileAttachment, ContractorPricingInput, ContractorTypeEnum } from '../../../../types';
import { StateCodeEnum, StateEnum } from '../../../../types/address';
import { BusinessDetailsSection, ReportWritingSoftwareEnum } from '../types';
import { InspectionServiceType } from './InspectionService/types';

export interface BasicInformationSectionProps {
  phoneNumber: string,
  setPhoneNumber: (e: string) => void,
  email: string,
  setEmail: (e: string) => void,
  street: string,
  setStreet: (value: string) => void,
  city: string,
  setCity: (value: string) => void,
  state: StateEnum | StateCodeEnum
  setState: (value: StateEnum | StateCodeEnum) => void,
  zipCode: string,
  setZipCode: (value: string) => void,
  showZipCodeError: boolean,
  setShowZipCodeError: (value: boolean) => void,
  showPhoneError: boolean,
  setShowPhoneError: (value: boolean) => void,
  showEmailError: boolean,
  setShowEmailError: (value: boolean) => void,
  addressRef: React.RefObject<HTMLInputElement>,
  isMobile: boolean,
  onboardingContractorType: ContractorTypeEnum,
  title: BusinessDetailsSection,
  sectionRef: React.RefObject<HTMLDivElement>,

  // surveyor only
  firstName: string,
  setFirstName: (e: string) => void,
  lastName: string,
  setLastName: (e: string) => void,

  // inspector only
  businessName: string,
  setBusinessName: (e: string) => void,
  website: string,
  setWebsite: (e: string) => void,
  businessContactName: string,
  setBusinessContactName: (e: string) => void,
  schedulerName: string,
  setSchedulerName: (e: string) => void,
  schedulerPhoneNumber: string,
  setSchedulerPhoneNumber: (e: string) => void,
  schedulerEmail: string,
  setSchedulerEmail: (e: string) => void,
  showSchedulerPhoneError: boolean,
  setShowSchedulerPhoneError: (value: boolean) => void,
  showSchedulerEmailError: boolean,
  setShowSchedulerEmailError: (value: boolean) => void,
}
export interface InspectionServicesSectionProps {
  title: BusinessDetailsSection,
  sectionRef: React.RefObject<HTMLDivElement>,
  inspectionServices: InspectionServiceType[],
  contractorPricingInputs: ContractorPricingInput[],
  setContractorPricingInputs: (e: ContractorPricingInput[]) => void,
}

export interface CertificationsSectionProps {
  onboardingContractorType: ContractorTypeEnum,
  title: BusinessDetailsSection,
  instructions?: string,
  sectionRef?: React.RefObject<HTMLDivElement>,

  // inspector only
  stateLicenseNumber: string,
  setStateLicenseNumber: (e: string) => void,
  ashiLicenseNumber: string,
  setAshiLicenseNumber: (e: string) => void,
  internachiLicenseNumber: string,
  setInternachiLicenseNumber: (e: string) => void,
  otherLicenseNumber: string,
  setOtherLicenseNumber: (e: string) => void,

  // surveyor only
  inspectorLicenseHeld: boolean,
  setInspectorLicenseHeld: (e: boolean) => void,
  contractorLicenseHeld: boolean,
  setContractorLicenseHeld: (e: boolean) => void,
  realEstateLicenseHeld: boolean,
  setRealEstateLicenseHeld: (e: boolean) => void,
}

export interface ServiceableAreaSectionProps {
  isMobile: boolean,
  onboardingContractorType: ContractorTypeEnum,
  title: BusinessDetailsSection,
  sectionRef: React.RefObject<HTMLDivElement>,
  serviceAreaRadiusInMiles: number;
  setServiceAreaRadiusInMiles: (e: number) => void,
}

export interface AccessAndSoftwareSectionProps {
  onboardingContractorType: ContractorTypeEnum,
  title: BusinessDetailsSection,
  sectionRef: React.RefObject<HTMLDivElement>,
  hasSupra: boolean,
  setHasSupra: (value: boolean) => void,
  hasSentrilock: boolean,
  setHasSentriLock: (value: boolean) => void,

  // inspector only
  reportWritingSoftware: ReportWritingSoftwareEnum,
  setReportWritingSoftware: (value: ReportWritingSoftwareEnum) => void,
}

export interface DocumentsSectionProps {
  onboardingContractorType: ContractorTypeEnum,
  title: BusinessDetailsSection,
  sectionRef: React.RefObject<HTMLDivElement>,
  msaSigned: boolean,
  misaFiles: ContractorFileAttachment[],
  certificationFiles: ContractorFileAttachment[],
  uploadInsuranceCertificate: (uploadedFile: File) => void,
  loadingNewFile: boolean,
}

export enum CertificationLicenseEnum {
  State = 'State license number',
  Ashi = 'ASHI certification number',
  InterNachi = 'InterNACHI member ID',
  Other = 'Other license',
}

export enum RemoveServicesModalEnum {
  Header = 'To remove services, please contact our partnerships team',
  Email = ' partnerships@inspectify.com',
}
