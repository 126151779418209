import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { handleSignInError } from 'marketplace-common';
import OnboardingSetPasswordPage from './OnboardingSetPasswordPage';
import InspectifyLayout from '../../../components/InspectifyLayout';
import { captureException } from '../../../utils/error';
import { handleOnboardingPageNavigation } from '../../../utils/onboarding';
import { signIn, signOut } from '../../../utils/amplify_auth';
import {
  ContractorTypeEnum, OnboardingCompletedStep, OnboardingQueryResponseType, OnboardingUpdateResponseType,
} from '../../../types';
import {
  useInspectorOnboarding, useSurveyorOnboarding, useUpdateInspectorOnboarding, useUpdateSurveyorOnboarding,
} from '../../../hooks/onboarding';

const OnboardingSetPasswordPageLoader: React.FC = () => {
  const history = useHistory();
  const { onboardingContractorType } = useParams<{ onboardingContractorType: string }>();
  const { onboardingId } = useParams<{ onboardingId: string }>();
  const [email, setEmail] = useState('');
  const [completedStep, setCompletedStep] = useState<OnboardingCompletedStep>();
  const [canSetPassword, setCanSetPassword] = useState<boolean>();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [loading, setIsLoading] = useState(false);

  useSurveyorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching surveyor onboarding',
    onCompleted: (data: OnboardingQueryResponseType) => {
      if (onboardingContractorType === ContractorTypeEnum.Surveyor && data?.surveyorOnboarding) {
        setCompletedStep(data.surveyorOnboarding.completedStep);
        setCanSetPassword(data.surveyorOnboarding.canSetPassword);
        setEmail(data.surveyorOnboarding.contractor.businessEmail);
      }
    },
  });

  useInspectorOnboarding({
    id: onboardingId,
    errorMessage: 'Fetching inspector onboarding',
    onCompleted: (data: OnboardingQueryResponseType) => {
      if (onboardingContractorType === ContractorTypeEnum.Inspector && data?.inspectorOnboarding) {
        setCompletedStep(data.inspectorOnboarding.completedStep);
        setCanSetPassword(data.inspectorOnboarding.canSetPassword);
        setEmail(data.inspectorOnboarding.contractor.businessEmail);
      }
    },
  });

  // Prevent the user from returning to the set password page once set
  useEffect(() => {
    if (completedStep) {
      handleOnboardingPageNavigation(completedStep, canSetPassword, onboardingContractorType, onboardingId, history);
    }
  }, [completedStep]);

  const { updateSurveyorOnboarding } = useUpdateSurveyorOnboarding({
    errorMessage: 'Graphql failed to update surveyor onboarding',
    onCompleted: (data: OnboardingUpdateResponseType) => {
      if (data?.updateSurveyorOnboarding?.surveyorOnboarding) {
        setCompletedStep(data?.updateSurveyorOnboarding?.surveyorOnboarding.completedStep);
      }
    },
  });

  const { updateInspectorOnboarding } = useUpdateInspectorOnboarding({
    errorMessage: 'Graphql failed to update inspector onboarding',
    onCompleted: (data: OnboardingUpdateResponseType) => {
      if (data?.updateInspectorOnboarding?.inspectorOnboarding) {
        setCompletedStep(data?.updateInspectorOnboarding?.inspectorOnboarding.completedStep);
      }
    },
  });

  const checkPasswordsMatch = () => {
    if (password !== passwordConfirmation) {
      setShowPasswordError(true);
    } else {
      setShowPasswordError(false);
    }
  };

  const handleSignIn = async () => {
    try {
      if (localStorage.getItem('inspectifySelectedUser')) {
        await signOut();
      }
      await signIn(
        email,
        password,
        () => {
          window.location.assign('/dashboard');
        },
      );
      setIsLoading(false);
    } catch (error) {
      handleSignInError(error, captureException, email);
    }
  };

  const handleSetPassword = async () => {
    setIsLoading(true);
    try {
      if (onboardingContractorType === ContractorTypeEnum.Surveyor) {
        await updateSurveyorOnboarding({
          variables: {
            input: {
              id: onboardingId,
              passwordSet: {
                password,
                passwordConfirmation,
              },
              completedStep: OnboardingCompletedStep.PasswordSet,
            },
          },
          context: { headers: { onboarding_id: onboardingId } },
        });
      }
      if (onboardingContractorType === ContractorTypeEnum.Inspector) {
        await updateInspectorOnboarding({
          variables: {
            input: {
              id: onboardingId,
              passwordSet: {
                password,
                passwordConfirmation,
              },
              completedStep: OnboardingCompletedStep.PasswordSet,
            },
          },
          context: { headers: { onboarding_id: onboardingId } },
        });
      }
    } catch (error) {
      captureException(error, { source: 'Setting password' });
    }
    handleSignIn();
  };

  return (
    <>
      <Helmet>
        <title>Welcome to Inspectify!</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showSignIn={false} hideHeaderOnMobile />
      <OnboardingSetPasswordPage
        password={password}
        setPassword={setPassword}
        showPasswordError={showPasswordError}
        setShowPasswordError={setShowPasswordError}
        passwordConfirmation={passwordConfirmation}
        setPasswordConfirmation={setPasswordConfirmation}
        checkPasswordsMatch={checkPasswordsMatch}
        setPasswordAndSignIn={handleSetPassword}
        loading={loading}
      />
    </>
  );
};

export default OnboardingSetPasswordPageLoader;
