import React from 'react';
import './styles.scss';
import '../styles.scss';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import {
  BasicInformation, OnboardingFormQuestions, OnboardingFormPageProps,
} from './types';
import Spinner from '../../../components/Spinner';
import { calculateSliderWidth } from '../../../utils/onboarding';
import { ContractorTypeEnum } from '../../../types';
import OnboardingFormBasicInformation from './OnboardingFormBasicInformation';

const OnboardingFormPage: React.FC<OnboardingFormPageProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  businessName,
  setBusinessName,
  website,
  setWebsite,
  street,
  setStreet,
  city,
  setCity,
  state,
  setState,
  zipCode,
  setZipCode,
  hasMultipleInspectors,
  setHasMultipleInspectors,
  hasModeOfTransportation,
  setHasModeOfTransporation,
  hasReliablePhone,
  setHasReliablePhone,
  sendAutomatedSms,
  setSendAutomatedSms,
  serviceAreaRadiusInMiles,
  setServiceAreaRadiusInMiles,
  inspectorLicenseHeld,
  setInspectorLicenseHeld,
  contractorLicenseHeld,
  setContractorLicenseHeld,
  realEstateLicenseHeld,
  setRealEstateLicenseHeld,
  phoneErrorMessage,
  setPhoneErrorMessage,
  emailErrorMessage,
  setEmailErrorMessage,
  showZipCodeError,
  setShowZipCodeError,
  formComplete,
  isMobile,
  addressRef,
  submitOnboardingForm,
  loading,
  onboardingContractorType,
}) => (
  <div className="backdrop">
    <div className="container">
      <p className="text__header">Sign up for Inspectify</p>
      <p className="text__instructions">
        After you fill out the form below, you&apos;ll be directed to create a
        password, login, and complete your Inspectify account.
      </p>
      <div className="contentContainer">
        <div className="userProfile">
          <div className="userProfile__section">
            <div className="userProfile__section__title">
              {BasicInformation.Title}
            </div>
          </div>
          <div className="userProfile__section--large">
            <OnboardingFormBasicInformation
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              email={email}
              setEmail={setEmail}
              businessName={businessName}
              setBusinessName={setBusinessName}
              website={website}
              setWebsite={setWebsite}
              street={street}
              setStreet={setStreet}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              zipCode={zipCode}
              setZipCode={setZipCode}
              phoneErrorMessage={phoneErrorMessage}
              setPhoneErrorMessage={setPhoneErrorMessage}
              emailErrorMessage={emailErrorMessage}
              setEmailErrorMessage={setEmailErrorMessage}
              showZipCodeError={showZipCodeError}
              setShowZipCodeError={setShowZipCodeError}
              isMobile={isMobile}
              addressRef={addressRef}
              onboardingContractorType={onboardingContractorType}
            />
          </div>
        </div>
        <div className="divider" />
        <div className="userProfile">
          <div className="userProfile__section">
            <div className="userProfile__section__title">
              {onboardingContractorType === ContractorTypeEnum.Surveyor ? OnboardingFormQuestions.SurveyorTitle : OnboardingFormQuestions.InspectorTitle}
            </div>
          </div>
          {onboardingContractorType === ContractorTypeEnum.Surveyor ? (
            <div className="userProfile__section--large">
              <div className="userProfile__section__inputRow">
                <div className="userProfile__section__inputRow__inputContainer">
                  <div className="userProfile__section__inputRow__inputContainer__label" style={{ whiteSpace: 'normal' }}>
                    <div className="userProfile__section__inputRow__inputContainer__label--required">
                      {OnboardingFormQuestions.Required}
                    </div>
                    {OnboardingFormQuestions.Transportation}
                  </div>
                  <label htmlFor="transportation_yes" className="radioButtonContainer">
                    <input
                      type="radio"
                      id="transportation_yes"
                      className="radioButton"
                      name="transportation"
                      checked={hasModeOfTransportation}
                      onChange={(e) => setHasModeOfTransporation(e.target.checked)}
                    />
                    <div className={hasModeOfTransportation ? 'responseTextSelected' : 'responseText'}>
                      {OnboardingFormQuestions.Yes}
                    </div>
                  </label>
                  <label htmlFor="transportation_no" className="radioButtonContainer">
                    <input
                      type="radio"
                      id="transportation_no"
                      className="radioButton"
                      name="transportation"
                      checked={hasModeOfTransportation === false}
                      onChange={(e) => setHasModeOfTransporation(!e.target.checked)}
                    />
                    <div className={hasModeOfTransportation === false ? 'responseTextSelected' : 'responseText'}>
                      {OnboardingFormQuestions.No}
                    </div>
                  </label>
                </div>
              </div>
              <div className="userProfile__section__inputRow">
                <div className="userProfile__section__inputRow__inputContainer">
                  <div className="userProfile__section__inputRow__inputContainer__label" style={{ whiteSpace: 'normal' }}>
                    <div className="userProfile__section__inputRow__inputContainer__label--required">
                      {OnboardingFormQuestions.Required}
                    </div>
                    {OnboardingFormQuestions.Smartphone}
                  </div>
                  <label htmlFor="phone_yes" className="radioButtonContainer">
                    <input
                      type="radio"
                      id="phone_yes"
                      className="radioButton"
                      name="smartphone"
                      checked={hasReliablePhone}
                      onChange={(e) => setHasReliablePhone(e.target.checked)}
                    />
                    <div className={hasReliablePhone ? 'responseTextSelected' : 'responseText'}>
                      {OnboardingFormQuestions.Yes}
                    </div>
                  </label>
                  <label htmlFor="phone_no" className="radioButtonContainer">
                    <input
                      type="radio"
                      id="phone_no"
                      className="radioButton"
                      name="smartphone"
                      checked={hasReliablePhone === false}
                      onChange={(e) => setHasReliablePhone(!e.target.checked)}
                    />
                    <div className={hasReliablePhone === false ? 'responseTextSelected' : 'responseText'}>
                      {OnboardingFormQuestions.No}
                    </div>
                  </label>
                </div>
              </div>
              <div className="userProfile__section__inputRow">
                <div className="userProfile__section__inputRow__inputContainer">
                  <div className="userProfile__section__inputRow__inputContainer__label" style={{ whiteSpace: 'normal' }}>
                    <div className="userProfile__section__inputRow__inputContainer__label--required">
                      {OnboardingFormQuestions.Required}
                    </div>
                    {OnboardingFormQuestions.Sms}
                  </div>
                  <label htmlFor="sms_yes" className="radioButtonContainer">
                    <input
                      type="radio"
                      id="sms_yes"
                      className="radioButton"
                      name="sms"
                      checked={sendAutomatedSms}
                      onChange={(e) => setSendAutomatedSms(e.target.checked)}
                    />
                    <div className={sendAutomatedSms ? 'responseTextSelected' : 'responseText'}>
                      {OnboardingFormQuestions.SmsYes}
                    </div>
                  </label>
                  <label htmlFor="sms_no" className="radioButtonContainer">
                    <input
                      type="radio"
                      id="sms_no"
                      className="radioButton"
                      name="sms"
                      checked={sendAutomatedSms === false}
                      onChange={(e) => setSendAutomatedSms(!e.target.checked)}
                    />
                    <div className={sendAutomatedSms === false ? 'responseTextSelected' : 'responseText'}>
                      {OnboardingFormQuestions.SmsNo}
                    </div>
                  </label>
                </div>
              </div>
              <div className="userProfile__section__inputRow">
                <div className="userProfile__section__inputRow__inputContainer">
                  <div className="userProfile__section__inputRow__inputContainer__label" style={{ whiteSpace: 'normal' }}>
                    <div className="userProfile__section__inputRow__inputContainer__label--required">
                      {OnboardingFormQuestions.Required}
                    </div>
                    <div className="questionContainer">
                      <span className="questionText">{OnboardingFormQuestions.Distance}</span>&nbsp;
                      <span className="questionTextSecondary">{OnboardingFormQuestions.DistanceSelect}</span>
                    </div>
                  </div>
                  <div className="sliderContainer">
                    <div
                      id="handle_follower"
                      className="sliderTooltip"
                      style={{
                        position: 'absolute',
                        left: calculateSliderWidth(isMobile, serviceAreaRadiusInMiles),
                        top: 5,
                      }}
                    >
                      {serviceAreaRadiusInMiles.toString().concat(' miles')}
                    </div>
                    <input
                      id="distance_slider"
                      type="range"
                      min={0}
                      max={200}
                      step={10}
                      value={serviceAreaRadiusInMiles}
                      onChange={(e) => setServiceAreaRadiusInMiles(parseInt(e.target.value, 10))}
                    />
                    <div className="sliderLabelContainer">
                      <div>{OnboardingFormQuestions.DistanceMin}</div>
                      <div>{OnboardingFormQuestions.DistanceMax}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="userProfile__section__inputRow">
                <div className="userProfile__section__inputRow__inputContainer">
                  <div className="questionContainer">
                    <span className="questionText">{OnboardingFormQuestions.License}</span>&nbsp;
                    <span className="questionTextSecondary">{OnboardingFormQuestions.LicenseSelect}</span>
                  </div>
                  <Checkbox
                    isChecked={inspectorLicenseHeld}
                    toggle={() => setInspectorLicenseHeld(!inspectorLicenseHeld)}
                    label={OnboardingFormQuestions.Inspector}
                  />
                  <Checkbox
                    isChecked={contractorLicenseHeld}
                    toggle={() => setContractorLicenseHeld(!contractorLicenseHeld)}
                    label={OnboardingFormQuestions.Contractor}
                  />
                  <Checkbox
                    isChecked={realEstateLicenseHeld}
                    toggle={() => setRealEstateLicenseHeld(!realEstateLicenseHeld)}
                    label={OnboardingFormQuestions.Realtor}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="userProfile__section--large">
              <div className="userProfile__section__inputRow">
                <div className="userProfile__section__inputRow__inputContainer">
                  <div className="userProfile__section__inputRow__inputContainer__label">
                    <div className="userProfile__section__inputRow__inputContainer__label--required">
                      {OnboardingFormQuestions.Required}
                    </div>
                    {OnboardingFormQuestions.MultipleInspectors}
                  </div>
                  <label htmlFor="multipleInspectors_yes" className="radioButtonContainer">
                    <input
                      type="radio"
                      id="multipleInspectors_yes"
                      className="radioButton"
                      name="multipleInspectors"
                      checked={hasMultipleInspectors}
                      onChange={(e) => setHasMultipleInspectors(e.target.checked)}
                    />
                    <div className={hasMultipleInspectors ? 'responseTextSelected' : 'responseText'}>
                      {OnboardingFormQuestions.Yes}
                    </div>
                  </label>
                  <label htmlFor="multipleInspectors_no" className="radioButtonContainer">
                    <input
                      type="radio"
                      id="multipleInspectors_no"
                      className="radioButton"
                      name="multipleInspectors"
                      checked={hasMultipleInspectors === false}
                      onChange={(e) => setHasMultipleInspectors(!e.target.checked)}
                    />
                    <div className={hasMultipleInspectors === false ? 'responseTextSelected' : 'responseText'}>
                      {OnboardingFormQuestions.No}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="divider" />
        <div className="buttonContainer">
          <Button
            onClick={() => submitOnboardingForm()}
            disabled={loading || !formComplete()}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {loading ? <Spinner /> : 'Sign up'}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default OnboardingFormPage;
