import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isNull } from 'lodash';
import { InspectorDashboardContext } from './context';
import { DashboardPageTab, UrlTargets } from './types';
import {
  InspectorDashboardContent,
  InspectorDashboardGlobalStyles,
} from './styles';
import './styles.scss';
import styles from '../../components/Button/styles.module.scss';
import BadNavigationImage from '../../assets/images/badNavigationImage.svg';
import JobsLoader from './Jobs';
import MobileMenu from './MobileMenu';
import Calendar from './Calendar';
import Spinner from '../../components/Spinner';
import TipaltiIframe from './TipaltiIframe';
import MsaReview from './MsaReview';
import { ContractorTypeEnum, UserContractor } from '../../types';
import BusinessDetailsLoader from './BusinessDetails';
import Menu from './Menu';
import UserManagement from './UserManagement';
import { canAccessMSAPage, isUserUsingMobile } from '../../utils/user';
import { useUrlQuery } from '../../utils/hooks';
import { BusinessDetailsSection } from './BusinessDetails/types';
import ContractorBusinessDetailsLoader from './BusinessDetails/ContractorBusinessDetailsLoader';
import { SplitFlags } from '../../redux/actions';
import { useTreatment } from '../../utils/splitio';

interface Props {
  user: UserContractor,
  isLoading: boolean,
  error: string,
  refetchContractor: () => void
  basicInformationRef: React.RefObject<HTMLDivElement>,
  inspectionServicesRef: React.RefObject<HTMLDivElement>,
  certificationsRef: React.RefObject<HTMLDivElement>,
  serviceableAreaRef: React.RefObject<HTMLDivElement>,
  accessAndSoftwareRef: React.RefObject<HTMLDivElement>,
  documentsRef: React.RefObject<HTMLDivElement>,
}

const InspectorDashboardPage: React.FC<Props> = ({
  user,
  isLoading,
  error,
  refetchContractor,
  basicInformationRef,
  inspectionServicesRef,
  certificationsRef,
  serviceableAreaRef,
  accessAndSoftwareRef,
  documentsRef,
}) => {
  const query = useUrlQuery();
  const target = query.get('target');
  const { state, setPage } = useContext(InspectorDashboardContext);
  const featureInspectorOnboarding = useTreatment(SplitFlags.FeatureInspectorOnboarding);
  const [currentSection, setCurrentSection] = useState(BusinessDetailsSection.BasicInformation);
  const [businessDetailsEdited, setBusinessDetailsEdited] = useState(false);

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [dashboardPageTabForModal, setDashboardPageTabForModal] = useState<DashboardPageTab>(state.page);
  const [showMenu, setShowMenu] = useState(false);

  const isMobile = useMemo(() => isUserUsingMobile(), []);

  const handleClickMenuTab = (tab: DashboardPageTab, leaveMenuOpen = false) => {
    if (state.page === DashboardPageTab.BusinessDetails && businessDetailsEdited) {
      setConfirmationModalIsOpen(true);
      setDashboardPageTabForModal(tab);
    } else {
      setPage(tab);
    }
    if (isMobile) setShowMenu(leaveMenuOpen);
  };

  const handleConfirmationModalConfirm = () => {
    setConfirmationModalIsOpen(false);
    setBusinessDetailsEdited(false);
    setPage(dashboardPageTabForModal);
  };

  const showBackToChecklist = useMemo(() => (
    isNull(user?.currentUser?.contractor?.currentOnboarding?.onboardingTrackerDismissedAt)
    || isNull(user?.currentUser?.contractor?.currentInspectorOnboarding?.onboardingTrackerDismissedAt)
  ), [user?.currentUser?.contractor]);

  useEffect(() => {
    if (target && user?.currentUser?.contractor) {
      switch (target) {
        case UrlTargets.Misa:
          if (canAccessMSAPage(user)) {
            setPage(DashboardPageTab.MasterInspectionAndServiceAgreement);
          } else {
            setPage(DashboardPageTab.Jobs);
          }
          break;
        case UrlTargets.BusinessDetails:
          setPage(DashboardPageTab.BusinessDetails);
          break;
        case UrlTargets.PaymentSetup:
          if (user.currentUser.grantedPermissions.includes('view_payment_setup_page')) {
            setPage(DashboardPageTab.TipaltiSetupProcess);
          } else {
            setPage(DashboardPageTab.Jobs);
          }
          break;
        case UrlTargets.PaymentHistory:
          if (user.currentUser.grantedPermissions.includes('view_payment_setup_page')) {
            setPage(DashboardPageTab.TipaltiPaymentHistory);
          } else {
            setPage(DashboardPageTab.Jobs);
          }
          break;
        case UrlTargets.ManageUsers:
          if (user.currentUser?.canManageUsers) {
            setPage(DashboardPageTab.UserManagement);
          } else {
            setPage(DashboardPageTab.Jobs);
          }
          break;
        default:
          setPage(DashboardPageTab.Jobs);
          break;
      }
    }
  }, [target, user?.currentUser?.contractor]);

  useEffect(() => {
    if (canAccessMSAPage(user)
      && state.page === DashboardPageTab.Jobs
      && !user.currentUser.contractor?.currentOnboarding
      && !user.currentUser.contractor?.currentInspectorOnboarding) {
      setPage(DashboardPageTab.MasterInspectionAndServiceAgreement);
    }
  }, [user?.currentUser?.contractor]);

  const renderPageContent = (content: DashboardPageTab) => {
    switch (content) {
      case DashboardPageTab.Jobs:
        return <JobsLoader user={user} setCurrentSection={setCurrentSection} />;
      case DashboardPageTab.Calendar:
        return <Calendar />;
      case DashboardPageTab.BusinessDetails:
        // TODO: remove the if statement when featureInspectorOnboarding is removed
        if ((featureInspectorOnboarding === 'on' && user.currentUser.contractor.contractorType === ContractorTypeEnum.Inspector)
          || user.currentUser.contractor.contractorType === ContractorTypeEnum.Surveyor) {
          return (
            <BusinessDetailsLoader
              contractorId={user.currentUser.contractor.id}
              businessDetailsEdited={businessDetailsEdited}
              setBusinessDetailsEdited={setBusinessDetailsEdited}
              confirmationModalIsOpen={confirmationModalIsOpen}
              setConfirmationModalIsOpen={setConfirmationModalIsOpen}
              handleConfirm={handleConfirmationModalConfirm}
              basicInformationRef={basicInformationRef}
              inspectionServicesRef={inspectionServicesRef}
              certificationsRef={certificationsRef}
              serviceableAreaRef={serviceableAreaRef}
              accessAndSoftwareRef={accessAndSoftwareRef}
              documentsRef={documentsRef}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
          );
        }
        // TODO: remove this component when featureInspectorOnboarding is removed
        return <ContractorBusinessDetailsLoader />;
      case DashboardPageTab.UserManagement:
        return <UserManagement currentUser={user.currentUser} />;
      case DashboardPageTab.TipaltiSetupProcess:
        return <TipaltiIframe iframeType="setup_process" showBackToChecklist={showBackToChecklist} />;
      case DashboardPageTab.TipaltiPaymentHistory:
        return <TipaltiIframe iframeType="payment_history" showBackToChecklist={showBackToChecklist} />;
      case DashboardPageTab.MasterInspectionAndServiceAgreement:
        return <MsaReview refetchContractor={refetchContractor} />;
      default:
        return <JobsLoader user={user} />;
    }
  };

  if (isLoading && error.length === 0) return <Spinner style={{ width: '100vw', height: '100vh' }} />;

  if (error.length) {
    return (
      <div className="errorMessageContainer">
        <img src={BadNavigationImage} alt="" className="imageContainer" />
        <div className="errorMessageHeader">Are you in the wrong place?</div>
        <div className="errorMessageContent">{error}</div>
        <a href="https://app2.inspectify.com" className={styles.primary}>
          Login as a client
        </a>
      </div>
    );
  }

  return (
    <div>
      <InspectorDashboardGlobalStyles />
      <Menu
        user={user}
        handleClickMenuTab={handleClickMenuTab}
        basicInformationRef={basicInformationRef}
        inspectionServicesRef={inspectionServicesRef}
        certificationsRef={certificationsRef}
        serviceableAreaRef={serviceableAreaRef}
        accessAndSoftwareRef={accessAndSoftwareRef}
        documentsRef={documentsRef}
      />
      <MobileMenu
        user={user}
        setCurrentSection={setCurrentSection}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        handleClickMenuTab={handleClickMenuTab}
      />
      <InspectorDashboardContent>
        {renderPageContent(state.page)}
      </InspectorDashboardContent>
    </div>
  );
};

export default InspectorDashboardPage;
