import {
  JobStatus,
  LocalInspection,
} from 'marketplace-common';
import React from 'react';
import {
  RestrictedWidthMainText,
  RestrictedWidthSubText,
  TextLayoutContainer,
  FileHyperlink,
  AppRequiredContainer,
} from '../../styles';
import incomplete from '../../../../../../assets/icons/incomplete.svg';
import complete from '../../../../../../assets/icons/complete.svg';
import {
  InspectionRow,
  LeftLayoutContainer,
  RowButton,
  StatusLogo,
} from '../styles';
import inspectifyLogo from '../../../../../../assets/inspectify/Inspectify_Logo_Jobs.svg';
import { LogoImage } from '../../../../shared/styles';
import { FileUploadDetails } from '../../../../types';

interface Props {
  inspection: LocalInspection;
  fileUploadDetails: FileUploadDetails[];
  reportName: string;
  allowsUploading: boolean;
  onUploadClick: (inspection: LocalInspection) => void;
}

const ReportUploadRow: React.FC<Props> = ({
  inspection,
  fileUploadDetails,
  reportName,
  allowsUploading,
  onUploadClick,
}) => (
  <InspectionRow>
    {inspection.status === JobStatus.Completed && (
      <>
        <LeftLayoutContainer>
          <StatusLogo src={complete} alt="complete" />
          <TextLayoutContainer>
            <RestrictedWidthMainText>{reportName}</RestrictedWidthMainText>
            {fileUploadDetails.map((file) => (
              <FileHyperlink key={file.url} href={file.url} target="_blank">
                {file.name}
              </FileHyperlink>
            ))}
          </TextLayoutContainer>
        </LeftLayoutContainer>
        {allowsUploading && (
          <RowButton completed onClick={() => onUploadClick(inspection)}>
            Upload another file
          </RowButton>
        )}
      </>
    )}
    {inspection.status !== JobStatus.Completed && (
      <>
        {(inspection.templateReportId || ['re_inspection', 'draw_inspection', 'sow_inspection', 'land_development_inspection'].includes(inspection.inspectionType)) ? (
          <>
            <LeftLayoutContainer>
              <StatusLogo src={incomplete} alt="incomplete" />
              <TextLayoutContainer>
                <RestrictedWidthMainText>{reportName}</RestrictedWidthMainText>
                <RestrictedWidthSubText>
                  Complete this inspection through our app.
                </RestrictedWidthSubText>
              </TextLayoutContainer>
            </LeftLayoutContainer>
            <AppRequiredContainer>
              <LogoImage src={inspectifyLogo} /> App Required
            </AppRequiredContainer>
          </>
        ) : (
          <>
            <LeftLayoutContainer>
              <StatusLogo src={incomplete} alt="incomplete" />
              <TextLayoutContainer>
                <RestrictedWidthMainText>{reportName}</RestrictedWidthMainText>
                <RestrictedWidthSubText>
                  No report has been uploaded.
                </RestrictedWidthSubText>
              </TextLayoutContainer>
            </LeftLayoutContainer>
            {allowsUploading && (
              <RowButton completed={false} onClick={() => onUploadClick(inspection)}>
                Upload
              </RowButton>
            )}
          </>
        )}
      </>
    )}
  </InspectionRow>
);

export default ReportUploadRow;
