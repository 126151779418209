// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
const decimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
const noDecimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatMoney = (money: number) => {
  if (money % 1 === 0) return noDecimalFormatter.format(money);

  return decimalFormatter.format(money);
};

/**
 * Validate money input
 * @param {string} money
 * @returns {number}
 */
export const validateMoney = (money: string) => {
  const value = money.replace(/[^0-9.]/g, '');
  return parseFloat(value);
};
