import React, { useContext } from 'react';
import { ReportDeliveryFilter } from '../../../../types';
import { useReportsPageContext } from '../../../../utils/reportsPageContext';
import { ReportContext } from '../../context';
import MenuSystem from './MenuSystem';
import { MenuItem, MenuItemsContainer, MenuText } from './styles';

interface Props {
  filter: ReportDeliveryFilter
  isMobileView?: boolean
  onMobileItemPress?: () => void
}

const MenuItems: React.FC<Props> = ({
  isMobileView,
  onMobileItemPress,
  filter,
}) => {
  const { state } = useContext(ReportContext);
  const { state: menuState, dispatch } = useReportsPageContext();

  const items = Object.keys(state.groupedReportSystemsByName);
  if (items.length === 0) return null;

  return (
    <MenuItemsContainer>
      <MenuItem
        href="#Summary"
        isActive={menuState.activeMenuItem === 'Summary'}
        isMobileView={isMobileView}
        onClick={() => {
          onMobileItemPress?.();
          dispatch({ type: 'set_active_item', payload: 'Summary' });
        }}
      >
        <MenuText>
          Summary
        </MenuText>
      </MenuItem>

      {items.map((systemName: string) => (
        <MenuSystem
          key={systemName}
          systemName={systemName}
          filter={filter}
          onMobileItemPress={onMobileItemPress}
        />
      ))}
    </MenuItemsContainer>
  );
};

export default MenuItems;
