import styled from 'styled-components';
import {
  colorPaletteGrayscale50,
  colorBaseWhitePrimary,
  colorBaseBlackLight,
  colorBaseTealLight,
  colorBaseTealDark,
  colorBaseBluePrimary,
  colorPaletteOrange300,
  colorPaletteYellow100,

  colorPalettePurple600,
} from 'haven-design-system/build/typescript/es6';

import ContributorSvg from 'haven-design-system/build/assets/svgs/display/contributor.svg?component';

export const WorkItemContainer = styled.div<{ isActive: boolean }>`
  position: relative;
  background-color: ${(props) => (props.isActive ? colorPaletteYellow100 : colorBaseWhitePrimary)};
  border: 1px solid ${(props) => (props.isActive ? colorPaletteOrange300 : colorPaletteGrayscale50)};
  border-radius: 2px;
  margin-bottom: 13px;
  height: 135px;
  display: flex;
  flex-direction: row;
`;

export const WorkItemImageContainer = styled.div`
  display: flex;
`;

export const WorkItemImage = styled.img`
  width: 200px;
  background-color: ${colorPaletteGrayscale50};
  object-fit: contain;
`;

export const AltImageWrapper = styled.div`
  min-width: 200px;
  height: auto;
  background-color: ${colorBaseTealLight};
  display: flex;
  justify-content: center;
`;

export const AltImage = styled.img`
  background-color: ${colorBaseTealLight};
  width: 40%;
  height: 40%;
  margin: auto;
`;

export const ContributorIcon = styled(ContributorSvg)`
  path {
    fill: ${colorPalettePurple600};
  }
`;

export const WorkItemDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 16px 20px;
  overflow-y: auto;
`;

interface WorkItemTitleProps {
  isItalic?: boolean;
}

export const WorkItemTitle = styled.div<WorkItemTitleProps>`
  font-size: 20px;
  font-weight: 400;
  color: ${colorBaseBlackLight};
  font-style: ${({ isItalic }) => (isItalic ? 'italic' : 'normal')};
  margin: 0px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const WorkItemDetails = styled.div`
  font-size: 14px;
  color: ${colorBaseBlackLight};
  margin: 4px 8px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const WorkItemTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WorkItemTag = styled.div<{ $color?: string, $background?: string }>`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.$color || colorBaseTealDark};
  background: ${(props) => props.$background || colorBaseTealLight};
  min-width: 100px;
  border-radius: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 4px 8px;
  margin: 0px 4px;
`;

export const WorkItemPricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  margin-left: auto;
  min-width: 200px;
`;

export const Price = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 27px;
  text-align: right;
  color: ${colorBaseBlackLight};
`;

export const PriceBreakdown = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: ${colorBaseBlackLight};
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  justify-content: right;
  &:hover {
    cursor: pointer;
  }
`;

export const EditText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  color: ${colorBaseBluePrimary};
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
`;

export const LeftArrow = styled.img`
  height: 25px;
  position: absolute;
  left: 0;
  top: 40%;
  transform: rotate(180deg);
  &:hover {
    cursor: pointer;
  }
`;

export const RightArrow = styled(LeftArrow)`
  transform: none;
  left: 180px;
`;
