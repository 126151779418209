import React, { useState, SetStateAction, Dispatch } from 'react';
import AddCircleOutlineIcon from 'haven-design-system/build/assets/svgs/action/add-circle_outlined.svg?component';
import RemoveCircleOutlineIcon from 'haven-design-system/build/assets/svgs/action/remove-circle_outlined.svg?component';
import uuid from 'react-uuid';
import Button from '../../../../../../components/Button';
import ToggleSwitch from '../../../../../../components/ToggleSwitch';
import styles from '../styles.module.scss';
import { formatMoney } from '../../../../../../utils/money';
import {
  FormInput,
  FormInputCurrency,
} from '../../../../../../components/FormElements';
import { WorkItemContributorType } from '../../../../../../types';

type WorkItemCostSplitProps = {
  totalCost: number;
  contributors: WorkItemContributorType[];
  setContributors: Dispatch<SetStateAction<WorkItemContributorType[]>>;
  costBreakdownEnabled: boolean;
  setCostBreakdownEnabled: Dispatch<SetStateAction<boolean>>;
};

const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

const WorkItemCostSplit = ({
  totalCost,
  contributors,
  setContributors,
  costBreakdownEnabled,
  setCostBreakdownEnabled,
}: WorkItemCostSplitProps) => {
  const [isToggled, setIsToggled] = useState(costBreakdownEnabled);
  const contributorWithPreExistingAmount = contributors.some(
    (contributor) => parseFloat(contributor?.amount) > 0,
  );

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
    setCostBreakdownEnabled(!costBreakdownEnabled);

    if (!isToggled && !contributorWithPreExistingAmount) {
      const evenSplit = totalCost ? totalCost / contributors.length : 0;
      setContributors(
        contributors.map((contributor) => ({
          ...contributor,
          amount: evenSplit.toString(),
        })),
      );
    }
  };

  const updateContributor = (
    index: number,
    field: 'name' | 'amount',
    value: string | number,
  ) => {
    const updatedContributors = contributors.map((contributor, i) => (i === index
      ? {
        ...contributor,
        [field]: value,
      }
      : contributor));
    setContributors(updatedContributors);
  };

  const addContributor = () => {
    setContributors([
      ...contributors,
      {
        id: uuid(),
        name: '',
        amount: '0',
        isPreExisting: false,
      },
    ]);
  };

  const removeContributor = (index: number) => {
    const updatedContributors = contributors.filter((_, i) => i !== index);
    setContributors(updatedContributors);
  };

  const distributeEvenly = () => {
    const evenSplit = roundToTwoDecimals(totalCost / contributors.length);
    const roundedContributors = contributors.map((contributor, i) => ({
      ...contributor,
      amount: i === contributors.length - 1
        ? (totalCost - evenSplit * (contributors.length - 1)).toFixed(2)
        : evenSplit.toFixed(2),
    }));

    setContributors(roundedContributors);
  };

  const validateTotal = () => {
    const totalAmount = contributors.reduce(
      (acc, contributor) => acc + parseFloat(contributor.amount),
      0,
    );
    return roundToTwoDecimals(totalAmount) === roundToTwoDecimals(totalCost);
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <div className={styles.row}>
        <div className={styles.row}>
          <p style={{ marginBottom: 4, marginLeft: 6, fontWeight: 700 }}>
            Contributor breakdown
          </p>
          <div style={{ marginLeft: 12 }}>
            <ToggleSwitch
              tag="cost-split"
              isOn={isToggled}
              toggle={handleToggleChange}
            />
          </div>
        </div>
      </div>

      {totalCost > 0 && (
        <div>
          {isToggled && (
            <div>
              {!validateTotal() ? (
                <p className={styles.error}>
                  {`Total contributor amount does not match the total cost of the work item (${formatMoney(
                    totalCost,
                  )}).`}
                </p>
              ) : (
                <p />
              )}
              {contributors.map((contributor, index) => (
                <div
                  key={contributor.id}
                  className={styles.row}
                  style={{ marginBottom: 24 }}
                >
                  <div className={styles.contributorInput}>
                    <p>
                      {!contributor.name ? (
                        <span className={styles.error}>*</span>
                      ) : null}
                      Contributor
                    </p>
                    <FormInput
                      type="text"
                      placeholder="Enter contributor"
                      value={contributor.name}
                      onChange={(e) => updateContributor(index, 'name', e.target.value)}
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <p>Amount</p>
                    <div className={styles.row}>
                      <FormInputCurrency
                        name="Contributor amount"
                        includeLabel={false}
                        placeholder="$0.00"
                        value={contributor.amount}
                        onValueChange={(value: string) => updateContributor(index, 'amount', value)}
                      />

                      <div className={styles.removeContributorContainer}>
                        <RemoveCircleOutlineIcon
                          className={styles.icon}
                          onClick={() => removeContributor(index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className={styles.row}>
                <div
                  role="button"
                  onKeyDown={(e) => e.key === 'Enter' && addContributor()}
                  onClick={addContributor}
                  className={styles.addContributorContainer}
                  tabIndex={0}
                >
                  <AddCircleOutlineIcon className={styles.icon} />
                  <p className={styles.addContributorText}>Add contributor</p>
                </div>

                <Button secondary onClick={distributeEvenly}>
                  Distribute amount evenly
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkItemCostSplit;
