import React from 'react';
import '../../styles.scss';
import InformationSection from '../../../../components/InformationSection';
import { ContractorTypeEnum } from '../../../../types';
import { FormFile } from '../../../../components/FormElements';
import { DocumentsSectionProps } from './types';
import { BusinessDetailsSectionDescription, BusinessDetailsText } from '../types';
import { BasicInformation } from '../../../Onboarding/OnboardingFormPage/types';

const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  sectionRef,
  title,
  onboardingContractorType,
  msaSigned,
  misaFiles,
  certificationFiles,
  loadingNewFile,
  uploadInsuranceCertificate,
}) => (
  <InformationSection
    title={title}
    sectionRef={sectionRef}
    description={BusinessDetailsSectionDescription.Documents}
  >
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          {BusinessDetailsText.MISA}
        </div>
        {msaSigned && (
          <FormFile
            files={misaFiles}
            upload={false}
          />
        )}
      </div>
    </div>
    {onboardingContractorType === ContractorTypeEnum.Inspector && (
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          <div className="userProfile__section__inputRow__inputContainer__label--required">
            {BasicInformation.Required}
          </div>
          {BusinessDetailsText.Insurance}
        </div>
        <FormFile
          files={certificationFiles}
          upload
          addFile={uploadInsuranceCertificate}
          loading={loadingNewFile}
        />
      </div>
    </div>
    )}
  </InformationSection>
);

export default DocumentsSection;
