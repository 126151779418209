import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReportAttachment } from 'marketplace-common';
import DeleteFiledIcon from 'haven-design-system/build/assets/svgs/action/delete_filled.svg?component';
import DownloadIcon from 'haven-design-system/build/assets/svgs/action/download_filled.svg?component';
import LocationIcon from 'haven-design-system/build/assets/svgs/display/location_filled.svg?component';
import LoadingIcon from 'haven-design-system/build/assets/svgs/display/spinner.svg?component';
import ShareIcon from 'haven-design-system/build/assets/svgs/action/share-outlined.svg?component';
import DELETE_ATTACHMENT from '../../../../../../graphql/mutations/deleteAttachment';
import { useCurrentUser } from '../../../../../../hooks/user';
import Zoom from './Zoom';
import Rotate from './Rotate';
import styles from './styles.module.scss';

interface Props {
  show: boolean,
  sliderValue: number,
  setSliderValue: (value: React.SetStateAction<number>) => void,
  rotate: number,
  setRotate: (value: React.SetStateAction<number>) => void,
  attachmentSource: string | null,
  gpsCoordinates: { latitude: number, longitude: number, accuracy?: number } | null,
  videoLoadErrored: boolean,
  allowCopyLongLiveUrl: boolean,
  attachment: ReportAttachment,
  handleClose: () => void,
}

const Controls: React.FC<Props> = ({
  show,
  sliderValue,
  setSliderValue,
  rotate,
  setRotate,
  gpsCoordinates,
  attachmentSource,
  videoLoadErrored,
  allowCopyLongLiveUrl,
  attachment,
  handleClose,
}) => {
  const user = useCurrentUser();
  const inspectifyAdmin = !!user?.currentUser?.admin;

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const onGoogleMaps = () => {
    if (gpsCoordinates?.latitude && gpsCoordinates?.longitude) {
      window.open(`https://www.google.com/maps?q=${gpsCoordinates.latitude},${gpsCoordinates.longitude}`, '_blank');
    }
  };

  const onDownload = useCallback(async () => {
    const response = await fetch(videoLoadErrored ? attachment.attachmentUrl : attachmentSource);
    const data = await response.blob();
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachment?.fileName);
    document.body.appendChild(link);
    link.click();
    document.removeChild(link);
  }, [attachment, attachmentSource]);

  const [deleteAttachment, { loading: deleteLoading }] = useMutation(DELETE_ATTACHMENT);
  const onDelete = async () => {
    await deleteAttachment({
      variables: { input: { id: attachment.id } },
    });
    setShowDeleteAlert(false);
    toast.success(
      <>
        Attachment deleted.
        <br />
        {/* eslint-disable-next-line max-len */}
        you can still download it, but once you close the window and refresh the page it will be gone
      </>,
    );
  };

  const copyLongLiveUrlToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success('URL copied to clipboard. The URL is valid for 365 days.');
  };

  // register keyboard shortcut:
  // d for download
  // m for google maps
  // delete for delete
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (!show) return;

      switch (event.key) {
        case 'd':
          await onDownload();
          break;
        case 'm':
          onGoogleMaps();
          break;
        case 'l':
          if (allowCopyLongLiveUrl && attachment.longLiveUrl) {
            copyLongLiveUrlToClipboard(attachment.longLiveUrl);
          }
          break;
        case 'Delete':
          if (inspectifyAdmin) {
            setShowDeleteAlert(true);
          }
          break;
        default:
          break;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onDownload, onGoogleMaps, show]);

  return (
    <>
      <div className={styles.controls}>
        {attachment.mediaType === 'photo' && (
          <Zoom
            show={show}
            zoomValue={sliderValue}
            setZoomValue={setSliderValue}
          />
        )}

        {attachment.mediaType !== 'pdf' && (
          <Rotate
            show={show && attachment?.rotatedUrls?.original && !videoLoadErrored}
            attachmentId={attachment?.id}
            degrees={rotate}
            setRotate={setRotate}
          />
        )}

        {attachment.mediaType !== 'pdf' && gpsCoordinates && (
          <button
            title="View GPS coordinates on map (M)"
            className={styles.controlButton}
            type="button"
            onClick={onGoogleMaps}
          >
            (M)<LocationIcon className={styles.controlButtonIcon} />
          </button>
        )}

        {attachment.mediaType !== 'pdf' && attachmentSource && (
          <button
            title="Download media (D)"
            className={styles.controlButton}
            type="button"
            onClick={onDownload}
          >
            (D)<DownloadIcon className={styles.controlButtonIcon} />
          </button>
        )}

        {allowCopyLongLiveUrl && attachment.longLiveUrl && (
          <button
            title="Copy URL (C)"
            className={styles.controlButton}
            type="button"
            onClick={() => copyLongLiveUrlToClipboard(attachment.longLiveUrl)}
          >
            (L)<ShareIcon className={styles.controlButtonIcon} />
          </button>
        )}

        {inspectifyAdmin && (
          <>
            <button
              title="Delete (DEL)"
              className={styles.controlButton}
              type="button"
              onClick={() => setShowDeleteAlert(true)}
            >
              {deleteLoading ? (
                <LoadingIcon className={styles.controlButtonIcon} />
              ) : (
                <>(DEL)<DeleteFiledIcon className={styles.controlButtonIcon} /></>
              )}
            </button>
          </>
        )}

        <button
          title="Close"
          className={styles.controlClose}
          type="button"
          onClick={handleClose}
        >
          &#10005;
        </button>
      </div>

      <Modal show={showDeleteAlert}>
        <Modal.Header>
          <Modal.Title>Delete Attachment</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 24 }}>
          <h4>Are you sure you want to delete this attachment?</h4>
          <p>
            This action cannot be undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setShowDeleteAlert(false)}
            className="btn"
            type="button"
          >
            Cancel
          </button>
          <button
            onClick={onDelete}
            className="btn btn-danger"
            type="button"
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Controls;
