import React, { useMemo } from 'react';
import { chunk, isNil } from 'lodash';
import { colorPaletteGrayscale100, colorPaletteGrayscale500 } from 'haven-design-system/build/typescript/es6';
import ScanIcon from 'haven-design-system/build/assets/svgs/camera/photo/scan.svg?component';
import { WorkItem as WorkItemType, WorkItemAttachmentsInterface } from '../../../../types';
import { formatMoney } from '../../../../utils/money';
import TagIcon from '../../../../assets/icons/tag.svg?component';
import {
  TableBody, TableHead, TableRow, TableWithoutBreak,
  WorkItemImage,
} from '../../styles';
import {
  WorkItemTableAttachment,
  WorkItemTableBreakdown,
  WorkItemTableDetail,
  WorkItemTableHeaderCost,
  WorkItemTableHeaderName,
  WorkItemTag,
  WorkItemTags,
} from './styles';

interface Props {
  item: WorkItemType,
  workItemAttachments: WorkItemAttachmentsInterface,
  imageWidth: number,
}

const WorkItem: React.FC<Props> = ({
  item,
  workItemAttachments,
  imageWidth,
}) => {
  const groupedAttachments = useMemo(() => (
    item.workItemAttachments ? chunk(item.workItemAttachments, 2) : []
  ), [item.workItemAttachments]);

  const quantityStr = useMemo(() => {
    if (!item.pricePerUnit) return '';

    let str = `${formatMoney(item.pricePerUnit || 0)}`;
    if (item.quantity) str = `${str} x ${item.quantity}`;
    if (item.unit) str = `${str} ${item.unit}`;
    return str;
  }, [item.pricePerUnit, item.quantity, item.unit]);

  const getUrl = (attachmentId: string) => {
    const { resizedUrls } = workItemAttachments[attachmentId];
    const urls = resizedUrls.filter((x) => imageWidth === parseInt(x.width, 10));
    if (urls.length > 0) {
      return urls[0].url;
    }
    return resizedUrls[0].url; // better to return oversized image than no image at all?
  };

  return (
    <TableWithoutBreak>
      <TableHead>
        <TableRow>
          <WorkItemTableHeaderName isBold>{item.title || 'Undefined work item'}</WorkItemTableHeaderName>
          <WorkItemTableHeaderCost isBold>{isNil(item.totalPrice) ? 'Price Pending' : formatMoney(item.totalPrice)}</WorkItemTableHeaderCost>
        </TableRow>
      </TableHead>

      <TableBody>
        {quantityStr && (
          <TableRow>
            <WorkItemTableBreakdown colSpan={2}>{quantityStr}</WorkItemTableBreakdown>
          </TableRow>
        )}

        {item.details && (
          <TableRow>
            <WorkItemTableDetail colSpan={2}>{item.details}</WorkItemTableDetail>
          </TableRow>
        )}

        {item.tags.length > 0 && (
          <TableRow>
            <WorkItemTags colSpan={2}>
              {item.tags.map((tag) => (
                <WorkItemTag key={`${item.id}-${tag}`}><TagIcon /> {tag}</WorkItemTag>
              ))}
            </WorkItemTags>
          </TableRow>
        )}

        {item.pricingExternalId?.length > 0 && (
          <TableRow>
            <WorkItemTags colSpan={2}>
              <WorkItemTag $color={colorPaletteGrayscale500} $background={colorPaletteGrayscale100}>
                <ScanIcon /> {item.pricingExternalId}
              </WorkItemTag>
            </WorkItemTags>
          </TableRow>
        )}

        {groupedAttachments.map((attachmentIds) => (
          <TableRow key={`${attachmentIds.join('+')}`}>
            {attachmentIds.map((attachmentId) => (
              <WorkItemTableAttachment key={attachmentId}>
                {workItemAttachments[attachmentId].attachmentUrl.includes('mp4') ? (
                  <WorkItemImage
                    alt="WorkItemImage"
                    src={workItemAttachments[attachmentId].thumbnailUrl}
                  />
                ) : (
                  <WorkItemImage src={getUrl(attachmentId)} alt={workItemAttachments[attachmentId].id} />
                )}
              </WorkItemTableAttachment>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableWithoutBreak>
  );
};

export default WorkItem;
